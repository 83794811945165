import { useProfileContext } from "@hooks/context/useProfileContext";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { TableCell, TableRow, Chip, IconButton, Box } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState, useRef, useEffect } from "react";

import Iconify from "../Iconify/Iconify";
import { QuillInput } from "../QuillInput/QuillInput";
import { StyledButton } from "../StyledButton/StyledButton";

export function createMarkup(dirty: string) {
  return { __html: dirty };
}

interface EditableTableRowProps {
  loading: boolean;
  disabled: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  userOrganisation: OrganisationLineItem;
  condition: ConditionLineItem;
  conditions: ConditionLineItem[];
  handleOpenConditionDocumentModal: (condition: ConditionLineItem) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleOpenConditionEditModal: (condition: ConditionLineItem) => void;
}

function EditableTableRow({
  condition,
  conditions,
  disabled,
  handleDeleteCondition,
  handleEditCondition,
  handleOpenConditionDocumentModal,
  handleOpenConditionEditModal,
  loading,
  permit,
  project,
  userOrganisation,
}: EditableTableRowProps) {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentCondition, setCurrentCondition] =
    useState<ConditionLineItem>(condition);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { userProfile } = useProfileContext();

  const [showDescriptionEditor, setShowDescriptionEditor] =
    useState<boolean>(false);
  const [showCommentEditor, setEditCommentEditor] = useState<boolean>(false);
  const [showDisciplineEditor, setShowDisciplineEditor] =
    useState<boolean>(false);
  const [showAssigneeNotesEditor, setShowAssigneeNotesEditor] =
    useState<boolean>(false);

  const handleTextChange = (
    id: string,
    column: keyof ConditionLineItem,
    newText: string
  ) => {
    const matchingCondition = conditions.find((item) => item.id === id);
    if (!matchingCondition) {
      return;
    }
    if (matchingCondition[column] !== newText) {
      setCurrentCondition({ ...matchingCondition, [column]: newText });
    }
  };

  const handleTextSave = () => {
    handleEditCondition(currentCondition);
  };

  useEffect(() => {
    if (condition) {
      setCurrentCondition(condition);
    }
  }, [condition.status]);

  const canEdit = project.organisationId === userOrganisation.id;

  const menuItems = [
    {
      action: () => {
        handleClose();
        handleOpenConditionDocumentModal(condition);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
  ];
  if (canEdit) {
    if (
      !condition.markedAsNotApplicable &&
      condition.status === ConditionStatus.Approved
    ) {
      menuItems.push({
        action: () => {
          handleEditCondition({
            ...condition,
            status: ConditionStatus.Rejected,
          });
        },
        color: "red",
        icon: <ThumbDownOffAltIcon />,
        name: "Reject",
      });
    }
    if (condition.markedAsNotApplicable) {
      menuItems.push({
        action: () => {
          handleEditCondition({
            ...condition,
            markedAsNotApplicable: false,
          });
        },
        color: "green",
        icon: <DownloadDoneIcon />,
        name: "Mark as applicable",
      });
    } else {
      menuItems.push({
        action: () => {
          handleEditCondition({
            ...condition,
            markedAsNotApplicable: true,
          });
        },
        color: "red",
        icon: <BlockIcon />,
        name: "Mark not applicable",
      });
    }
    menuItems.push({
      action: () => {
        handleClose();
        handleOpenConditionEditModal(condition);
      },
      color: "black",
      icon: <EditIcon />,
      name: "Edit",
    });

    menuItems.push({
      action: () => {
        handleClose();
        setOpenConfirmDialog(true);
      },
      color: "red",
      icon: <DeleteIcon />,
      name: "Delete",
    });
  }
  return (
    <>
      <TableRow key={condition.id}>
        <TableCell sx={{ border: "1px solid #000" }} width={"5%"}>
          {conditonNameToString(
            condition.conditionAuthority,
            condition.permitItemNumber
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            if (canEdit) {
              setEditCommentEditor(false);
              setShowDescriptionEditor(true);
              setShowAssigneeNotesEditor(false);
              setShowDisciplineEditor(false);
            }
          }}
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          {showDescriptionEditor ? (
            <QuillInput
              loading={false}
              disabled={disabled}
              value={currentCondition.conditionDetails}
              onChange={(value) =>
                handleTextChange(condition.id, "conditionDetails", value)
              }
              onBlur={() => {
                setShowDescriptionEditor(false);
                setEditCommentEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.conditionDetails || "",
                }}
              />
              {canEdit ? (
                <EditIcon
                  style={{
                    color: disabled ? "#b4b4b4" : undefined,
                    cursor: "pointer",
                    height: "20px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20px",
                  }}
                />
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            if (canEdit) {
              setEditCommentEditor(true);
              setShowDescriptionEditor(false);
              setShowAssigneeNotesEditor(false);
              setShowDisciplineEditor(false);
            }
          }}
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          {showCommentEditor ? (
            <QuillInput
              loading={false}
              disabled={disabled}
              value={currentCondition.conditionComments}
              onChange={(value) =>
                handleTextChange(condition.id, "conditionComments", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                padding: "5px !important",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.conditionComments || "",
                }}
              />
              {canEdit ? (
                <EditIcon
                  style={{
                    color: disabled ? "#b4b4b4" : undefined,
                    cursor: "pointer",
                    height: "20px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20px",
                  }}
                />
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            if (canEdit) {
              setEditCommentEditor(false);
              setShowDescriptionEditor(false);
              setShowAssigneeNotesEditor(false);
              setShowDisciplineEditor(true);
            }
          }}
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          {showDisciplineEditor ? (
            <QuillInput
              loading={false}
              disabled={disabled}
              value={currentCondition.discipline}
              onChange={(value) =>
                handleTextChange(condition.id, "discipline", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                padding: "5px !important",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.discipline || "",
                }}
              />
              {canEdit ? (
                <EditIcon
                  style={{
                    color: disabled ? "#b4b4b4" : undefined,
                    cursor: "pointer",
                    height: "20px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20px",
                  }}
                />
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            if (
              canEdit ||
              (userProfile &&
                condition.assignees.map((a) => a.id).includes(userProfile.id))
            ) {
              setEditCommentEditor(false);
              setShowDescriptionEditor(false);
              setShowAssigneeNotesEditor(true);
              setShowDisciplineEditor(false);
            }
          }}
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          {showAssigneeNotesEditor ? (
            <QuillInput
              loading={false}
              disabled={disabled}
              value={currentCondition.assigneeComments}
              onChange={(value) =>
                handleTextChange(condition.id, "assigneeComments", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                padding: "5px !important",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.assigneeComments || "",
                }}
              />
              {canEdit ||
              (userProfile &&
                condition.assignees
                  .map((a) => a.id)
                  .includes(userProfile.id)) ? (
                <EditIcon
                  style={{
                    color: disabled ? "#b4b4b4" : undefined,
                    cursor: "pointer",
                    height: "20px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20px",
                  }}
                />
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            justifyContent: "center",
            width: "10%",
          }}
        >
          <Box display="flex" justifyContent="center">
            {disabled ? (
              <Chip
                label={conditionStatusToString(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                variant="filled"
                background-color={"#b6b6b6"}
                sx={{
                  backgroundColor: "#b6b6b6",
                  margin: "auto",
                }}
              />
            ) : (
              <Chip
                label={conditionStatusToString(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                variant="filled"
                background-color={conditionStatusToColor(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                sx={{
                  backgroundColor: conditionStatusToColor(
                    condition.status,
                    Boolean(condition.markedAsNotApplicable)
                  ),
                  margin: "auto",
                }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          <Box display="flex" justifyContent="center">
            <StyledButton
              loading={loading}
              disabled={disabled}
              onClick={() => {
                handleOpenConditionDocumentModal(condition);
              }}
              variant="outlined"
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
            >
              {condition.status === ConditionStatus.InReview &&
              permit.managers
                .map((pm) => pm.id)
                .includes(userProfile?.id || "no-id-found")
                ? "Review Documents"
                : "Upload Documents"}
            </StyledButton>
          </Box>
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }} ref={ref}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                handleClick();
              }}
            >
              <Iconify icon={"eva:more-vertical-fill"} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}

      {openConfirmDialog ? (
        <ConfirmationDialog
          message={"Do you want to delete this condition?"}
          open={openConfirmDialog}
          title={"Delete condition"}
          intent={"error"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => handleDeleteCondition(condition)}
        />
      ) : null}
    </>
  );
}

export default EditableTableRow;
