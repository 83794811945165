import { DialogContentText, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  ConditionLineItem,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import AddCommentModal from "./AddCommentModal";
import { CommentTable } from "./CommentTable";

interface ConditionCommentFormProps {
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  comments: ConditionCommentLineItem[];
  createComments: (comments: ConditionCommentLineItem[]) => void;
  deleteComment: (comment: ConditionCommentLineItem) => void;
  handleSubmit: () => void;
  handleBack: () => void;
  handleClose: () => void;
}

export const ConditionCommentForm = ({
  canEdit,
  comments,
  condition,
  createComments,
  deleteComment,
  disabled,
  handleBack,
  handleClose,
  handleSubmit,
  loading,
  permit,
  project,
  shareCondition,
  userProfile,
}: ConditionCommentFormProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "0 0 10px",
        }}
      >
        Add comments to the condition
      </DialogContentText>
      {canEdit ? (
        <Grid container sx={{ padding: "0 " }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              alignContent: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 0 20px",
            }}
          >
            <StyledButton
              loading={false}
              color="primary"
              variant="contained"
              sx={{ cursor: "pointer !important" }}
              onClick={() => setOpen(true)}
            >
              Add Comment
            </StyledButton>
          </Grid>
        </Grid>
      ) : null}
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
          <CommentTable
            canEdit={canEdit}
            loading={loading}
            disabled={disabled}
            conditionComments={comments}
            deleteComment={deleteComment}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <StyledButton
              loading={false}
              color="primary"
              variant="outlined"
              sx={{ cursor: "pointer !important" }}
              onClick={handleBack}
            >
              Back
            </StyledButton>
            <StyledButton
              loading={false}
              color="primary"
              variant="outlined"
              sx={{ cursor: "pointer !important" }}
              onClick={handleClose}
            >
              Exit
            </StyledButton>
            <StyledButton
              loading={false}
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              sx={{ cursor: "pointer !important" }}
            >
              Continue
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
      {open && project && permit && condition ? (
        <AddCommentModal
          open={open}
          disabled={disabled}
          project={project}
          permit={permit}
          condition={condition}
          userProfile={userProfile}
          shareCondition={shareCondition}
          setOpen={setOpen}
          createComments={createComments}
        />
      ) : null}
    </>
  );
};
