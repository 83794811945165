"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermitEventType = void 0;
var PermitEventType;
(function (PermitEventType) {
    PermitEventType["PermitCreated"] = "permit-event-type_permit-created";
    PermitEventType["PermitAssignedManager"] = "permit-event-type_permit-assigned-manager";
    PermitEventType["PermitUnassignedManager"] = "permit-event-type_permit-unassigned-manager";
    PermitEventType["PermitAssignedApprover"] = "permit-event-type_permit-assigned-approver";
    PermitEventType["PermitUnassignedApprover"] = "permit-event-type_permit-unassigned-approver";
    PermitEventType["PermitInReview"] = "permit-event-type_permit-in_review";
    PermitEventType["PermitRejected"] = "permit-event-type_permit-rejected";
    PermitEventType["PermitApproved"] = "permit-event-type_permit-approved";
    PermitEventType["PermitUpdated"] = "permit-event-type_permit-updated";
    PermitEventType["PermitUploadedDocument"] = "permit-event-type_uploaded_document";
    PermitEventType["PermitTransmittalCreated"] = "permit-event-type_permit-transmittal-created";
    PermitEventType["ConditionCreated"] = "permit-event-type_condition-created";
    PermitEventType["ConditionAssigned"] = "permit-event-type_condition-assigned";
    PermitEventType["ConditionInReview"] = "permit-event-type_condition-in_review";
    PermitEventType["ConditionRejected"] = "permit-event-type_condition-rejected";
    PermitEventType["ConditionUpdated"] = "permit-event-type_condition-updated";
    PermitEventType["ConditionApproved"] = "permit-event-type_condition-approved";
    PermitEventType["ConditionDocumentUploaded"] = "permit-event-type_condition-document-uploaded";
    PermitEventType["ConditionDocumentRevisionUploaded"] = "permit-event-type_condition-document-revision-uploaded";
    PermitEventType["ConditionDocumentAssigned"] = "permit-event-type_condition-uploaded-assigned";
    PermitEventType["ConditionDocumentUnassigned"] = "permit-event-type_condition-document-unassigned";
    PermitEventType["ConditionUnassigned"] = "permit-event-type_condition-unassigned";
})(PermitEventType || (exports.PermitEventType = PermitEventType = {}));
