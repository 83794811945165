import { useProjectContext } from "@hooks/context/useProjectContext";
import { useConditionCount } from "@hooks/crud/useConditionCount";
import { Box, Grid, Typography } from "@mui/material";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";

export const AllConditions = (): JSX.Element => {
  const { selectedProject } = useProjectContext();

  const { conditionCounts, isConditionCountLoading } = useConditionCount(
    selectedProject?.id
  );

  // const handleViewCondition = (condition: ConditionLineItem) => {
  //   navigate(
  //     RouteHelper.condition(
  //       selectedProject?.id || "",
  //       condition.permitId,
  //       condition.id
  //     )
  //   );
  // };

  const loading = isConditionCountLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0 0 30px",
          padding: "20px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            color: "#333333",
            fontSize: "28px !important",
            fontWeight: "700",
          }}
        >
          My Conditions
        </Typography>
      </Grid>
      <Box sx={{ padding: "20px 40px 40px" }}>
        <ConditionSummary
          conditionCounts={conditionCounts}
          project={selectedProject}
          loading={loading}
        />
        {/* <ConditionTable
          loading={loading}
          conditions={conditions}
          setConditions={() => {
            console.log("Not implemented");
          }}
          handleViewCondition={handleViewCondition}
          handleOpen={() => {
            console.log("not implemented");
          }}
          handleImportConditons={async () => {
            console.log("not implemented");
          }}
          handleEditCondition={() => {
            console.log("not implemented");
          }}
          handleDeleteCondition={() => {
            console.log("not implemented");
          }}
          showToolbar={false}
        /> */}
      </Box>
    </Box>
  );
};
