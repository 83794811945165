import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactNode, Ref } from "react";

interface SubCardProps {
  children: ReactNode | string | null;
  content?: boolean;
  className?: string;
  contentClass?: string;
  darkTitle?: boolean;
  secondary?: ReactNode | string;
  sx?: object;
  contentSX?: object;
  title?: ReactNode | string;
}

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = React.forwardRef(
  (
    {
      children,
      content,
      contentClass,
      contentSX = {},
      secondary,
      sx = {},
      title,
      ...others
    }: SubCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        sx={{
          ":hover": {
            boxShadow:
              theme.palette.mode === "dark"
                ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                : "0 2px 14px 0 rgb(32 40 45 / 8%)",
          },
          border: "1px solid",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light + 15
              : theme.palette.primary.main,
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              p: 2.5,
            }}
            title={
              <Typography variant="h5" color={"#FFFFFF"}>
                {title}
              </Typography>
            }
            action={secondary}
          />
        )}
        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              borderColor:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.light + 15
                  : theme.palette.grey[200],
              opacity: 1,
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent
            sx={{ p: 2.5, ...contentSX }}
            className={contentClass || ""}
          >
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

SubCard.defaultProps = {
  content: true,
};

SubCard.displayName = "SubCard";

export default SubCard;
