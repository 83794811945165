import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import { Chip, Stack, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import * as React from "react";

import { MailRowProps } from "./MailProps";


export const MailRow = ({ profile, row, setSelectedMail }: MailRowProps) => {
  const theme = useTheme();
  const darkBG = theme.palette.mode === "dark" ? "dark.main" : "grey.100";
  const isRead = row.topMail.readBy.includes(profile?.id || "");

  const mailRowStatus =
    row.topMail?.sender.id === profile?.id
      ? "Sent"
      : isRead
      ? "Read"
      : "Unread";

  return (
    <TableRow
      hover
      sx={{
        "& td:last-of-type>div": {
          "& button + button": {
            ml: 0.625,
          },
          background:
            theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
          borderRadius: "12px",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
          display: "none",
          position: "absolute",
          px: 1.75,
          py: 1,
          right: 5,
          top: "50%",
          transform: "translateY(-50%)",
        },
        "&:hover": {
          "& td:last-of-type>div": {
            display: "block",
          },
        },
        bgcolor: !row.topMail.readBy.includes(profile?.id || "") ? darkBG : "",
      }}
      tabIndex={-1}
      onClick={() => {
        setSelectedMail(row);
      }}
    >
      <TableCell align="center" style={{ width: "5%" }}>
        <Chip
          label={mailRowStatus}
          sx={{
            backgroundColor: isRead ? "#AFE2AF" : "#FCC8CF",
            color: "black",
            fontSize: "14px",
            fontWeight: "bold !important",
          }}
        />
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} style={{ width: "20%" }}>
        {isRead ? row.subject : <strong>{row.subject}</strong>}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} style={{ width: "20%" }}>
        {isRead ? (
          `${row.permitName ? row.permitName : ""} ${
            row.conditionName ? row.conditionName : ""
          }`
        ) : (
          <strong>{`${row.permitName ? row.permitName : ""} ${
            row.conditionName ? row.conditionName : ""
          }`}</strong>
        )}
      </TableCell>
      <TableCell align="center" style={{ width: "5%" }}>
        {row.topMail.attachments && (
          <Stack direction="row">
            {row.topMail.attachments.length > 0 ? (
              <AttachmentTwoToneIcon />
            ) : null}
          </Stack>
        )}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          alignContent: "center",
          justifyContent: "center",
          position: "relative",
          textAlign: "center",
        }}
        style={{ width: "20%" }}
      >
        <p style={{ textAlign: "center" }}>
          {format(new Date(row.topMail.created), "d MMM yy")}
        </p>
      </TableCell>
    </TableRow>
  );
};
