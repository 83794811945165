import ChecklistIcon from "@mui/icons-material/Checklist";
import GroupIcon from "@mui/icons-material/Group";
import InfoIcon from "@mui/icons-material/Info";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { List } from "@mui/material";

import TabButton from "../TabButton/TabButton";

interface ConditionTabsProps {
  loading: boolean;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const ConditionTabs = ({
  loading,
  selectedTab,
  setSelectedTab,
}: ConditionTabsProps): JSX.Element => {
  const handleChange = (newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <List
      className="mailList"
      sx={{ display: "flex", maxWidth: "max-content" }}
    >
      <TabButton
        isLoading={loading}
        title={"Checklist"}
        tabIndex={0}
        icon={<ChecklistIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      <TabButton
        isLoading={loading}
        title={"Permit Details"}
        tabIndex={1}
        icon={<InfoIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      {/* <TabButton
        isLoading={loading}
        title={"Correspondence"}
        tabIndex={2}
        icon={<EmailIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      /> */}
      <TabButton
        isLoading={loading}
        title={"Contacts"}
        tabIndex={3}
        icon={<GroupIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      <TabButton
        isLoading={loading}
        title={"Activity"}
        tabIndex={4}
        icon={<SwapVerticalCircleIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      {/* <TabButton
        isLoading={loading}
        title={"Inspections"}
        tabIndex={5}
        icon={<TroubleshootIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      <TabButton
        isLoading={loading}
        title={"Fees"}
        tabIndex={6}
        icon={<PaidIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      />
      <TabButton
        isLoading={loading}
        title={"Lodgements & Assessments"}
        tabIndex={7}
        icon={<AddTaskIcon />}
        selectedTab={selectedTab}
        handleSelect={handleChange}
      /> */}
    </List>
  );
};
