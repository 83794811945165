import EmailIcon from "@mui/icons-material/Email";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import GroupIcon from "@mui/icons-material/Group";
import InfoIcon from "@mui/icons-material/Info";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { List } from "@mui/material";
import TabButton from "@stories/molecules/TabButton/TabButton";
import * as RouteHelper from "@utils/routes";
import { useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

interface ConditionTabsProps {
  loading: boolean;
  currentTab: number;
  setCurrentTab: (newTab: number) => void;
}

const getBaseUrl = (location: string): string => {
  const baseLocation = location.split("/");
  baseLocation.pop();
  return baseLocation.join("/");
};

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export const ConditionTabs = ({
  currentTab,
  loading,
  setCurrentTab,
}: ConditionTabsProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const baseUrl = getBaseUrl(location.pathname);

  const routeMatch = useRouteMatch([
    RouteHelper.condition(":projectId", ":permitId", ":conditionId"),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "properties"
    ),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "documents"
    ),
    RouteHelper.condition(":projectId", ":permitId", ":conditionId", "mail"),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "collaborators"
    ),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "event-log"
    ),
  ]);

  useEffect(() => {
    const currentPathId =
      routeMatch?.pattern?.path.split("/").pop() || "properties";
    if (currentPathId === "properties") {
      setCurrentTab(0);
    } else if (currentPathId === "documents") {
      setCurrentTab(1);
    } else if (currentPathId === "mail") {
      setCurrentTab(2);
    } else if (currentPathId === "collaborators") {
      setCurrentTab(3);
    } else if (currentPathId === "event-log") {
      setCurrentTab(4);
    }
  }, [location]);
  return (
    <List
      className="mailList"
      sx={{ display: "flex", maxWidth: "max-content" }}
    >
      <TabButton
        isLoading={loading}
        title={"Properties"}
        tabIndex={0}
        icon={<InfoIcon />}
        selectedTab={currentTab}
        handleSelect={() => {
          navigate(`${baseUrl}/properties`);
        }}
      />
      <TabButton
        isLoading={loading}
        title={"Documents"}
        tabIndex={1}
        icon={<FilePresentIcon />}
        selectedTab={currentTab}
        handleSelect={() => {
          navigate(`${baseUrl}/documents`);
        }}
      />
      <TabButton
        isLoading={loading}
        title={"Correspondence"}
        tabIndex={2}
        icon={<EmailIcon />}
        selectedTab={currentTab}
        handleSelect={() => {
          navigate(`${baseUrl}/mail`);
        }}
      />
      <TabButton
        isLoading={loading}
        title={"Collaborators"}
        tabIndex={3}
        icon={<GroupIcon />}
        selectedTab={currentTab}
        handleSelect={() => {
          navigate(`${baseUrl}/collaborators`);
        }}
      />
      <TabButton
        isLoading={loading}
        title={"Activity"}
        tabIndex={4}
        icon={<SwapVerticalCircleIcon />}
        selectedTab={currentTab}
        handleSelect={() => {
          navigate(`${baseUrl}/event-log`);
        }}
      />
    </List>
  );
};
