import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import MailList from "./MailList";

interface MailTableProps {
  loading: boolean;
  mailThreads: MailThreadLineItem[];
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  setSelectedMail: (mail: MailThreadLineItem) => void;
}

const MailTable = ({
  loading,
  mailThreads,
  profile,
  setSelectedMail,
}: MailTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newString = event.target.value;
    setSearchTerm(newString);
  };

  return (
    <MailList
      isLoading={loading}
      data={mailThreads}
      profile={profile}
      search={searchTerm}
      handleSearch={handleSearch}
      setSelectedMail={setSelectedMail}
    />
  );
};

export default MailTable;
