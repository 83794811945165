import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import { gridSpacing } from "constant";

const MailEmpty = () => {
  return (
    <TableRow className="emptyrow nofoundrow ">
      <TableCell colSpan={7}>
        <Stack alignItems="center" spacing={gridSpacing}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            No mail found
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default MailEmpty;
