"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentCategorysToTitlePrefix = exports.documentCategoryToString = exports.DocumentCategory = exports.createDefaultConditionDocument = void 0;
var uuid_1 = require("uuid");
var condition_1 = require("../entities/condition");
var string_1 = require("../utils/string");
var createDefaultConditionDocument = function (projectId, file, createdByName, documentCount, url, project, permit, condition, profileId, shareConditionId) {
    var conditionId = (0, uuid_1.v4)();
    var intialRevision = {
        conditionDocumentId: conditionId,
        created: new Date(),
        createdByName: createdByName,
        fileSize: file.size,
        fileType: file.type,
        id: (0, uuid_1.v4)(),
        profileId: profileId,
        revisionDate: new Date(),
        revisionNumber: 1,
        shareConditionId: shareConditionId,
        url: url,
    };
    return {
        condition: condition,
        conditionId: condition.id,
        created: new Date(),
        createdBy: (intialRevision === null || intialRevision === void 0 ? void 0 : intialRevision.profileId) || (intialRevision === null || intialRevision === void 0 ? void 0 : intialRevision.shareConditionId) || "",
        createdByName: createdByName,
        documentCategory: DocumentCategory.Other,
        documentDiscipline: condition_1.ConditionDiscipline.Other,
        documentNumber: (0, string_1.generateDocumentName)((project === null || project === void 0 ? void 0 : project.projectName) || "", condition_1.ConditionDiscipline.Other, DocumentCategory.Other, documentCount),
        documentTitle: (0, string_1.removeFileExtension)(file.name),
        file: file,
        fileSize: file.size,
        fileType: file.type,
        id: conditionId,
        modified: new Date(),
        permitId: permit.id,
        projectId: projectId,
        revisionDate: new Date(),
        revisionNumber: 1,
        revisions: [intialRevision],
        url: url,
    };
};
exports.createDefaultConditionDocument = createDefaultConditionDocument;
var DocumentCategory;
(function (DocumentCategory) {
    DocumentCategory["AsBuiltDrawings"] = "document-category__as-built-drawings";
    DocumentCategory["AuthorityConsentsPermitsCertificates"] = "document-category__authority-consents-permits-certificates";
    DocumentCategory["ContractAgreement"] = "document-category__contract-agreement";
    DocumentCategory["Drawings"] = "document-category__drawings";
    DocumentCategory["Emails"] = "document-category__emails";
    DocumentCategory["InspectionReports"] = "document-category__inspection-reports";
    DocumentCategory["InsuranceCertificates"] = "document-category__insurance-certificates";
    DocumentCategory["Invoices"] = "document-category__invoices";
    DocumentCategory["Letters"] = "document-category__letters";
    DocumentCategory["Licenses"] = "document-category__licenses";
    DocumentCategory["MeetingMinutes"] = "document-category__meeting-minutes";
    DocumentCategory["PaymentSchedules"] = "document-category__payment-schedules";
    DocumentCategory["ProductComplianceCertificates"] = "document-category__product-compliance-certificates";
    DocumentCategory["ProjectBriefingDocuments"] = "document-category__project-briefing-documents";
    DocumentCategory["QualityControlReports"] = "document-category__quality-control-reports";
    DocumentCategory["Receipt"] = "document-category__receipt";
    DocumentCategory["Register"] = "document-category__register";
    DocumentCategory["RegulatoryComplianceDocuments"] = "document-category__regulatory-compliance-documents";
    DocumentCategory["Report"] = "document-category__report";
    DocumentCategory["SafetyCertificate"] = "document-category__safety-certificate";
    DocumentCategory["ServiceAgreements"] = "document-category__service-agreements";
    DocumentCategory["SitePlans"] = "document-category__site-plans";
    DocumentCategory["Specifications"] = "document-category__specifications";
    DocumentCategory["SubcontractorAgreements"] = "document-category__subcontractor-agreements";
    DocumentCategory["SurveyPlans"] = "document-category__survey-plans";
    DocumentCategory["TechnicalSpecifications"] = "document-category__technical-specifications";
    DocumentCategory["TestResults"] = "document-category__test-results";
    DocumentCategory["Other"] = "document-category__other";
})(DocumentCategory || (exports.DocumentCategory = DocumentCategory = {}));
var documentCategoryToString = function (documentCategory) {
    switch (documentCategory) {
        case DocumentCategory.AsBuiltDrawings:
            return "As Built Drawings";
        case DocumentCategory.AuthorityConsentsPermitsCertificates:
            return "Authority Consents Permits Certificates";
        case DocumentCategory.ContractAgreement:
            return "Contract Agreement";
        case DocumentCategory.Drawings:
            return "Drawings";
        case DocumentCategory.Emails:
            return "Emails";
        case DocumentCategory.InspectionReports:
            return "Inspection Reports";
        case DocumentCategory.InsuranceCertificates:
            return "Insurance Certificates";
        case DocumentCategory.Invoices:
            return "Invoices";
        case DocumentCategory.Letters:
            return "Letters";
        case DocumentCategory.Licenses:
            return "Licenses";
        case DocumentCategory.MeetingMinutes:
            return "Meeting Minutes";
        case DocumentCategory.PaymentSchedules:
            return "Payment Schedules";
        case DocumentCategory.ProductComplianceCertificates:
            return "Product Compliance Certificates";
        case DocumentCategory.ProjectBriefingDocuments:
            return "Project Briefing Documents";
        case DocumentCategory.QualityControlReports:
            return "Quality Control Reports";
        case DocumentCategory.Receipt:
            return "Receipt";
        case DocumentCategory.Register:
            return "Register";
        case DocumentCategory.RegulatoryComplianceDocuments:
            return "Regulatory Compliance Documents";
        case DocumentCategory.Report:
            return "Report";
        case DocumentCategory.SafetyCertificate:
            return "Safety Certificate";
        case DocumentCategory.ServiceAgreements:
            return "Service Agreements";
        case DocumentCategory.SitePlans:
            return "Site Plans";
        case DocumentCategory.Specifications:
            return "Specifications";
        case DocumentCategory.SubcontractorAgreements:
            return "Subcontractor Agreements";
        case DocumentCategory.SurveyPlans:
            return "Survey Plans";
        case DocumentCategory.TechnicalSpecifications:
            return "Technical Specifications";
        case DocumentCategory.TestResults:
            return "Test Results";
        case DocumentCategory.Other:
            return "Other";
    }
};
exports.documentCategoryToString = documentCategoryToString;
var documentCategorysToTitlePrefix = function (documentCategory) {
    switch (documentCategory) {
        case DocumentCategory.AsBuiltDrawings:
            return "ABD";
        case DocumentCategory.AuthorityConsentsPermitsCertificates:
            return "ACP";
        case DocumentCategory.ContractAgreement:
            return "CA";
        case DocumentCategory.Drawings:
            return "D";
        case DocumentCategory.Emails:
            return "E";
        case DocumentCategory.InspectionReports:
            return "IR";
        case DocumentCategory.InsuranceCertificates:
            return "IC";
        case DocumentCategory.Invoices:
            return "INV";
        case DocumentCategory.Letters:
            return "LET";
        case DocumentCategory.Licenses:
            return "LIC";
        case DocumentCategory.MeetingMinutes:
            return "MM";
        case DocumentCategory.PaymentSchedules:
            return "PS";
        case DocumentCategory.ProductComplianceCertificates:
            return "PCC";
        case DocumentCategory.ProjectBriefingDocuments:
            return "PBD";
        case DocumentCategory.QualityControlReports:
            return "QCR";
        case DocumentCategory.Receipt:
            return "RCP";
        case DocumentCategory.Register:
            return "REG";
        case DocumentCategory.RegulatoryComplianceDocuments:
            return "RCD";
        case DocumentCategory.Report:
            return "REP";
        case DocumentCategory.SafetyCertificate:
            return "SC";
        case DocumentCategory.ServiceAgreements:
            return "SA";
        case DocumentCategory.SitePlans:
            return "SP";
        case DocumentCategory.Specifications:
            return "SPEC";
        case DocumentCategory.SubcontractorAgreements:
            return "SCA";
        case DocumentCategory.SurveyPlans:
            return "SUR";
        case DocumentCategory.TechnicalSpecifications:
            return "TS";
        case DocumentCategory.TestResults:
            return "TR";
        case DocumentCategory.Other:
            return "OTH";
    }
};
exports.documentCategorysToTitlePrefix = documentCategorysToTitlePrefix;
