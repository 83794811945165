import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RestoreIcon from "@mui/icons-material/Restore";
import { Chip, IconButton, TableCell, TableRow, Box } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { PermitStatus } from "permit-one-common/src/entities/permit";
import {
  PermitType,
  permitTypeToString,
} from "permit-one-common/src/entities/permit";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  PermitLineItem,
  permitStatusToColor,
  permitStatusToString,
} from "permit-one-common/src/interfaces/permit";
import {
  ProfileLineItem,
  getProfileName,
} from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { daysActive } from "permit-one-common/src/utils/date";
import { useState, useRef } from "react";

interface PermitTableRowProps {
  userOrganisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit: PermitLineItem;
  handleViewPermit: (permit: PermitLineItem) => void;
  handleEditPermit: (permit: PermitLineItem) => void;
  handleDeletePermit: (permit: PermitLineItem) => void;
  handleDestroyPermit: (permit: PermitLineItem) => void;
  handleRestorePermit: (permit: PermitLineItem) => void;
}

export const PermitTableRow = ({
  handleDeletePermit,
  handleEditPermit,
  handleRestorePermit,
  handleViewPermit,
  permit,
  profile,
  project,
  userOrganisation,
}: PermitTableRowProps) => {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleRowClick = (permit: PermitLineItem) => {
    handleViewPermit(permit);
  };

  const {
    approvers,
    created,
    id,
    managers,
    permitName,
    permitType,
    permitTypeOther,
    status,
  } = permit;

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);

  const canEdit = isProjectOwner || isPermitManager;

  const menuItems = [
    {
      action: () => {
        handleClose();
        handleRowClick(permit);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
  ];
  if (canEdit) {
    menuItems.push({
      action: () => {
        handleClose();
        handleEditPermit(permit);
      },
      color: "black",
      icon: <EditIcon />,
      name: "Edit",
    });
    if (permit.status === PermitStatus.Archived) {
      menuItems.push({
        action: () => {
          handleClose();
          setOpenConfirmDialog(true);
        },
        color: "green",
        icon: <RestoreIcon />,
        name: "Restore",
      });
    } else {
      menuItems.push({
        action: () => {
          handleClose();
          setOpenConfirmDialog(true);
        },
        color: "red",
        icon: <DeleteIcon />,
        name: "Archive",
      });
    }
  }
  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox">
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "5%" }}
        >
          <Chip
            label={permitStatusToString(status)}
            sx={{ backgroundColor: permitStatusToColor(status) }}
            variant="filled"
            background-color={permitStatusToColor(status)}
            className="chip"
          />
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {permitName}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {permitType !== PermitType.Other
            ? permitTypeToString(permitType)
            : permitTypeOther}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {managers.length > 0
            ? managers.map((a) => getProfileName(a)).join(", ")
            : "Not Assigned"}
        </TableCell>

        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {approvers.length > 0
            ? approvers.map((a) => getProfileName(a)).join(", ")
            : "Not Assigned"}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {daysActive(created)}
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }} ref={ref}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                handleClick();
              }}
            >
              <Iconify icon={"eva:more-vertical-fill"} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
      <ConfirmationDialog
        message={
          permit.status !== PermitStatus.Archived
            ? "Are you sure you want to archive this permit?"
            : "Are you sure you want to restore this permit?"
        }
        open={openConfirmDialog}
        title={
          permit.status !== PermitStatus.Archived
            ? "Archive Permit"
            : "Restore Permit"
        }
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={
          permit.status !== PermitStatus.Archived
            ? () => handleDeletePermit(permit)
            : () => handleRestorePermit(permit)
        }
      />
    </>
  );
};
