import { Avatar, Stack, TableCell, TableRow } from "@mui/material";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";

interface DirectoryTableRowProps {
  isSelected: boolean;
  contact: DirectoryContactLineItem;
}

export const DirectoryTableRow = ({
  contact,
  isSelected,
}: DirectoryTableRowProps) => {
  const displayName = `${contact.firstName} ${contact.lastName}`;

  return (
    <TableRow hover tabIndex={-1} role="checkbox" selected={isSelected}>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {contact.photoUrl ? (
            <Avatar
              alt={`${contact.firstName} ${contact.lastName}`}
              src={contact.photoUrl}
              sx={{ background: "#fff", marginRight: "20px" }}
            />
          ) : null}
          {displayName}
        </Stack>
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.organisationName}
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.jobTitle}
      </TableCell>
      <TableCell
        align="center"
        sx={{ textAlign: "center !important", width: "20%" }}
      >
        {contact.email}
      </TableCell>
      <TableCell
        align="center"
        sx={{ textAlign: "center !important", width: "20%" }}
      >
        {contact.phoneNumber}
      </TableCell>
    </TableRow>
  );
};
