import { Grid } from "@mui/material";
import { SubTitleWithImage } from "@stories/atoms/SubTitle/SubTitle";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface PageHeaderWithIconProps {
  loading: boolean;
  title: string;
  userProfile?: ProfileLineItem;
}
export const PageHeaderWithIcon = ({
  loading,
  title,
  userProfile,
}: PageHeaderWithIconProps): JSX.Element => {
  return (
    <Grid
      className="borderBottom add-project"
      container
      spacing={2}
      sx={{
        alignItems: "center",
        display: "flex",
        margin: "0 0 40px",
        padding: "10px 30px",
        width: "100%",
      }}
    >
      <Grid item xs={12} md={10} style={{ padding: "0" }}>
        <SubTitleWithImage
          loading={loading}
          title={title}
          url={userProfile?.photoUrl || ""}
        />
      </Grid>
    </Grid>
  );
};
