import { TableCell, TableRow, Skeleton, Button, Chip } from "@mui/material";

function PublicEditableTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell sx={{ width: "5%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto", width: "80%" }}>
          <Chip label="Not Assigned" />
        </Skeleton>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ margin: "auto", width: "100%" }}
        >
          <Button>Button</Button>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
}

export default PublicEditableTableRowSkeleton;
