import { FormControlLabel, FormGroup, Skeleton, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import * as yup from "yup";

interface NotificationsFormProps {
  profile?: ProfileLineItem;
  loading: boolean;
  handleSubmit: (profile: ProfileLineItem) => void;
}

export const NotificationsForm = ({
  handleSubmit,
  loading,
  profile,
}: NotificationsFormProps) => {
  const validationSchema = yup.object({});

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      messageNotifications: profile?.messageNotifications || false,
      taskNotifications: profile?.taskNotifications || false,
    },
    onSubmit: async (values) => {
      if (profile) {
        await handleSubmit({
          ...profile,
          messageNotifications: values.messageNotifications,
          taskNotifications: values.taskNotifications,
        });
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0",
          padding: "0 40px",
          width: "100%",
        }}
      >
        <Grid item sm={6}>
          <FormGroup>
            <FormControlLabel
              control={
                !loading ? (
                  <Switch
                    defaultChecked
                    value={formik.values.taskNotifications}
                    onChange={(event, checked) => {
                      formik.setFieldValue("taskNotifications", checked);
                    }}
                  />
                ) : (
                  <Skeleton animation="wave">
                    <Switch
                      defaultChecked
                      value={formik.values.taskNotifications}
                    />
                  </Skeleton>
                )
              }
              label="Task Notifications"
            />
          </FormGroup>
        </Grid>
        <Grid item sm={6} />
        <Grid item sm={6}>
          <FormGroup>
            <FormControlLabel
              control={
                !loading ? (
                  <Switch
                    defaultChecked
                    value={formik.values.messageNotifications}
                    onChange={(event, checked) => {
                      formik.setFieldValue("messageNotifications", checked);
                    }}
                  />
                ) : (
                  <Skeleton animation="wave">
                    <Switch
                      defaultChecked
                      value={formik.values.messageNotifications}
                    />
                  </Skeleton>
                )
              }
              label="Message Notifications"
            />
          </FormGroup>
        </Grid>
        <Grid item sm={6} />
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "flex-start",
            display: "flex !important",
            justifyContent: "right !important;",
            padding: "40px 0 0 10px !important",
          }}
        >
          <StyledButton
            loading={loading}
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading || !formik.dirty || formik.isSubmitting}
          >
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
