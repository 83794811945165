import { listApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { decodeDirectoryContact } from "permit-one-common/src/decoders/directoryContact";
import { Profile } from "permit-one-common/src/entities/profile";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import * as React from "react";

export const useDirectory = (
  projectId?: string,
  permitId?: string,
  conditionId?: string
) => {
  const [directory, setDirectory] = React.useState<DirectoryContactLineItem[]>(
    []
  );
  const [isDirectoryLoading, setIsDirectoryLoading] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const listDirectoryForCondition = async (id: string): Promise<void> => {
    try {
      setIsDirectoryLoading(true);
      const directoryResult = await listApiData(
        "listDirectoryForCondition",
        "directory",
        id
      );
      const directoryLineItems = await Promise.all(
        directoryResult.data.map((d) => decodeDirectoryContact(d as Profile, getImageUrl))
      );
      setDirectory(directoryLineItems);
    } catch (e: any) {
      setError("Could not list directory for condition");
    } finally {
      setIsDirectoryLoading(false);
    }
  };

  const listDirectoryForPermit = async (id: string): Promise<void> => {
    try {
      setIsDirectoryLoading(true);
      const directoryResult = await listApiData(
        "listDirectoryForPermit",
        "directory",
        id
      );
      const directoryLineItems = await Promise.all(
        directoryResult.data.map((d) => decodeDirectoryContact(d as Profile, getImageUrl))
      );
      setDirectory(directoryLineItems);
    } catch (e: any) {
      setError("Could not list directory for permit");
    } finally {
      setIsDirectoryLoading(false);
    }
  };

  const listDirectoryForProject = async (id: string): Promise<void> => {
    try {
      setIsDirectoryLoading(true);
      const directoryResult = await listApiData(
        "listDirectoryForProject",
        "directory",
        id
      );
      const directoryLineItems = await Promise.all(
        directoryResult.data.map((d) => decodeDirectoryContact(d as Profile, getImageUrl))
      );
      setDirectory(directoryLineItems);
    } catch (e: any) {
      setError("Could not list directory for project");
    } finally {
      setIsDirectoryLoading(false);
    }
  };

  React.useEffect(() => {
    if (conditionId) {
      listDirectoryForCondition(conditionId);
    } else if (permitId) {
      listDirectoryForPermit(permitId);
    } else if (projectId) {
      listDirectoryForProject(projectId);
    }
  }, [projectId]);

  return {
    directory,
    error,
    isDirectoryLoading,
  };
};
