import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import * as React from "react";

import { InviteForm } from "../InviteForm/InviteForm";


interface ContactModalProps {
  open: boolean;
  sendInvite: (profiles: ProfileLineItem[]) => Promise<void>;
  handleClose: () => void;
}

export default function ContactModal({
  handleClose,
  open,
  sendInvite,
}: ContactModalProps) {
  const onSubmit = async (users: ProfileLineItem[]) => {
    await sendInvite(users);
    handleClose();
  };
  const title = "Invite User";
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 34px 20px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            "&:hover": {
              background: "#023AF3",
            },
            background: "#011F44",
            color: "#fff",
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 10px 40px" }}>
        <InviteForm handleSubmit={onSubmit} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
