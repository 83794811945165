"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPermitLodgement = void 0;
var uuid_1 = require("uuid");
var createDefaultPermitLodgement = function (project, permit) {
    return {
        created: new Date(),
        id: (0, uuid_1.v4)(),
        lodgementName: "",
        permitId: permit.id,
        projectId: project.id,
        url: "",
    };
};
exports.createDefaultPermitLodgement = createDefaultPermitLodgement;
