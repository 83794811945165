import { useDownloadDocuments } from "@hooks/crud/useDownloadDocuments";
import { useTable } from "@hooks/utils/useTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Table, TableBody, Tooltip, Box } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { ListDocumentTabs } from "@stories/molecules/ListDocumentTabs/ListDocumentTabs";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { ConditionStatus } from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { useState } from "react";

import { DocumentTableRow } from "./DocumentTableRow";
import { DocumentTableRowSkeleton } from "./DocumentTableRowSkeleton";

interface DocumentTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  permits: PermitLineItem[];
  documents: ConditionDocumentLineItem[];
  handleOpenBulk: () => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleDeleteDocument: (document: ConditionDocumentLineItem) => void;
}

export const DocumentTable = ({
  documents,
  handleOpenBulk,
  loading,
  permits,
  project,
}: DocumentTableProps) => {
  const [documentKey, setDocumentKey] = useState<string>();
  const [selectedDocumentStatus, setSelectedDocumentStatus] =
    useState<number>(0);

  const [selectedPermitId, setSelectPermitId] = useState<string>();

  const handleDocumentStatusChange = (newValue: number) => {
    setSelectedDocumentStatus(newValue);
  };

  const handleView = (conditionDocument: ConditionDocumentLineItem) => {
    setDocumentKey(conditionDocument.url);
  };

  const {
    clearSelected,
    handleClick,
    handleFilterByName,
    handleSelectAllClick,
    selected,
  } = useTable();

  const { downloadProgress, downloadStep, getDocumentsZip } =
    useDownloadDocuments();

  const handleDownloadAll = async () => {
    if (project) {
      const selectedDocuments = documents
        .filter((d) => selected.includes(d.id))
        .map((d) => d.id);

      if (selectedDocuments.length > 0) {
        await getDocumentsZip(project.id, selectedDocuments);
      }
    }
  };

  const altControl = (
    <Tooltip title="Download All">
      <StyledButton
        loading={loading}
        className="css-kok4z1"
        variant="contained"
        startIcon={<CloudDownloadIcon />}
        onClick={handleDownloadAll}
      >
        Download
      </StyledButton>
    </Tooltip>
  );

  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: false, id: "document-number", label: "Perit Name + Scope" },
    { alignRight: true, id: "document-title", label: "Document Code" },
    { alignRight: false, id: "document-revision", label: "Document Title" },
    { alignRight: true, id: "document-discipline", label: "Revision" },
    { alignRight: true, id: "document-category", label: "Discipline" },
    { alignRight: true, id: "document-created-by", label: "Category" },
    { alignRight: true, id: "document-creator", label: "Creator" },
    { alignRight: true, id: "document-modified", label: "Uploaded Date" },
    { alignRight: true, id: "document-file-type", label: "Document Type" },
    { alignRight: false, id: "document-file-size", label: "Size" },
    { alignRight: false, id: "document-file-status", label: "Status" },
    { id: "", static: true },
  ];

  const { filterName, handleRequestSort, order, orderBy } = useTable();

  const filteredDocuments =
    documents.length > 0
      ? documents
          .filter(
            (u) =>
              u.documentTitle
                .toLowerCase()
                .includes(filterName.toLocaleLowerCase()) ||
              u.documentCategory
                .toLowerCase()
                .includes(filterName.toLocaleLowerCase())
          )
          .filter((d) => {
            if (selectedPermitId) {
              return d.permitId === selectedPermitId;
            }

            return d;
          })
      : documents;

  let sortedDocuments = filteredDocuments.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.condition.permitName.toLowerCase() <
          b.condition.permitName.toLowerCase()
          ? -1
          : 1;
      case 1:
        return a.documentNumber.toLowerCase() < b.documentNumber.toLowerCase()
          ? -1
          : 1;
      case 2:
        return a.documentTitle.length < b.documentTitle.length ? -1 : 1;
      case 3:
        return a.revisionNumber < b.revisionNumber ? -1 : 1;
      case 4:
        return a.documentDiscipline < b.documentDiscipline ? -1 : 1;
      case 5:
        return a.documentCategory < b.documentCategory ? -1 : 1;
      case 6:
        return a.createdByName < b.createdByName ? -1 : 1;
      case 8:
        return a.fileType < b.fileType ? -1 : 1;
      case 9:
        return a.fileSize < b.fileSize ? -1 : 1;
      case 10: {
        const sortOrder = ["not applicable", ...Object.values(ConditionStatus)];
        const statusA = a.condition.markedAsNotApplicable
          ? "not applicable"
          : a.condition.status;
        const statusB = b.condition.markedAsNotApplicable
          ? "not applicable"
          : b.condition.status;

        return sortOrder.indexOf(statusA) - sortOrder.indexOf(statusB);
      }
      default:
        return 0;
    }
  });

  sortedDocuments =
    order === "asc" ? sortedDocuments : sortedDocuments.reverse();

  return (
    <>
      <ListTableToolbar
        loading={loading}
        handleOpen={handleOpenBulk}
        searchTitle="Search Documents..."
        numSelected={selected.length}
        filterName={filterName}
        clearSelected={clearSelected}
        onFilter={handleFilterByName}
        altControl={altControl}
      />
      <ListDocumentTabs
        loading={loading}
        project={project}
        value={selectedDocumentStatus}
        handleChange={handleDocumentStatusChange}
        permitId={selectedPermitId}
        allPermits={permits}
        setPermitId={setSelectPermitId}
      />
      <Box sx={{ marginTop: "25px !important" }}>
        <Table stickyHeader aria-label="sticky table" className="project-table">
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={sortedDocuments.length}
            numSelected={selected.length}
            showCheckbox
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            onSelectAllClick={(e: any) =>
              handleSelectAllClick(
                e,
                filteredDocuments.map((u) => u.id)
              )
            }
          />
          <TableBody>
            {!loading
              ? sortedDocuments.map((row) => {
                  const selectedDocument = selected.indexOf(row.id) !== -1;
                  return (
                    <DocumentTableRow
                      key={row.id}
                      document={row}
                      selected={selectedDocument}
                      handleClick={handleClick}
                      handleView={handleView}
                    />
                  );
                })
              : null}
            {loading
              ? Array.from({ length: 6 }, (_, index) => (
                  <DocumentTableRowSkeleton key={index} />
                ))
              : null}
            {!loading && sortedDocuments.length === 0 ? (
              <NoRows colSpan={13} title={"documents"} />
            ) : null}
          </TableBody>
        </Table>
      </Box>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Exporting Documents Zip"}
      />
      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </>
  );
};
