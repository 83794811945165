import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";

import { DocumentForm } from "../DocumentForm/DocumentForm";


interface DocumentModalProps {
  open: boolean;
  project?: ProjectLineItem;
  condition?: ConditionLineItem;
  userProfile: ProfileLineItem;
  documentCount: number;
  existingDocument: ConditionDocumentLineItem;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
}

export default function DocumentModal({
  condition,
  existingDocument,
  handleClose,
  open,
  project,
  updateDocuments,
  userProfile,
}: DocumentModalProps) {
  const onSubmit = async (documents: ConditionDocumentLineItem[]) => {
    if (existingDocument) {
      await updateDocuments(documents);
    }
    handleClose();
  };
  const title = existingDocument ? "Edit Document" : "Create Document";
  return (
    <Dialog fullWidth open={open} onClose={handleClose} className="bgBlueOverlay">
        <DialogTitle
          sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                background: "#005cd1",
              },
              background: "#000",
              color: "#fff",
              position: "absolute",
              right: 14,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <DialogContentText sx={{color:'black', fontSize:'14px', fontWeight:400, lineHeight:'16px', padding:'0 0 20px'}}>
          Update document details or resubmit file
        </DialogContentText>
        <DocumentForm
          project={project}
          condition={condition}
          document={existingDocument}
          userProfile={userProfile}
          handleSubmit={onSubmit}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
