import { usePermitInspection } from "@hooks/crud/usePermitInspection";
import { useTable } from "@hooks/utils/useTable";
import { downloadFile } from "@hooks/utils/useUpload";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  Grid,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { useState } from "react";

import { InspectionTableRow } from "./InspectionTableRow";
import { InspectionTableRowSkeleton } from "./InspectionTableRowSkeleton";
import InspectionModal from "../../InspectionModal/InspectionModal";


interface InspectionTableProps {
  permitId?: string;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
}

export const InspectionTable = ({
  permit,
  permitId,
  project,
}: InspectionTableProps) => {
  const [open, setOpen] = useState(false);
  const {
    createPermitInspection,
    deletePermitInspection,
    isPermitInspectionsLoading,
    permitInspections,
  } = usePermitInspection(permitId);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [selectedId, setSelectedId] = useState<string>();

  const onDownload = async (uri: string, greenMetricName: string) => {
    setDownloadProgress(0);
    setIsDownloading(true);
    await downloadFile(uri, greenMetricName, (progress: any) => {
      const total = (progress.loaded / progress.total) * 100;
      setDownloadProgress(total);
    });
    setIsDownloading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "name", label: "Name" },
    { id: "created", label: "Created" },
    { id: "", label: "Download", static: true },
    { id: "", label: "Delete", static: true },
  ];

  const {
    filterName,
    handleFilterByName,
    handleRequestSort,
    order,
    orderBy,
    selected,
  } = useTable();

  const filteredUsers =
    filterName.length > 0
      ? permitInspections.filter((u) =>
          u.inspectionName
            .toLowerCase()
            .includes(filterName.toLocaleLowerCase())
        )
      : permitInspections;

  let sortedInspections = filteredUsers.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.inspectionName.toLowerCase() < b.inspectionName.toLowerCase()
          ? -1
          : 1;
      case 1:
        return a.created.getTime() < b.created.getTime() ? -1 : 1;
      default:
        return 0;
    }
  });

  sortedInspections =
    order === "asc" ? sortedInspections : sortedInspections.reverse();

  const isNotFound = !sortedInspections.length && !!filterName;

  const loading = isPermitInspectionsLoading;
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 0 20px",
        }}
      >
        <Grid item md={3} sx={{ padding: "0" }}>
          <StyledTextField
            className="search"
            loading={loading}
            type="text"
            placeholder={"Search Inspections..."}
            name="filterProject"
            value={filterName}
            onChange={handleFilterByName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          md={8}
          sx={{
            alignItems: "center",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            padding: "0",
          }}
        >
          <StyledButton
            loading={false}
            onClick={handleOpen}
            variant="contained"
            disabled={isPermitInspectionsLoading}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {"Add Inspection"}
          </StyledButton>
        </Grid>
      </Grid>

      <TableContainer sx={{ minWidth: 800 }}>
        <Table stickyHeader aria-label="sticky table" className="project-table">
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={sortedInspections.length}
            numSelected={selected.length}
            showCheckbox={false}
            onSelectAllClick={() => {
              console.log("Not implemented");
            }}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableBody>
            {!loading
              ? sortedInspections
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isSelected = selected.indexOf(row.id) !== -1;
                    return (
                      <InspectionTableRow
                        key={row.id}
                        selected={isSelected}
                        inspection={row}
                        isDownloading={isDownloading}
                        selectedId={selectedId}
                        downloadProgress={downloadProgress}
                        setSelectedId={setSelectedId}
                        onDownload={onDownload}
                        deletePermitInspection={deletePermitInspection}
                      />
                    );
                  })
              : null}
            {isNotFound && (
              <NoResultsFoundRow
                colSpan={TABLE_HEAD.length + 1}
                filterName={filterName}
              />
            )}
            {sortedInspections.length === 0 && !isPermitInspectionsLoading ? (
              <NoRows colSpan={TABLE_HEAD.length + 1} title={"inspections"} />
            ) : null}
            {loading
              ? Array.from({ length: 6 }, (_, index) => (
                  <InspectionTableRowSkeleton key={index} />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {open && project && permit ? (
        <InspectionModal
          open={open}
          project={project}
          permit={permit}
          createInspection={createPermitInspection}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};
