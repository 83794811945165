import { Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";

// ===========================|| CONTACT CARD - FORMS ||=========================== //

export const sendContactEmail = async (
  name: string,
  company: string,
  phone: string,
  email: string,
  message: string
) => {
  const res = await fetch(
    `https://f8gtp0moq5.execute-api.ap-southeast-2.amazonaws.com/dev/signIn`,
    {
      body: JSON.stringify({ company, email, message, name, phone }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  return res.json();
};

export const verifyRecaptcha = async (token: string) => {
  const res = await fetch(
    `https://f8gtp0moq5.execute-api.ap-southeast-2.amazonaws.com/dev/signIn`,
    {
      body: JSON.stringify({ token }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  return res.json();
};

export const ContactForm = () => {
  const captchaRef = useRef<ReCAPTCHA>(null);

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
    // subject: Yup.string().required("Subject is required"),
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      company: "",
      email: "",
      message: "",
      name: "",
      phone: "",
    },
    onSubmit: async (values) => {
      if (captchaRef.current) {
        try {
          const token = captchaRef.current.getValue();
          if (token) {
            const res = await verifyRecaptcha(token);
            if (res.status) {
              sendContactEmail(
                values.name,
                values.company,
                values.phone,
                values.email,
                values.message
              );
              toast("Message sent - we will respond shortly!", {
                type: "success",
              } as ToastOptions);
              formik.resetForm();
            } else {
              toast("Recaptcha failed! Nice try, robot.", {
                type: "error",
              } as ToastOptions);
            }
            captchaRef.current.reset();
          } else {
            toast("Recaptcha not valid", {
              type: "error",
            } as ToastOptions);
          }
        } catch (e) {
          toast("Failed to send - something went wrong!", {
            type: "error",
          } as ToastOptions);
        }
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-control-new">
        <label className="form-label-new">Full Name *</label>
        <div className="contact-form">
          <input
            type="text"
            id="name"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Full Name"
          />
          <Typography
            className="danger"
            color={(theme) => {
              return theme.palette.error.main;
            }}
          >
            {formik.errors.name ? formik.errors.name : null}
          </Typography>
        </div>
      </div>
      <div className="form-control-new">
        <label className="form-label-new">Company Name *</label>
        <div className="contact-form">
          <input
            type="text"
            id="company"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
            placeholder="Company Name"
          />
          <Typography
            className="danger"
            color={(theme) => {
              return theme.palette.error.main;
            }}
          >
            {formik.errors.company ? formik.errors.company : null}
          </Typography>
        </div>
      </div>
      <div className="form-control-new">
        <label className="form-label-new">Contact Number *</label>
        <div className="contact-form">
          <input
            type="text"
            id="phone"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            placeholder="e.g., +61 123 456 789"
          />

          <Typography
            className="danger"
            color={(theme) => {
              return theme.palette.error.main;
            }}
          >
            {formik.errors.phone ? formik.errors.phone : null}
          </Typography>
        </div>
      </div>
      <div className="form-control-new">
        <label className="form-label-new">Email Address *</label>
        <div className="contact-form">
          <input
            type="email"
            id="email"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="e.g., email@example.com"
          />

          <Typography
            className="danger"
            color={(theme) => {
              return theme.palette.error.main;
            }}
          >
            {formik.errors.email ? formik.errors.email : null}
          </Typography>
        </div>
      </div>
      <div className="form-control-new">
        <label className="form-label-new">Message*</label>
        <div className="contact-form">
          <textarea
            id="message"
            name="message"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            rows={4}
            placeholder="Enter text here"
          />
          <Typography
            className="danger"
            color={(theme) => {
              return theme.palette.error.main;
            }}
          >
            {formik.errors.message ? formik.errors.message : null}
          </Typography>
        </div>
      </div>
      <div className="form-control-new">
        <ReCAPTCHA
          ref={captchaRef}
          sitekey="6Le2DL0mAAAAAHGi46Uf3NpJS7yRih2-gNExcpS4"
          aria-disabled={formik.isSubmitting}
        />
      </div>

      <button
        type="submit"
        className="contact-form-btn"
        disabled={formik.isSubmitting}
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
