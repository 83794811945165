import { listApiData, postApiData } from "@hooks/utils/api";
import { decodePermitFee, encodePermitFee } from "permit-one-common/src/decoders/permitFee";
import { PermitFee } from "permit-one-common/src/entities/permitFee";
import { PermitFeeLineItem } from "permit-one-common/src/interfaces/permitFee";
import * as React from "react";

export const usePermitFee = (permitId?: string) => {
  const [permitFees, setPermitFees] = React.useState<PermitFeeLineItem[]>([]);

  const [isPermitFeesLoading, setIsPermitFeesLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listPermitFees = async (permitId: string): Promise<void> => {
    try {
      setIsPermitFeesLoading(true);
      if (permitId) {
        const mailThreadResult = await listApiData(
          "listPermitFees",
          "permitFee",
          permitId
        );
        const permitFeeLineItems = mailThreadResult.data.map((m) =>
          decodePermitFee(m as PermitFee)
        );

        setPermitFees(permitFeeLineItems);
      }
    } catch (e: any) {
      setError("Could not list fees");
    } finally {
      setIsPermitFeesLoading(false);
    }
  };

  const createPermitFee = async (
    permitFee: PermitFeeLineItem
  ): Promise<void> => {
    try {
      setIsPermitFeesLoading(true);
      await postApiData(
        "createPermitFee",
        "permitFee",
        encodePermitFee(permitFee)
      );

      setPermitFees([...permitFees, permitFee]);
    } catch (e: any) {
      setError("Could not create permit fee");
    } finally {
      setIsPermitFeesLoading(false);
    }
  };

  const deletePermitFee = async (
    permitFee: PermitFeeLineItem
  ): Promise<void> => {
    try {
      setIsPermitFeesLoading(true);
      await postApiData(
        "deletePermitFee",
        "permitFee",
        encodePermitFee(permitFee)
      );

      const remainingPermitFees = permitFees.filter(
        (p) => p.id !== permitFee.id
      );
      setPermitFees(remainingPermitFees);
    } catch (e: any) {
      setError("Could not create permit fee");
    } finally {
      setIsPermitFeesLoading(false);
    }
  };

  React.useEffect(() => {
    if (permitId) {
      listPermitFees(permitId);
    }
  }, [permitId]);

  return {
    createPermitFee,
    deletePermitFee,
    error,
    isPermitFeesLoading,
    listPermitFees,
    permitFees,
  };
};
