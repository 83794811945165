import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  SlideProps,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { forwardRef, useState } from "react";

import { MailForm } from "../MailForm/MailForm";

interface ComposeDialogProps {
  isLoading: boolean;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  handleSubmit: (mailThread: MailThreadLineItem) => void;
}

const Transition = forwardRef((props: SlideProps, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const ComposeDialog = ({
  condition,
  handleSubmit,
  isLoading,
  permit,
  profile,
  project,
}: ComposeDialogProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmitMailThread = (mailThread: MailThreadLineItem) => {
    handleSubmit(mailThread);
    setOpen(false);
  };

  const loading = isLoading;
  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" animation="wave">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{ width: "100%" }}
            size="large"
            startIcon={<AddCircleOutlineTwoToneIcon />}
          >
            Compose
          </Button>
        </Skeleton>
      ) : (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ width: "100%" }}
          size="large"
          startIcon={<AddCircleOutlineTwoToneIcon />}
        >
          Compose
        </Button>
      )}
      {open && profile ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClickClose}
          className="bgBlueOverlay"
        >
          <DialogTitle
            sx={{ fontSize: "20px !important", padding: "30px 30px 25px" }}
          >
            New Message
            <IconButton
              aria-label="close"
              onClick={handleClickClose}
              sx={{
                "&:hover": {
                  background: "#023AF3",
                },
                background: "#011F44",
                color: "#fff",
                position: "absolute",
                right: 14,
                top: 11,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: "30px 30px 40px" }}>
            <MailForm
              loading={loading}
              profile={profile}
              project={project}
              permit={permit}
              condition={condition}
              handleSubmit={handleSubmitMailThread}
              handleClose={handleClickClose}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

export default ComposeDialog;
