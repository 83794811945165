"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeFileGeneration = void 0;
var decodeFileGeneration = function (data) {
    return {
        created: new Date(data.created),
        fileGenerationId: data.file_generation_id,
        fileSize: data.file_size,
        organisationId: data.organisation_id,
        permitId: data.permit_id,
        progress: data.progress,
        projectId: data.project_id,
        status: data.status,
        url: data.url,
    };
};
exports.decodeFileGeneration = decodeFileGeneration;
