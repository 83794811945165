import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import { PageHeaderWithIcon } from "@stories/organisms/PageHeaderWithIcon/PageHeaderWithIcon";
import { ProfileForm } from "@stories/organisms/ProfileForm/ProfileForm";
import { SettingsTabs } from "@stories/organisms/SettingsTabs/SettingsTabs";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { BillingForm } from "./Billing/BillingForm";
import { NotificationsForm } from "./NotificationsForm";


export const UserAccount = () => {
  const { user } = useAuthContext();

  const [searchParams] = useSearchParams();
  const success = searchParams.get("success");
  const cancelled = searchParams.get("cancelled");
  const redirect = searchParams.get("redirect");
  // setSearchParams();

  if (!user) {
    throw Error("No user in state.");
  }

  const goToBilling = !!success || !!cancelled || !!redirect;
  const [selectedSettingsTab, setSelectedSettingsTab] = useState<number>(
    goToBilling ? 1 : 0
  );

  const {
    isAuthProfileLoading,
    updateUserProfile,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmit = async (profile: ProfileLineItem) => {
    if (userProfile) {
      await updateUserProfile(profile);
    }
  };

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedSettingsTab(newValue);
  };

  const loading = isAuthProfileLoading;

  const selectedSettingStep = (() => {
    switch (selectedSettingsTab) {
      default:
      case 0:
        return (
          <ProfileForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmit}
          />
        );
      case 1:
        return (
          <BillingForm
            loading={loading}
            profile={userProfile}
            organisation={userOrganisation}
            success={!!success}
            cancelled={!!cancelled}
          />
        );
      case 2:
        return (
          <NotificationsForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmit}
          />
        );
    }
  })();

  console.log("Render");
  return (
    <>
      <PageHeaderWithIcon
        loading={loading}
        title="User Account"
        userProfile={userProfile}
      />
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          margin: "0",
          padding: "0px 40px 0px 40px",
          width: "100%",
        }}
      >
        <Grid item md={12}>
          <SettingsTabs
            loading={loading}
            value={selectedSettingsTab}
            handleChange={handleConditionStatusChange}
          />
        </Grid>
        <Grid item>{selectedSettingStep}</Grid>
      </Grid>
    </>
  );
};
