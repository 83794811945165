"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermit = exports.decodePermit = exports.decodePermitCount = void 0;
var profile_1 = require("./profile");
var string_1 = require("../utils/string");
var decodePermitCount = function (permitCount) { return __awaiter(void 0, void 0, void 0, function () {
    var safePermit;
    return __generator(this, function (_a) {
        safePermit = (0, string_1.reversePostgresSafe)(permitCount);
        return [2 /*return*/, {
                active: safePermit.active,
                approved: safePermit.approved,
                archived: safePermit.archived,
                inReview: safePermit.in_review,
            }];
    });
}); };
exports.decodePermitCount = decodePermitCount;
var decodePermit = function (permit, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var safePermit, managerProfiles, approverProfiles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                safePermit = (0, string_1.reversePostgresSafe)(permit);
                return [4 /*yield*/, Promise.all(safePermit.managers.map(function (a) { return (0, profile_1.decodeProfile)(a, getImageUrl); }))];
            case 1:
                managerProfiles = _a.sent();
                return [4 /*yield*/, Promise.all(safePermit.approvers.map(function (a) { return (0, profile_1.decodeProfile)(a, getImageUrl); }))];
            case 2:
                approverProfiles = _a.sent();
                return [2 /*return*/, {
                        abn: safePermit.abn,
                        applicantContactPersonEmail: safePermit.applicant_contact_person_email,
                        applicantContactPersonName: safePermit.applicant_contact_person_name,
                        applicantContactPersonPhone: safePermit.applicant_contact_person_phone,
                        applicantCountry: safePermit.applicant_country,
                        applicantName: safePermit.applicant_name,
                        applicantPostCode: safePermit.applicant_post_code,
                        applicantState: safePermit.applicant_state,
                        applicantStreetName: safePermit.applicant_street_name,
                        applicantStreetNumber: safePermit.applicant_street_number,
                        applicantSuburb: safePermit.applicant_suburb,
                        approvedConditions: safePermit.approved_conditions,
                        approvers: approverProfiles,
                        archived: safePermit.archived,
                        assignedConditions: safePermit.assigned_conditions,
                        bcaVersion: safePermit.bca_version,
                        buildingClassification: safePermit.building_classification,
                        costOfPermitWorks: safePermit.cost_of_permit_works || undefined,
                        costOfProjectWorks: safePermit.cost_of_project_works || undefined,
                        councilAuthority: safePermit.council_authority || undefined,
                        created: new Date(safePermit.created),
                        developmentApprovalDate: safePermit.development_approval_date
                            ? new Date(safePermit.development_approval_date)
                            : undefined,
                        developmentApprovalLapseDate: safePermit.development_approval_lapse_date
                            ? new Date(safePermit.development_approval_lapse_date)
                            : undefined,
                        developmentApprovalNumber: safePermit.development_approval_number || undefined,
                        endDate: safePermit.end_date ? new Date(safePermit.end_date) : undefined,
                        id: safePermit.permit_id,
                        inReviewConditions: safePermit.in_review_conditions,
                        landCountry: safePermit.land_country,
                        landDpsMpsNumber: safePermit.land_dps_mps_number,
                        landLotNumber: safePermit.land_lot_number,
                        landPostCode: safePermit.land_post_code,
                        landState: safePermit.land_state,
                        landStreetName: safePermit.land_street_name,
                        landStreetNumber: safePermit.land_street_number,
                        landSuburb: safePermit.land_suburb,
                        managers: managerProfiles,
                        memorandumNote: safePermit.memorandum_note,
                        notAssignedConditions: safePermit.not_assigned_conditions,
                        overDueConditions: safePermit.overdue_conditions,
                        ownerAbn: safePermit.owner_abn,
                        ownerApplicant: safePermit.owner_applicant,
                        ownerApplicantName: safePermit.owner_applicant_name,
                        ownerContactPersonEmail: safePermit.owner_contact_person_email,
                        ownerContactPersonName: safePermit.owner_contact_person_name,
                        ownerContactPersonPhone: safePermit.owner_contact_person_phone,
                        ownerCountry: safePermit.owner_country,
                        ownerPostCode: safePermit.owner_post_code,
                        ownerState: safePermit.owner_state,
                        ownerStreetName: safePermit.owner_street_name,
                        ownerStreetNumber: safePermit.owner_street_number,
                        ownerSuburb: safePermit.owner_suburb,
                        permitDescription: safePermit.permit_description,
                        permitName: safePermit.permit_name,
                        permitNumber: safePermit.permit_number,
                        permitScope: safePermit.permit_scope,
                        permitType: safePermit.permit_type,
                        permitTypeOther: safePermit.permit_type_other,
                        projectId: safePermit.project_id,
                        rejectedConditions: safePermit.rejected_conditions,
                        riseInStoreys: safePermit.rise_in_storeys,
                        stageDetailsOfWork: safePermit.stage_details_of_work,
                        startDate: new Date(safePermit.start_date),
                        status: safePermit.status,
                        storeysContained: safePermit.storeys_contained,
                        totalConditions: safePermit.total_conditions,
                        transmittalNumber: safePermit.transmittal_number,
                        typeOfConstruction: safePermit.type_of_construction,
                        uri: safePermit.uri,
                    }];
        }
    });
}); };
exports.decodePermit = decodePermit;
var encodePermit = function (permit) {
    var safePermit = (0, string_1.makePostgresSafe)(permit);
    var managerProfiles = safePermit.managers.map(function (a) { return (0, profile_1.encodeProfile)(a); });
    var approverProfiles = safePermit.approvers.map(function (a) { return (0, profile_1.encodeProfile)(a); });
    return {
        abn: safePermit.abn,
        applicant_contact_person_email: safePermit.applicantContactPersonEmail,
        applicant_contact_person_name: safePermit.applicantContactPersonName,
        applicant_contact_person_phone: safePermit.applicantContactPersonPhone,
        applicant_country: safePermit.applicantCountry,
        applicant_name: safePermit.applicantName,
        applicant_post_code: safePermit.applicantPostCode,
        applicant_state: safePermit.applicantState,
        applicant_street_name: safePermit.applicantStreetName,
        applicant_street_number: safePermit.applicantStreetNumber,
        applicant_suburb: safePermit.applicantSuburb,
        approved_conditions: safePermit.approvedConditions,
        approvers: approverProfiles,
        archived: safePermit.archived,
        assigned_conditions: safePermit.assignedConditions,
        bca_version: safePermit.bcaVersion,
        building_classification: safePermit.buildingClassification,
        cost_of_permit_works: safePermit.costOfPermitWorks,
        cost_of_project_works: safePermit.costOfProjectWorks,
        council_authority: safePermit.councilAuthority,
        created: safePermit.created,
        development_approval_date: safePermit.developmentApprovalDate,
        development_approval_lapse_date: safePermit.developmentApprovalLapseDate,
        development_approval_number: safePermit.developmentApprovalNumber,
        end_date: safePermit.endDate,
        in_review_conditions: safePermit.inReviewConditions,
        land_country: safePermit.landCountry,
        land_dps_mps_number: safePermit.landDpsMpsNumber,
        land_lot_number: safePermit.landLotNumber,
        land_post_code: safePermit.landPostCode,
        land_state: safePermit.landState,
        land_street_name: safePermit.landStreetName,
        land_street_number: safePermit.landStreetNumber,
        land_suburb: safePermit.landSuburb,
        managers: managerProfiles,
        memorandum_note: safePermit.memorandumNote,
        not_assigned_conditions: safePermit.notAssignedConditions,
        overdue_conditions: safePermit.overDueConditions,
        owner_abn: safePermit.ownerAbn,
        owner_applicant: safePermit.ownerApplicant,
        owner_applicant_name: safePermit.ownerApplicantName,
        owner_contact_person_email: safePermit.ownerContactPersonEmail,
        owner_contact_person_name: safePermit.ownerContactPersonName,
        owner_contact_person_phone: safePermit.ownerContactPersonPhone,
        owner_country: safePermit.ownerCountry,
        owner_post_code: safePermit.ownerPostCode,
        owner_state: safePermit.ownerState,
        owner_street_name: safePermit.ownerStreetName,
        owner_street_number: safePermit.ownerStreetNumber,
        owner_suburb: safePermit.ownerSuburb,
        permit_description: safePermit.permitDescription,
        permit_id: safePermit.id,
        permit_name: safePermit.permitName,
        permit_number: safePermit.permitNumber,
        permit_scope: safePermit.permitScope,
        permit_type: safePermit.permitType,
        permit_type_other: safePermit.permitTypeOther,
        project_id: safePermit.projectId,
        rejected_conditions: safePermit.rejectedConditions,
        rise_in_storeys: safePermit.riseInStoreys,
        stage_details_of_work: safePermit.stageDetailsOfWork,
        start_date: safePermit.startDate,
        status: safePermit.status,
        storeys_contained: safePermit.storeysContained,
        total_conditions: safePermit.totalConditions,
        transmittal_number: safePermit.transmittalNumber,
        type_of_construction: safePermit.typeOfConstruction,
        uri: safePermit.uri,
    };
};
exports.encodePermit = encodePermit;
