import { downloadFile } from "@hooks/utils/useUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";
import {
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import { fileSizeToMb } from "permit-one-common/src/utils/number";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { useRef, useState } from "react";

interface DocumentTableRowProps {
  document: ConditionDocumentLineItem;
  selected: boolean;
  handleClick: (id: string) => void;
  handleView: (document: ConditionDocumentLineItem) => void;
}

export const DocumentTableRow = ({
  document,
  handleClick,
  handleView,
  selected,
}: DocumentTableRowProps) => {
  const ref = useRef<Element>();

  const [open, setOpen] = useState(false);

  const menuItems = [
    {
      action: () => {
        handleClose();
        handleView(document);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
    {
      action: () => {
        if (document && document.url.length > 0) {
          downloadFile(
            document.url,
            getDocumentName(document.documentTitle, document.url)
          );
        }
      },
      color: "black",
      icon: <CloudDownloadIcon />,
      name: "Download",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center">
          <Checkbox
            checked={selected}
            onChange={() => handleClick(document.id)}
          />
        </TableCell>
        <TableCell align="center">{document.condition.permitName}</TableCell>
        <TableCell align="center">{document.documentNumber}</TableCell>
        <TableCell align="center">{document.documentTitle}</TableCell>
        <TableCell align="center" sx={{ textAlign: "center !important" }}>
          {document.revisionNumber}
        </TableCell>
        <TableCell align="center">
          {conditionDisciplineToString(document.documentDiscipline)}
        </TableCell>
        <TableCell align="center">
          {documentCategoryToString(document.documentCategory)}
        </TableCell>
        <TableCell align="center">{document.createdByName}</TableCell>
        <TableCell align="center">
          {document.modified.toLocaleDateString()}
        </TableCell>
        <TableCell align="center">{document.fileType}</TableCell>
        <TableCell align="center" sx={{ textAlign: "center !important" }}>
          {fileSizeToMb(document.fileSize)}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            justifyContent: "center",
            width: "10%",
          }}
        >
          <Box display="flex" justifyContent="center">
            <Chip
              label={conditionStatusToString(
                document.condition.status,
                Boolean(document.condition.markedAsNotApplicable)
              )}
              variant="filled"
              background-color={conditionStatusToColor(
                document.condition.status,
                Boolean(document.condition.markedAsNotApplicable)
              )}
              sx={{
                backgroundColor: conditionStatusToColor(
                  document.condition.status,
                  Boolean(document.condition.markedAsNotApplicable)
                ),
                margin: "auto",
              }}
            />
          </Box>
        </TableCell>
        <TableCell align="right" ref={ref}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                handleOpen();
              }}
            >
              <Iconify icon={"eva:more-vertical-fill"} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
    </>
  );
};
