import { useProfileContext } from "@hooks/context/useProfileContext";
import { useDocument } from "@hooks/crud/useDocument";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import DocumentModal from "../DocumentModal/DocumentModal";
import { ConditionDocumentTable } from "../Tables/ConditionDocumentTable/ConditionDocumentTable";

interface ConditionDocumentsProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
}
export const ConditionDocuments = ({
  condition,
  isLoading,
  project,
}: ConditionDocumentsProps): JSX.Element => {
  const { userProfile } = useProfileContext();
  const {
    documents,
    isDocumentLoading,
    unassignDocumentsToConditions,
    updateDocuments,
  } = useDocument(undefined, undefined, condition?.id);

  const [openSingle, setOpenSingle] = useState(false);

  // const [openBulk, setOpenBulk] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<
    ConditionDocumentLineItem | undefined
  >(undefined);

  if (!userProfile) {
    return <PageLoader />;
  }

  const handleCloseSingle = () => {
    setOpenSingle(false);
    setSelectedDocument(undefined);
  };
  // const handleCloseBulk = () => {
  //   setOpenBulk(false);
  // };

  const handleOpenSingle = (document: ConditionDocumentLineItem) => {
    setSelectedDocument(document);
    setOpenSingle(true);
  };

  const handleOpenBulk = () => {
    //setOpenBulk(true);
  };

  const handleRemoveDocument = (document: ConditionDocumentLineItem) => {
    if (condition) {
      unassignDocumentsToConditions([document]);
    }
  };

  const loading = isLoading || isDocumentLoading;

  return (
    <>
      <ConditionDocumentTable
        project={project}
        handleOpenBulk={handleOpenBulk}
        documents={documents}
        loading={loading}
        handleEditDocument={handleOpenSingle}
        handleRemoveDocument={handleRemoveDocument}
      />
      {userProfile && selectedDocument ? (
        <DocumentModal
          open={openSingle}
          project={project}
          condition={condition}
          userProfile={userProfile}
          handleClose={handleCloseSingle}
          updateDocuments={updateDocuments}
          documentCount={documents.length}
          existingDocument={selectedDocument}
        />
      ) : null}
    </>
  );
};
