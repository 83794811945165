import { useTable } from "@hooks/utils/useTable";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, Table, TableBody, Box } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { GlobalDirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";

import { GlobalDirectoryTableRow } from "./GlobalDirectoryTableRow";
import { GlobalDirectoryTableRowSkeleton } from "./GlobalDirectoryTableRowSkeleton";

interface GlobalDirectoryTableProps {
  loading: boolean;
  directory: GlobalDirectoryContactLineItem[];
}

export const GlobalDirectoryTable = ({
  directory,
  loading,
}: GlobalDirectoryTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: true, id: "name", label: "Name" },
    { alignRight: true, id: "company", label: "Company" },
    { alignRight: true, id: "role", label: "Job Title" },
    { alignRight: false, id: "email", label: "Email" },
    { alignRight: false, id: "phone", label: "Phone" },
  ];

  const {
    filterName,
    handleFilterByName,
    handleRequestSort,
    order,
    orderBy,
    selected,
  } = useTable();

  const filteredUsers =
    filterName.length > 0
      ? directory.filter(
          (u) =>
            u.organisationName
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase()) ||
            u.jobTitle.toLowerCase().includes(filterName.toLocaleLowerCase()) ||
            u.email.toLowerCase().includes(filterName.toLocaleLowerCase()) ||
            u.organisationName
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase()) ||
            u.email.toLowerCase().includes(filterName.toLocaleLowerCase())
        )
      : directory;

  let sortedUsers = filteredUsers.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      case 1:
        return a.organisationName.toLowerCase() <
          b.organisationName.toLowerCase()
          ? -1
          : 1;
      case 2:
        return a.jobTitle.toLowerCase() < b.jobTitle.toLowerCase() ? -1 : 1;
      case 3:
        return a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1;
      case 4:
        return a.phoneNumber.toLowerCase() < b.phoneNumber.toLowerCase()
          ? -1
          : 1;
      default:
        return 0;
    }
  });

  sortedUsers = order === "asc" ? sortedUsers : sortedUsers.reverse();

  const isNotFound = !sortedUsers.length && !!filterName;

  return (
    <>
      <Box
        sx={{
          background: "transparent",
          typography: "body1",
          width: "100%",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            margin: "0",
            marginBottom: "20px",
            padding: "0",
            width: "100%",
          }}
        >
          <Grid item md={3}>
            <StyledTextField
              className="search"
              loading={loading}
              type="text"
              placeholder="Search Directory..."
              name="filterProject"
              value={filterName}
              onChange={handleFilterByName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Table
        stickyHeader
        aria-label="sticky table"
        className="project-table directory-tb"
      >
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={directory.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={() => {
            console.log("Not implemented");
          }}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />
        <TableBody>
          {!loading
            ? sortedUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isSelected = selected.indexOf(row.id) !== -1;
                  return (
                    <GlobalDirectoryTableRow
                      key={row.id}
                      isSelected={isSelected}
                      contact={row}
                    />
                  );
                })
            : null}
          {isNotFound && (
            <NoResultsFoundRow
              colSpan={TABLE_HEAD.length + 1}
              filterName={filterName}
            />
          )}
          {!isNotFound && directory.length === 0 && !loading ? (
            <NoRows colSpan={TABLE_HEAD.length + 1} title={"contacts"} />
          ) : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <GlobalDirectoryTableRowSkeleton key={index} />
              ))
            : null}
        </TableBody>
      </Table>
    </>
  );
};
