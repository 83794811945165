// @mui
import { Order } from "@hooks/utils/useTable";
import {
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  Skeleton,
  Checkbox,
  TableRow,
} from "@mui/material";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";

interface ListTableHeadProps {
  rowCount: number;
  loading: boolean;
  headLabel: TableHeaderCell[];
  numSelected: number;
  showCheckbox?: boolean;
  order?: Order;
  orderBy?: number;
  onSelectAllClick: (event: any) => void;
  handleRequestSort?: (property: number) => void;
}

export default function ListTableHead({
  handleRequestSort,
  headLabel,
  loading,
  numSelected,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  showCheckbox = true,
}: ListTableHeadProps) {
  return (
    <TableHead sx={{ backgroundColor: "#000" }}>
      <TableRow>
        {showCheckbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        ) : null}
        {headLabel.map((headCell: TableHeaderCell, index) => {
          const content = loading ? (
            <Skeleton animation="wave" sx={{ magin: "auto" }}>
              <Typography sx={{ magin: "auto" }}>{headCell.label}</Typography>
            </Skeleton>
          ) : (
            headCell.label
          );
          const directionToggle = index === orderBy;
          const active = loading ? undefined : directionToggle;
          return (
            <TableCell
              key={headCell.id}
              align={"center"}
              sx={{
                textAlign: headCell.alignRight ? "" : "center !important",
              }}
            >
              {headCell.static ? (
                content
              ) : (
                <TableSortLabel
                  active={active}
                  direction={directionToggle ? order : "asc"}
                  onClick={
                    handleRequestSort
                      ? () => {
                          handleRequestSort(index);
                        }
                      : undefined
                  }
                >
                  {content}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
