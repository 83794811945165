import { getImageUrl } from "@hooks/utils/useUpload";
import { useEffect } from "react";

interface DocumentViewerModalProps {
  documentKey: string;
  setDocumentKey: (documentKey?: string) => void;
}

export const DocumentViewerModal = ({
  documentKey,
  setDocumentKey,
}: DocumentViewerModalProps) => {
  useEffect(() => {
    const fetchImage = async () => {
      if (documentKey) {
        const url = await getImageUrl(documentKey);

        // Fallback to regular window.open if iframe approach fails
        window.open(url, "_blank");
        setDocumentKey(undefined);
      }
    };
    fetchImage();
  }, [documentKey, setDocumentKey]);
  return null;
};
