import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { generateDocumentName } from "permit-one-common/src/utils/string";

interface ReadOnlyDocumentTableProps {
  project: ProjectLineItem;
  documentCount: number;
  conditionDocuments: ConditionDocumentLineItem[];
}

export const ReadOnlyDocumentTable = ({
  conditionDocuments,
  documentCount,
  project,
}: ReadOnlyDocumentTableProps) => {
  return (
    <Table aria-label="table" className="project-table popup-input">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "40%" }}>Title</TableCell>
          <TableCell sx={{ width: "40%" }}>Submitter</TableCell>
          <TableCell sx={{ width: "20%" }}>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {conditionDocuments.map((conditionDocument, index) => (
          <TableRow key={index}>
            <TableCell>
              {conditionDocument?.file?.name ||
                generateDocumentName(
                  project?.projectName || "",
                  conditionDocument.documentDiscipline,
                  conditionDocument.documentCategory,
                  documentCount
                )}
            </TableCell>
            <TableCell>{conditionDocument?.createdByName}</TableCell>
            <TableCell>
              {conditionDocument?.created.toLocaleDateString()}
            </TableCell>
          </TableRow>
        ))}
        {conditionDocuments.length === 0 ? (
          <NoRows colSpan={6} title={"files"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
