"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermitEvent = exports.decodePermitEvent = void 0;
var string_1 = require("../utils/string");
var decodePermitEvent = function (permitEvent) {
    var safePermitEvent = (0, string_1.reversePostgresSafe)(permitEvent);
    return {
        conditionId: safePermitEvent.condition_id,
        created: new Date(safePermitEvent.created),
        eventType: safePermitEvent.event_type,
        id: safePermitEvent.permit_event_id,
        info: safePermitEvent.info,
        name: safePermitEvent.name,
        permitId: safePermitEvent.permit_id,
        projectId: safePermitEvent.project_id,
    };
};
exports.decodePermitEvent = decodePermitEvent;
var encodePermitEvent = function (permitEvent) {
    var safePermitEvent = (0, string_1.makePostgresSafe)(permitEvent);
    return {
        condition_id: safePermitEvent.conditionId,
        created: safePermitEvent.created,
        event_type: safePermitEvent.eventType,
        info: safePermitEvent.info,
        name: safePermitEvent.name,
        permit_event_id: safePermitEvent.id,
        permit_id: safePermitEvent.permitId,
        project_id: safePermitEvent.projectId,
    };
};
exports.encodePermitEvent = encodePermitEvent;
