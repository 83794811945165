import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodeProject,
  encodeProject,
} from "permit-one-common/src/decoders/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import {
  Project,
  ProjectLineItem,
} from "permit-one-common/src/interfaces/project";
import * as React from "react";

export const useProject = (userProfile?: ProfileLineItem) => {
  const [selectedProject, setSelectedProject] =
    React.useState<ProjectLineItem>();
  const [projects, setProjects] = React.useState<ProjectLineItem[]>([]);
  const [isProjectLoading, setIsProjectLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getProject = async (id: string): Promise<void> => {
    try {
      setIsProjectLoading(true);
      const project = (await getApiData("getProject", "project", id))
        .data as Project;
      if (project) {
        const decodedProject = await decodeProject(project, getImageUrl);
        setSelectedProject(decodedProject);
      } else {
        setSelectedProject(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch project");
    } finally {
      setIsProjectLoading(false);
    }
  };

  const listProjects = async (profileId: string): Promise<void> => {
    try {
      setIsProjectLoading(true);

      const projectRes = await listApiData(
        "listProjects",
        "project",
        profileId
      );

      const decodedProjects = await Promise.all(
        projectRes.data.map((p) => decodeProject(p as Project, getImageUrl))
      );
      setProjects(decodedProjects);
    } catch (e: any) {
      setError("Could not list projects");
    } finally {
      setIsProjectLoading(false);
    }
  };

  const createProject = async (project: ProjectLineItem): Promise<void> => {
    try {
      setIsProjectLoading(true);
      (await postApiData("createProject", "project", encodeProject(project)))
        .data as Project;
      setProjects([project, ...projects]);
    } catch (e: any) {
      setError("Could not list projects");
    } finally {
      setIsProjectLoading(false);
    }
  };

  const updateProject = async (project: ProjectLineItem): Promise<void> => {
    try {
      setIsProjectLoading(true);
      (await postApiData("updateProject", "project", encodeProject(project)))
        .data as Project;
      setProjects(
        projects.map((c) => {
          if (c.id === project.id) {
            return project;
          }
          return c;
        })
      );
    } catch (e: any) {
      setError("Could not update project");
    } finally {
      setIsProjectLoading(false);
    }
  };

  const deleteProject = async (project: ProjectLineItem): Promise<void> => {
    try {
      setIsProjectLoading(true);
      (await postApiData("deleteProject", "project", encodeProject(project)))
        .data as Project;
      setProjects(projects.filter((p) => p.id !== project.id));
    } catch (e: any) {
      setError("Could not delete project");
    } finally {
      setIsProjectLoading(false);
    }
  };

  React.useEffect(() => {
    if (userProfile) {
      listProjects(userProfile.id);
    }
  }, [userProfile]);

  return {
    createProject,
    deleteProject,
    error,
    getProject,
    isProjectLoading,
    listProjects,
    projects,
    selectedProject,
    setSelectedProject,
    updateProject,
  };
};
