import DeleteIcon from "@mui/icons-material/Delete";
import {
  TableCell,
  TableRow,
  IconButton,
  Skeleton,
  Box,
  Typography,
  AvatarGroup,
} from "@mui/material";

export const ProjectCardSkeleton = () => {
  return (
    <TableRow style={{ padding: "10px 0 0" }}>
      <TableCell>
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <Typography>10</Typography>
        </Skeleton>
      </TableCell>
      <TableCell sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ textAlign: "center !important", width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          alignContent: "left",
          justifyContent: "left",
          textAlign: "left !important",
          width: "10%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <AvatarGroup max={4}>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="circular"
                animation="wave"
                width={23}
                height={23}
              />
            ))}
          </AvatarGroup>
        </Box>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <Typography>100</Typography>
        </Skeleton>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <Typography>100</Typography>
        </Skeleton>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <Typography>100</Typography>
        </Skeleton>
      </TableCell>
      <TableCell align="right" sx={{ width: "5%" }}>
        <Box display="flex" gap="5px" justifyContent={"right"}>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="large">
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
