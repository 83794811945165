import { useAuthContext } from "@hooks/context/useAuthContext";
import { ProfileProvider } from "@hooks/context/useProfileContext";
import {
  AppBar,
  Box,
  Button,
  Container,
  styled,
  useTheme,
} from "@mui/material";
import LayoutWrapper from "@stories/pages/Authenticate/AuthWrapper1";
import { ReactNode } from "react";

import LogoSection from "./MainLayout/LogoSection";


interface PermitLayoutProps {
  children: ReactNode;
}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  minHeight: "100%",
  overflow: "auto",
  paddingBottom: theme.spacing(10),
  paddingTop: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}));

export const NoProfileLayout = (props: PermitLayoutProps) => {
  const { signOut } = useAuthContext();

  const { children } = props;
  const theme = useTheme();
  const header = (
    <div className="Projectheader">
      <LogoSection />
      <Box sx={{ alignItems: "center", display: "flex", gap: "10px" }} />
      <div className="header-btn">
        <Button size="large" variant="outlined" onClick={signOut}>
          Logout
        </Button>
      </div>
    </div>
  );

  return (
    <LayoutWrapper>
      <ProfileProvider>
        <AppBar
          className="project_header_wrap"
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            boxShadow: "0 3px 10px #c0b5b517",
            padding: "20px 20px",
          }}
        >
          {header}
        </AppBar>
        <Main>
          <Container maxWidth="xl">{children}</Container>
        </Main>
      </ProfileProvider>
    </LayoutWrapper>
  );
};
