import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Chip, IconButton, TableCell, TableRow } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { permitTypeToString } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { getProfileName } from "permit-one-common/src/interfaces/profile";
import { useRef, useState } from "react";

interface TaskTableRowProps {
  selectedTask: boolean;
  condition: ConditionLineItem;
  handleViewCondition: (condition: ConditionLineItem) => void;
}
export const TaskTableRow = ({
  condition,
  handleViewCondition,
  selectedTask,
}: TaskTableRowProps) => {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRowClick = (condition: ConditionLineItem) => {
    handleViewCondition(condition);
  };

  const menuItems = [
    {
      action: () => {
        handleClose();
        handleViewCondition(condition);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
  ];

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selectedTask}>
        <TableCell
          align="center"
          sx={{ width: "2%" }}
          onClick={() => handleRowClick(condition)}
        >
          <Chip
            label={conditionStatusToString(
              condition.status,
              Boolean(condition.markedAsNotApplicable)
            )}
            sx={{
              backgroundColor: conditionStatusToColor(
                condition.status,
                Boolean(condition.markedAsNotApplicable)
              ),
              color: "black",
            }}
            variant="filled"
            className="chip"
          />
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.permitName}
        </TableCell>
        <TableCell
          sx={{
            width: "15%",
          }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {permitTypeToString(condition.permitType)}
        </TableCell>
        <TableCell
          sx={{
            width: "15%",
          }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.conditionDetails || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            width: "15%",
          }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          <div
            style={{
              padding: "5px !important",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.discipline || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.assignees.length > 0
            ? condition.assignees.map((a) => getProfileName(a)).join(", ")
            : conditionStatusToString(ConditionStatus.NotAssigned, false)}
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.managers.length > 0
            ? condition.managers.map((a) => getProfileName(a)).join(", ")
            : conditionStatusToString(ConditionStatus.NotAssigned, false)}
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }} ref={ref}>
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              handleOpen();
            }}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
    </>
  );
};
