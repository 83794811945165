import { Avatar, CardHeader, Grid, Skeleton, Typography } from "@mui/material";

interface SubTitleProps {
  title: string;
  loading?: boolean;
}

export const SubTitle = ({ loading = false, title }: SubTitleProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: "center",
        display: "flex",
        margin: "0",
        padding: "0 5px 5px",
      }}
    >
      <Grid item md={12} sm={12} sx={{ padding: "0" }}>
        {loading ? (
          <Skeleton animation={"wave"}>
            <Typography variant="h1">{title}</Typography>
          </Skeleton>
        ) : (
          <Typography variant="h3" style={{ fontSize: "25px" }}>
            {title}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface SubTitleWithImageProps {
  loading: boolean;
  url: string;
  title: string;
}

export const SubTitleWithImage = ({
  loading,
  title,
  url,
}: SubTitleWithImageProps) => {
  if (loading) {
    return <SubTitleWithImageSkeleton />;
  }
  return (
    <CardHeader
      sx={{ padding: "6px" }}
      titleTypographyProps={{
        color: "rgb(18, 25, 38)",
        fontSize: "20px !important",
        fontWeight: "600",
        lineheight: "24px",
        margin: "0",
      }}
      avatar={<Avatar src={url} alt={title.toUpperCase()} />}
      title={title}
    />
  );
};

export const SubTitleWithImageSkeleton = () => {
  return (
    <CardHeader
      sx={{ padding: "6px" }}
      titleTypographyProps={{
        color: "rgb(18, 25, 38)",
        fontSize: "20px !important",
        fontWeight: "600",
        lineheight: "24px",
        margin: "0",
      }}
      avatar={
        <Skeleton animation={"wave"} variant="circular">
          <Avatar src={""} alt={""} />
        </Skeleton>
      }
      title={
        <Skeleton animation={"wave"}>
          <Typography component={"h1"}>
            This is a long title Page Title
          </Typography>
        </Skeleton>
      }
    />
  );
};
