import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";

import { BulkDocumentForm } from "../BulkDocumentForm/BulkDocumentForm";

interface BulkDocumentModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile: ProfileLineItem;
  documentCount: number;
  createDocuments: (documents: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
}

export default function BulkDocumentModal({
  condition,
  createDocuments,
  documentCount,
  handleClose,
  open,
  permit,
  project,
  userProfile,
}: BulkDocumentModalProps) {
  const onSubmit = async (document: ConditionDocumentLineItem[]) => {
    await createDocuments(document);
    handleClose();
  };
  const title = "Add Documents";
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            "&:hover": {
              background: "#005cd1",
            },
            background: "#000",
            color: "#fff",
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <DialogContentText
          sx={{
            color: "black",
            fontSize: "14px",
            lineHeight: "16px",
            padding: "0 0 10px",
          }}
        >
          Select one or many files to upload to the document register
        </DialogContentText>
        <BulkDocumentForm
          project={project}
          permit={permit}
          condition={condition}
          userProfile={userProfile}
          handleSubmit={onSubmit}
          handleClose={handleClose}
          documentCount={documentCount}
        />
      </DialogContent>
    </Dialog>
  );
}
