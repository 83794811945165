import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";

interface ReadOnlyCommentTableProps {
  conditionComments: ConditionCommentLineItem[];
}

export const ReadOnlyCommentTable = ({
  conditionComments,
}: ReadOnlyCommentTableProps) => {
  return (
    <Table className="project-table popup-input">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "60%" }}>Comment</TableCell>
          <TableCell sx={{ width: "20%" }}>By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {conditionComments.map((conditionComment) => (
          <TableRow key={conditionComment.id}>
            <TableCell sx={{ width: "80%" }}>
              <Stack>
                <Typography>{`${conditionComment.firstName} ${conditionComment.lastName}`}</Typography>
                <Typography>{conditionComment.comment}</Typography>
              </Stack>
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              {conditionComment?.created?.toLocaleDateString()}
            </TableCell>
          </TableRow>
        ))}
        {conditionComments.length === 0 ? (
          <NoRows colSpan={3} title={"comments"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
