"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeConditionDocument = exports.decodeConditionDocument = void 0;
var condition_1 = require("./condition");
var conditionDocumentRevision_1 = require("./conditionDocumentRevision");
var string_1 = require("../utils/string");
var decodeConditionDocument = function (document, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var latestRevision, safeDocument, condition;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (document.revisions.length == 0) {
                    throw new Error("No reivisions for document");
                }
                latestRevision = document.revisions[0];
                safeDocument = (0, string_1.reversePostgresSafe)(document);
                return [4 /*yield*/, (0, condition_1.decodeCondition)(safeDocument.condition, getImageUrl)];
            case 1:
                condition = _a.sent();
                return [2 /*return*/, {
                        condition: condition,
                        conditionId: safeDocument.condition_id,
                        created: new Date(safeDocument.created),
                        createdBy: (latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.profile_id) || (latestRevision === null || latestRevision === void 0 ? void 0 : latestRevision.share_condition_id) || "",
                        createdByName: latestRevision.created_by_name,
                        documentCategory: safeDocument.document_category,
                        documentDiscipline: safeDocument.document_discipline,
                        documentNumber: safeDocument.document_number,
                        documentTitle: safeDocument.document_title,
                        fileSize: latestRevision.file_size,
                        fileType: latestRevision.file_type,
                        id: safeDocument.condition_document_id,
                        modified: new Date(latestRevision.created),
                        permitId: safeDocument.permit_id,
                        projectId: safeDocument.project_id,
                        revisionDate: new Date(latestRevision.revision_date),
                        revisionNumber: latestRevision.revision_number,
                        revisions: document.revisions.map(function (dr) {
                            return (0, conditionDocumentRevision_1.decodeConditionDocumentRevision)(dr);
                        }),
                        url: latestRevision.url,
                    }];
        }
    });
}); };
exports.decodeConditionDocument = decodeConditionDocument;
var encodeConditionDocument = function (document) {
    var safeDocument = (0, string_1.makePostgresSafe)(document);
    return {
        condition: (0, condition_1.encodeCondition)(safeDocument.condition),
        condition_document_id: safeDocument.id,
        condition_id: safeDocument.conditionId,
        created: safeDocument.created,
        document_category: safeDocument.documentCategory,
        document_discipline: safeDocument.documentDiscipline,
        document_number: safeDocument.documentNumber,
        document_title: safeDocument.documentTitle,
        permit_id: safeDocument.permitId,
        project_id: safeDocument.projectId,
        revisions: safeDocument.revisions.map(function (dr) {
            return (0, conditionDocumentRevision_1.encodeConditionDocumentRevision)(dr);
        }),
    };
};
exports.encodeConditionDocument = encodeConditionDocument;
