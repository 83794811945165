"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectAddressToString = exports.projectLineItemAddressToString = exports.createDefaultProject = exports.projectSectorToString = exports.ProjectSector = void 0;
var uuid_1 = require("uuid");
var ProjectSector;
(function (ProjectSector) {
    ProjectSector["Residential"] = "project-sector_residential";
    ProjectSector["Industrial"] = "project-sector_industrial";
    ProjectSector["Commercial"] = "project-sector_commercial";
    ProjectSector["Retail"] = "project-sector_retail";
    ProjectSector["Other"] = "project-sector_other";
})(ProjectSector || (exports.ProjectSector = ProjectSector = {}));
var projectSectorToString = function (projectSector) {
    switch (projectSector) {
        case ProjectSector.Residential:
            return "Residential";
        case ProjectSector.Industrial:
            return "Industrial";
        case ProjectSector.Commercial:
            return "Commercial";
        case ProjectSector.Retail:
            return "Retail";
        case ProjectSector.Other:
            return "Other";
    }
};
exports.projectSectorToString = projectSectorToString;
var createDefaultProject = function (organisation) {
    return {
        activePermits: 0,
        archived: false,
        archivedPermits: 0,
        collaborators: [],
        country: "AU",
        created: new Date(),
        developer: "",
        dpsMpsNumber: "",
        estimatedProgress: 0,
        estimatedProjectCost: 0,
        id: (0, uuid_1.v4)(),
        inReviewPermits: 0,
        lotNumber: "",
        organisationId: organisation.id,
        owner: organisation,
        postCode: "",
        projectCode: "",
        projectDescription: "",
        projectName: "",
        projectSector: ProjectSector.Residential,
        projectSectorOther: "",
        state: "",
        streetName: "",
        streetNumber: "",
        suburb: "",
        totalPermits: 0,
    };
};
exports.createDefaultProject = createDefaultProject;
var projectLineItemAddressToString = function (project) {
    return "".concat(project.streetNumber, " ").concat(project.streetName, ", ").concat(project.suburb, ", ").concat(project.state, ", ").concat(project.postCode);
};
exports.projectLineItemAddressToString = projectLineItemAddressToString;
var projectAddressToString = function (project) {
    return "".concat(project.street_number, " ").concat(project.street_name, ", ").concat(project.suburb, ", ").concat(project.state, ", ").concat(project.post_code);
};
exports.projectAddressToString = projectAddressToString;
