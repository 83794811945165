import { downloadFile } from "@hooks/utils/useUpload";
import { Skeleton, TableCell, TableRow } from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { useState } from "react";

import { DownloadButton } from "../DownloadButton/DownloadButton";

interface LinkRowProps {
  loading: boolean;
  permit: PermitLineItem;
  link: string;
}

export const LinkRow = ({ link, loading, permit }: LinkRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const onDownload = async (uri: string, downloadName: string) => {
    setDownloadProgress(0);
    setIsDownloading(true);
    await downloadFile(uri, downloadName, (progress: any) => {
      const total = (progress.loaded / progress.total) * 100;
      setDownloadProgress(total);
    });
    setIsDownloading(false);
  };

  return (
    <TableRow>
      <TableCell>Permit Attachment</TableCell>
      <TableCell>
        {loading ? (
          <Skeleton animation="wave" width={200} />
        ) : isDownloading ? (
          <CircularProgressWithLabel value={downloadProgress} />
        ) : (
          <DownloadButton
            disabled={isDownloading}
            onClick={() => {
              onDownload(link, getDocumentName(permit.permitName, link));
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
