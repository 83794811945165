import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePermit } from "@hooks/crud/usePermit";
import { Box, Grid } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from "@mui/material";
import { LinkRow } from "@stories/atoms/LinkRow/LinkRow";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import PermitModal from "@stories/organisms/PermitModal/PermitModal";
import parse from "html-react-parser";
import {
  PermitType,
  permitTypeToString,
  constructionTypearrayToString,
} from "permit-one-common/src/entities/permit";
import {
  ConditionStatus,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { getProfileName } from "permit-one-common/src/interfaces/profile";
import {
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { useState } from "react";

interface PermitPropertiesProps {
  loading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
}

export const PermitProperties = ({
  loading,
  permit,
  project,
}: PermitPropertiesProps): JSX.Element => {
  const { getProject, selectedProject } = useProjectContext();
  const { userOrganisation, userProfile } = useProfileContext();

  const { createPermit, permits, updatePermit } = usePermit(
    selectedProject?.id
  );

  const [selectedPermit, setSelectPermit] = useState<
    PermitLineItem | undefined
  >(undefined);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectPermit(undefined);
  };

  const handleOpen = (permitToEdit?: PermitLineItem) => {
    setOpen(true);
    setSelectPermit(permitToEdit);
  };

  const handleCreatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      createPermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleUpdatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      updatePermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  return (
    <>
      <Grid container sx={{ marginLeft: "auto", padding: "0" }}>
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            padding: "0",
          }}
        >
          {userOrganisation &&
          userOrganisation.id === selectedProject?.organisationId ? (
            <StyledButton
              loading={loading}
              className="css-kok4z1"
              variant="contained"
              onClick={() => handleOpen(selectedPermit || permit)}
            >
              Edit Permit Details
            </StyledButton>
          ) : null}

          {selectedProject && userOrganisation && userProfile && open ? (
            <PermitModal
              open={open}
              handleClose={handleClose}
              createPermit={handleCreatePermit}
              updatePermit={handleUpdatePermit}
              permitCount={permits.length}
              organisation={userOrganisation}
              profile={userProfile}
              project={selectedProject}
              existingPermit={selectedPermit}
            />
          ) : null}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={12} sx={{ padding: "20px 0px 0" }}>
          <TableContainer component={Card}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Permit Details</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>{project?.projectName || ""}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Project Type</TableCell>
                  <TableCell>
                    {project
                      ? project?.projectSector !== ProjectSector.Other
                        ? projectSectorToString(project?.projectSector)
                        : project.projectSectorOther
                      : ""}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Permit Name</TableCell>
                  <TableCell>{permit?.permitName || ""}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Permit Type</TableCell>
                  <TableCell>
                    {permit
                      ? permit?.permitType !== PermitType.Other
                        ? permitTypeToString(permit.permitType)
                        : permit.permitTypeOther
                      : ""}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Condition Details</TableCell>
                  <TableCell>
                    {permit?.permitDescription
                      ? `${parse(permit.permitDescription)}`
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Permit Manager</TableCell>
                  <TableCell>
                    {permit && permit.managers.length > 0
                      ? permit.managers.map((a) => getProfileName(a)).join(", ")
                      : conditionStatusToString(
                          ConditionStatus.NotAssigned,
                          false
                        )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Permit Approver</TableCell>
                  <TableCell>
                    {permit && permit.approvers.length > 0
                      ? permit.approvers
                          .map((a) => getProfileName(a))
                          .join(", ")
                      : conditionStatusToString(
                          ConditionStatus.NotAssigned,
                          false
                        )}
                  </TableCell>
                </TableRow>
                {permit?.uri ? (
                  <LinkRow
                    loading={loading}
                    permit={permit}
                    link={permit?.uri || ""}
                  />
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Card}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Building Work Detail</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Storeys Contained</TableCell>
                  <TableCell>{permit?.storeysContained || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rise in Storeys</TableCell>
                  <TableCell>{permit?.riseInStoreys || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type of Construction</TableCell>
                  <TableCell>
                    {permit
                      ? constructionTypearrayToString(permit.typeOfConstruction)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>BCA Version</TableCell>
                  <TableCell>{permit?.bcaVersion || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Stage details of work permitted</TableCell>
                  <TableCell>{permit?.stageDetailsOfWork || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Building classifications</TableCell>
                  <TableCell>{permit?.buildingClassification || ""}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>
                    Development Approval Details
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Development Approval number</TableCell>
                  <TableCell>
                    {permit?.developmentApprovalNumber || ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Council Authority</TableCell>
                  <TableCell>{permit?.councilAuthority || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Development Approval Date</TableCell>
                  <TableCell>
                    {permit?.developmentApprovalDate?.toString() || ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Development Approval Lapse Date</TableCell>
                  <TableCell>
                    {permit?.developmentApprovalLapseDate?.toString() || ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Address</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Street Number</TableCell>
                  <TableCell>{permit?.landStreetNumber || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Street Name</TableCell>
                  <TableCell>{permit?.landStreetName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Suburb</TableCell>
                  <TableCell>{permit?.landSuburb || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Post code</TableCell>
                  <TableCell>{permit?.landPostCode || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{permit?.landCountry || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{permit?.landState || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Lot Number</TableCell>
                  <TableCell>{permit?.landLotNumber || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DPS/MPS Number</TableCell>
                  <TableCell>{permit?.landDpsMpsNumber || ""}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Applicant</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Applicant Name</TableCell>
                  <TableCell>{permit?.applicantName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ABN</TableCell>
                  <TableCell>{permit?.abn || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Street Number</TableCell>
                  <TableCell>{permit?.applicantStreetNumber || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Street Name</TableCell>
                  <TableCell>{permit?.applicantStreetName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{permit?.applicantCountry || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{permit?.applicantState || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Suburb</TableCell>
                  <TableCell>{permit?.applicantSuburb || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Post Code</TableCell>
                  <TableCell>{permit?.applicantPostCode || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Name</TableCell>
                  <TableCell>
                    {permit?.applicantContactPersonName || ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Email</TableCell>
                  <TableCell>
                    {permit?.applicantContactPersonEmail || ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Phone</TableCell>
                  <TableCell>
                    {permit?.applicantContactPersonPhone || ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Owner</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>{permit?.ownerApplicantName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ABN</TableCell>
                  <TableCell>{permit?.ownerAbn || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Street Number</TableCell>
                  <TableCell>{permit?.ownerStreetNumber || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Street Name</TableCell>
                  <TableCell>{permit?.ownerStreetName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{permit?.ownerCountry || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{permit?.ownerState || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Suburb</TableCell>
                  <TableCell>{permit?.ownerSuburb || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Post Code</TableCell>
                  <TableCell>{permit?.ownerPostCode || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Name</TableCell>
                  <TableCell>{permit?.ownerContactPersonName || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Email</TableCell>
                  <TableCell>{permit?.ownerContactPersonEmail || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Phone</TableCell>
                  <TableCell>{permit?.ownerContactPersonPhone || ""}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          item
          md={12}
          className="permitDetails"
          sx={{ padding: "20px 0 0" }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={"33%"}>Additional Info</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Memorandum Note</TableCell>
                  <TableCell>{permit?.memorandumNote || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approvers</TableCell>
                  <TableCell>
                    {permit?.approvers
                      ?.map((a) => `${a.firstName} ${a.lastName}`)
                      .join(", ") || ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
