import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Box, Typography, Grid } from "@mui/material";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";

export const ProjectMail = (): JSX.Element => {
  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const loading = isProjectLoading || isAuthProfileLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0 0 30px",
          padding: "20px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            color: "#333333",
            fontSize: "28px !important",
            fontWeight: "700",
          }}
        >
          Project Correspondence
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          margin: "0",
          padding: "10px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Grid item md={12} sx={{ padding: "0 !important" }}>
          <MailCenter
            isLoading={loading}
            profile={userProfile}
            project={selectedProject}
            showDialog={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
