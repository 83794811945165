import { Box, List } from "@mui/material";
import TabButton from "@stories/molecules/TabButton/TabButton";

interface SettingsTabsProps {
  loading: boolean;
  value: number;
  handleChange: (newValue: number) => void;
}

export const SettingsTabs = ({
  handleChange,
  loading,
  value,
}: SettingsTabsProps): JSX.Element => {
  return (
    <Box>
      <List
        className="mailList"
        sx={{ display: "flex", maxWidth: "max-content" }}
      >
        <TabButton
          isLoading={loading}
          title={"Account Settings"}
          tabIndex={0}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Billing & Invoices"}
          tabIndex={1}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Notifications"}
          tabIndex={2}
          selectedTab={value}
          handleSelect={handleChange}
        />
      </List>
    </Box>
  );
};
