"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeConditionComment = exports.decodeConditionComment = void 0;
var decodeConditionComment = function (conditionComment) {
    return {
        comment: conditionComment.comment,
        conditionId: conditionComment.condition_id,
        created: new Date(conditionComment.created),
        firstName: conditionComment.first_name,
        id: conditionComment.condition_comment_id,
        lastName: conditionComment.last_name,
        permitId: conditionComment.permit_id,
        profileId: conditionComment.profile_id,
        projectId: conditionComment.project_id,
        shareConditionId: conditionComment.share_condition_id,
    };
};
exports.decodeConditionComment = decodeConditionComment;
var encodeConditionComment = function (conditionComment) {
    return {
        comment: conditionComment.comment,
        condition_comment_id: conditionComment.id,
        condition_id: conditionComment.conditionId,
        created: conditionComment.created,
        first_name: conditionComment.firstName,
        last_name: conditionComment.lastName,
        permit_id: conditionComment.permitId,
        profile_id: conditionComment.profileId,
        project_id: conditionComment.projectId,
        share_condition_id: conditionComment.shareConditionId,
    };
};
exports.encodeConditionComment = encodeConditionComment;
