// ----------------------------------------------------------------------

import { Theme } from "@mui/material";

export default function Paper(_: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  };
}
