import {
  Box,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MainCard from "@stories/ui-component/cards/MainCard";

// styles
const CardWrapper = styled(MainCard)(() => ({
  overflow: "hidden",
  position: "relative",
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

interface DashboardCardProps {
  loading: boolean;
  title: string;
  subTitle: string;
  desc: string;
  handleClick?: () => void;
}

const DashboardCard = ({
  desc,
  handleClick,
  loading,
  subTitle,
  title,
}: DashboardCardProps) => {
  return (
    <CardWrapper
      border={false}
      content={false}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid rgb(208, 215, 222)",
        borderRadius: "10px",
        boxShadow: "none",
        flex: 1,
        flexGrow: 1,
        width: "100%",
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          cursor: "pointer",
          p: "15px",
          width: "100%",
        }}
        className="cards"
      >
        <List sx={{ py: 0 }}>
          <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
            <ListItemText
              sx={{
                mt: 0.45,
                py: "20",
              }}
              secondary={
                loading ? (
                  <>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Skeleton animation="wave" variant="circular" width={20}>
                        <span
                          className="tasks-status-color"
                          style={{
                            borderRadius: "50%",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Skeleton>
                      <Skeleton animation="wave">
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#000 !important",
                            fontFamily: `'Roboto', sans-serif`,
                            fontSize: "17px !important",
                            fontWeight: "600 !important",
                          }}
                        >
                          {"Title"} ({"Loading Subtitle"})
                        </Typography>
                      </Skeleton>
                    </Box>
                    <Skeleton
                      animation="wave"
                      sx={{ margin: "0px", width: "100%" }}
                    />
                    <Skeleton
                      animation="wave"
                      sx={{ margin: "0px", width: "100%" }}
                    />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <span
                        className="tasks-status-color"
                        style={{
                          borderRadius: "50%",
                          borderStyle: "solid",
                          borderWidth: "2px",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#000 !important",
                          fontFamily: `'Roboto', sans-serif`,
                          fontSize: "17px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {title} ({subTitle})
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "rgb(101, 109, 118)!important",
                        fontFamily: `'Roboto', sans-serif`,
                        fontSize: "13px !important",
                        fontWeight: "300 !important",
                        lineHeight: "16px",
                        marginTop: "12px",
                      }}
                    >
                      {desc}
                    </Typography>
                  </>
                )
              }
            />
          </ListItem>
        </List>
      </Box>
    </CardWrapper>
  );
};

export default DashboardCard;
