import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Chip,
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
export const PermitTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell align="center" sx={{ width: "5%" }}>
          <Skeleton animation="wave" variant="rounded">
            <Chip variant="outlined" label="Status" />
          </Skeleton>
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          <Skeleton animation="wave" sx={{ margin: "auto" }}>
            <Typography>100</Typography>
          </Skeleton>
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }}>
          <Box display="flex" gap="5px" justifyContent={"right"}>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
