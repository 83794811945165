import useConfig from "@hooks/utils/useConfig";
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Transitions from "@stories/ui-component/extended/Transitions";
import {
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons";
import LAYOUT_CONST from "constant";
import { useState } from "react";
import { NavItemType } from "types";

import NavItem from "../NavItem";

// mini-menu - wrapper
const PopperStyledMini = styled(Popper)(({ theme }) => ({
  "&:before": {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    content: '""',
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 120,
  },
  minWidth: 180,
  overflow: "visible",
  zIndex: 1202,
}));

// horizontal-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  "&:before": {
    borderColor: `transparent transparent ${theme.palette.background.paper}  ${theme.palette.background.paper}`,
    borderStyle: "solid",
    borderWidth: "6px",
    content: '""',
    display: "block",
    height: 12,
    left: -5,
    position: "absolute",
    top: 34,
    transform: "translateY(-50%) rotate(45deg)",
    width: 12,
    zIndex: 120,
  },
  '&[data-popper-placement="left-end"]:before': {
    borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`,
    bottom: 15,
    left: "auto",
    right: -5,
    top: "auto",
  },
  '&[data-popper-placement="left-start"]:before': {
    borderColor: `${theme.palette.background.paper}  ${theme.palette.background.paper} transparent transparent`,
    left: "auto",
    right: -5,
  },
  '&[data-popper-placement="right-end"]:before': {
    bottom: 15,
    top: "auto",
  },
  minWidth: 180,
  overflow: "visible",
  zIndex: 1202,
}));

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

interface NavCollapseProps {
  loading: boolean;
  menu: NavItemType;
  level: number;
  parentId: string;
  isDrawOpen: boolean;
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
  selectedItem: string[];
}

const NavCollapse = ({
  isDrawOpen,
  level,
  loading,
  menu,
  parentId,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: NavCollapseProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const { borderRadius, layout } = useConfig();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
  >(null);

  const handleClickMini = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    setAnchorEl(null);
    if (isDrawOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleHover = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClosePopper = () => {
    setOpen(false);
    setSelected(null);
    setAnchorEl(null);
  };

  const openMini = Boolean(anchorEl);

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse
            loading={loading}
            key={item.id}
            menu={item}
            level={level + 1}
            parentId={parentId}
            selectedItem={selectedItem}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            setSelectedItem={setSelectedItem}
          />
        );
      case "item":
        return (
          <NavItem
            loading={loading}
            key={item.id}
            item={item}
            level={level + 1}
            selectedItem={selectedItem}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            setSelectedItem={setSelectedItem}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const isSelected = selected === menu.id;

  const menuIcon = menu.icon;

  const collapseIcon = isDrawOpen ? (
    <IconChevronUp
      stroke={1.5}
      size="16px"
      style={{ marginBottom: "auto", marginTop: "auto" }}
    />
  ) : (
    <IconChevronRight
      stroke={1.5}
      size="16px"
      style={{ marginBottom: "auto", marginTop: "auto" }}
    />
  );

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" && isDrawOpen
      ? "text.primary"
      : "secondary.main";

  const popperId = openMini ? `collapse-pop-${menu.id}` : undefined;

  return (
    <>
      {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
      (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <>
          <ListItemButton
            sx={{
              borderRadius: `${borderRadius}px`,
              mb: 0.5,
              pl: isDrawOpen ? `${level * 24}px` : 1.25,
              zIndex: 1201,
              ...(isDrawOpen &&
                level === 1 &&
                theme.palette.mode !== "dark" && {
                  "&.Mui-selected": {
                    "&:hover": {
                      background: theme.palette.secondary.light,
                      color: iconSelectedColor,
                    },
                    background: theme.palette.secondary.light,
                    color: iconSelectedColor,
                  },
                  "&:hover": {
                    background: theme.palette.secondary.light,
                  },
                }),
              ...((!isDrawOpen || level !== 1) && {
                "&.Mui-selected": {
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                  bgcolor: "transparent",
                },
                "&:hover": {
                  bgcolor: "transparent",
                },
                py: level === 1 ? 0 : 1,
              }),
            }}
            selected={isSelected}
            {...(!isDrawOpen && {
              onMouseEnter: handleClickMini,
              onMouseLeave: handleClosePopper,
            })}
            onClick={handleClickMini}
          >
            {menuIcon && (
              <ListItemIcon
                sx={{
                  color: isSelected ? iconSelectedColor : textColor,
                  minWidth: level === 1 ? 36 : 18,
                  ...(!isDrawOpen &&
                    level === 1 && {
                      "&:hover": {
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? theme.palette.secondary.main + 25
                            : "secondary.light",
                      },
                      alignItems: "center",
                      borderRadius: `${borderRadius}px`,
                      height: 46,
                      justifyContent: "center",
                      width: 46,
                      ...(isSelected && {
                        "&:hover": {
                          bgcolor:
                            theme.palette.mode === "dark"
                              ? theme.palette.secondary.main + 30
                              : "secondary.light",
                        },
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? theme.palette.secondary.main + 25
                            : "secondary.light",
                      }),
                    }),
                }}
              >
                {menuIcon}
              </ListItemIcon>
            )}
            {(isDrawOpen || (!isDrawOpen && level !== 1)) && (
              <ListItemText
                primary={
                  <Typography
                    variant={isSelected ? "h5" : "body1"}
                    color="inherit"
                    sx={{ my: "auto" }}
                  >
                    {menu.title}
                  </Typography>
                }
                secondary={
                  menu.caption && (
                    <Typography
                      variant="caption"
                      sx={{ ...theme.typography.subMenuCaption }}
                      display="block"
                      gutterBottom
                    >
                      {menu.caption}
                    </Typography>
                  )
                }
              />
            )}

            {openMini || open ? (
              collapseIcon
            ) : (
              <IconChevronDown
                stroke={1.5}
                size="16px"
                style={{ marginBottom: "auto", marginTop: "auto" }}
              />
            )}

            {!isDrawOpen && (
              <PopperStyledMini
                open={openMini}
                anchorEl={anchorEl}
                placement="right-start"
                style={{
                  zIndex: 2001,
                }}
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [-12, 0],
                    },
                  },
                ]}
              >
                {({ TransitionProps }) => (
                  <Transitions in={openMini} {...TransitionProps}>
                    <Paper
                      sx={{
                        backgroundImage: "none",
                        boxShadow: theme.shadows[8],
                        mt: 1.5,
                        overflow: "hidden",
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClosePopper}>
                        <Box>{menus}</Box>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyledMini>
            )}
          </ListItemButton>
          {isDrawOpen && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {open && (
                <List
                  component="div"
                  disablePadding
                  sx={{
                    "&:after": {
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.light
                          : theme.palette.primary.light,
                      content: "''",
                      height: "100%",
                      left: "32px",
                      opacity: theme.palette.mode === "dark" ? 0.2 : 1,
                      position: "absolute",
                      top: 0,
                      width: "1px",
                    },
                    position: "relative",
                  }}
                >
                  {menus}
                </List>
              )}
            </Collapse>
          )}
        </>
      ) : null}

      {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? (
        <>
          <ListItemButton
            id={`boundary-${popperId}`}
            disableRipple
            selected={isSelected}
            onMouseEnter={handleHover}
            onMouseLeave={handleClosePopper}
            onClick={handleHover}
            aria-describedby={popperId}
          >
            {menuIcon && (
              <ListItemIcon sx={{ minWidth: !menu.icon ? 18 : 36, my: "auto" }}>
                {menuIcon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? "h5" : "body1"}
                  color="inherit"
                  sx={{ my: "auto" }}
                >
                  {menu.title}
                </Typography>
              }
            />
            {openMini ? (
              <IconChevronRight stroke={1.5} size="16px" />
            ) : (
              <IconChevronDown stroke={1.5} size="16px" />
            )}

            {anchorEl && (
              <PopperStyled
                id={popperId}
                open={openMini}
                anchorEl={anchorEl}
                placement="right-start"
                style={{
                  zIndex: 2001,
                }}
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [-10, 0],
                    },
                  },
                ]}
              >
                {({ TransitionProps }) => (
                  <Transitions in={openMini} {...TransitionProps}>
                    <Paper
                      sx={{
                        backgroundImage: "none",
                        boxShadow: theme.shadows[8],
                        mt: 1.5,
                        overflow: "hidden",
                        py: 0.5,
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClosePopper}>
                        <Box>{menus}</Box>
                      </ClickAwayListener>
                    </Paper>
                  </Transitions>
                )}
              </PopperStyled>
            )}
          </ListItemButton>
        </>
      ) : null}
    </>
  );
};

export default NavCollapse;
