import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Skeleton,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
} from "@mui/material";

interface StepsProps {
  activeStep: number;
  steps: string[];
  disabledSteps?: number[];
}

export const LoadingSteps = ({ steps }: StepsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => (
                <Skeleton variant="circular" width={24} height={24} />
              )}
             />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export const Steps = ({
  activeStep,
  disabledSteps = [],
  steps,
}: StepsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          const isDisabled = disabledSteps.includes(steps.indexOf(label));
          return (
            <Step key={label}>
              <StepLabel
                icon={
                  isDisabled ? (
                    <StepIcon icon={<CancelIcon sx={{ color: "#C5C5C5" }} />} />
                  ) : undefined
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
