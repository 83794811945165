import RecentActorsIcon from "@mui/icons-material/RecentActors";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { NavItemType } from "types";

export const getDirectoryNav = (projectId?: string): NavItemType => {
  const theme = useTheme();
  return {
    children: [
      {
        icon: (
          <RecentActorsIcon style={{ color: theme.palette.primary.main }} />
        ),
        id: "directory",
        title: "Project Contacts",
        type: "item",
        url: projectId ? RouteHelper.directory(projectId) : "",
      },
      // {
      //   icon: (
      //     <TravelExploreIcon style={{ color: theme.palette.primary.main }} />
      //   ),
      //   id: "global-directory",
      //   title: "Global Network",
      //   type: "item",
      //   url: projectId ? RouteHelper.projectGlobalDirectory(projectId) : "",
      // },
    ],
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "directory",
    title: "Directory",
    type: "group",
  };
};
