import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aos from "aos";
import Search from "assets/images/approve.png";
import Report from "assets/images/assist.png";
import ManageReview from "assets/images/consolidate.png";
import FooterLogo from "assets/images/foot-logo.png";
import instgram from "assets/images/instgram-logo.webp";
import linkedin from "assets/images/linkedin-logo.webp";
import LogoActive from "assets/images/LogoActive.png";
import Monitor from "assets/images/manage.png";
import hero from "assets/images/permit management-min.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import "./style.css";

export const ReportingAndAnalytics = () => {
  const [featureTabs, setfeatureTabs] = useState("coordinates");
  const [IsSticky, setIsSticky] = useState(false);
  const [IsActive, setIsActive] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [menus, setMenus] = useState([
    {
      isOpen: false,
      items: [
        { label: "permit management", url: "/permit-management" },
        { label: "Communication portal", url: "/communication-portal" },
        { label: "document Control", url: "/document-control" },
        { label: "Reporting & Analytics", url: "/reporting-and-analytics" },
      ],
      label: "Solutions",
    },
    {
      isOpen: false,
      items: [
        { label: "Templates", url: "/templates" },
        { label: "News & Articles", url: "/blog" },
        { label: "API Integration", url: "/api-integration" },
      ],
      label: "Resources",
    },
    {
      isOpen: false,
      items: [
        { label: "Our Team", url: "/our-team" },
        { label: "Support", url: "/support" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Terms & Conditions ", url: "/terms-conditions" },
      ],
      label: "Company",
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[menuIndex].isOpen = !updatedMenus[menuIndex].isOpen;
      return updatedMenus;
    });
  };

  return (
    <>
      {/* Hero Section  */}
      <header
        className={IsSticky === true ? "active sub-header" : "sub-header"}
      >
        <div className="container-new">
          <div className="navigation">
            <a href="/" className="site-logo">
              <img src={LogoActive} alt="socialpro" />
              {/* {IsSticky === true ? (
                <img src={Logo} alt="socialpro" />
              ) : (
                <img src={LogoActive} alt="socialpro" />
              )} */}
            </a>
            <ul>
              <li>
                <span>Solutions</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/permit-management">permit management</Link>
                  </li>
                  <li>
                    <Link to="/communication-portal">Communication portal</Link>
                  </li>
                  <li>
                    <Link to="/document-control">document Control</Link>
                  </li>
                  <li>
                    <Link to="/reporting-and-analytics">
                      Reporting & Analytics
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Resources</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/templates">templates</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Articles</Link>
                  </li>
                  <li>
                    <Link to="/api-integration">API Integration</Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Company</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="head-btns">
              <a href="/request-demo" className="demo-btn">
                Request a Demo
              </a>
              <a href="/login" className="nav-login sub-head">
                <svg
                  data-bbox="0 0 50 50"
                  data-type="shape"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                  fill="#fff"
                >
                  <g>
                    <path
                      d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                      fill="#005CD1"
                    />
                  </g>
                </svg>
                <span>Login</span>
              </a>
            </div>

            {IsActive === false ? (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {/* Mob Navigation  */}

        <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
          <div className="mob-nav">
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo" className="demo-link">
                  Request a Demo
                </a>
                <a href="/login" className="nav-login">
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                        fill="#005CD1"
                      />
                    </g>
                  </svg>
                  <span>Login</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* Hero Section  */}
      <div className=" zig-hero-wrap bg-overlay ">
        <div className="container-new">
          <div className="zig-hero-content">
            <h4>AUTOMATION</h4>
            <h3 data-aos="fade-in" data-aos-duration="1000">
              Approvals made easy.
            </h3>
            <p>
              PermitOne standardises and improves the permit management process,
              by removing the administrative tasks from project teams – saving
              time and money.
            </p>
            <a href="/request-demo" className="blue-btn">
              Request a demo
            </a>
          </div>
          <div className="zig-hero-img">
            <img src={hero} alt="leadingSocials" />
          </div>
        </div>
      </div>

      {/* Features Section  */}
      <div className=" bg-white">
        <div className="container-new feature-tab-section" id="tabsWrapper">
          <h4 className="feature-title">PERMIT MANAGER</h4>
          <h3 className="feature-tagline">
            Create, manage & approve in one place.
          </h3>
          <p className="feature-txt-new">
            There&#39;s no more double handling - use PermitOne to streamline
            the permit approval process with your team. Standardising and
            simplying permits on an easy-to-use portal; removing the need to
            work across multiple traditional and outdated programs.
          </p>

          <div className="featrure-tabs-buttons">
            <button
              onClick={() => setfeatureTabs("coordinates")}
              className={featureTabs === "coordinates" ? "active-tab" : ""}
            >
              Consolidate
            </button>
            <button
              onClick={() => setfeatureTabs("monitor")}
              className={featureTabs === "monitor" ? "active-tab" : ""}
            >
              Manage
            </button>
            <button
              onClick={() => setfeatureTabs("report")}
              className={featureTabs === "report" ? "active-tab" : ""}
            >
              Assist
            </button>
            <button
              onClick={() => setfeatureTabs("search")}
              className={featureTabs === "search" ? "active-tab" : ""}
            >
              Approve
            </button>
          </div>

          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="inner-feature-container"
          >
            <div className="feature-inner-wrapper">
              {/* Co-oridnate  */}
              {featureTabs === "coordinates" && (
                <div className="cooridnate-social">
                  <div className="cooridnate-social-content">
                    <h4>CONSOLIDATE</h4>
                    <h3>
                      One checklist to rule <br />
                      them all.
                    </h3>
                    <p>
                      Create a single Permit Checklist that can be queried,
                      updated, shared, actioned, and approved with project
                      teams, easily controlled on our PermitOne portal.
                    </p>
                    <a href="/request-demo" className="request">
                      Request a demo
                    </a>
                  </div>
                  <div className="cooridnate-img">
                    <img src={ManageReview} alt="ManageReview" />
                  </div>
                </div>
              )}
              {/* Monitor  */}
              {featureTabs === "monitor" && (
                <div className="cooridnate-social">
                  <div className="cooridnate-social-content">
                    <h4>MANAGE</h4>
                    <h3>
                      Automate permit <br /> management.
                    </h3>
                    <p>
                      Take the time and costs out of administrative permit
                      management tasks, by delegating and actioning permit
                      requirements with project teams in a smart permit
                      checklist.{" "}
                    </p>
                    <a href="/request-demo" className="request">
                      Request a demo
                    </a>
                  </div>
                  <div className="cooridnate-img">
                    <img src={Monitor} alt="Monitor" />
                  </div>
                </div>
              )}
              {/* REPORT  */}
              {featureTabs === "report" && (
                <div className="cooridnate-social">
                  <div className="cooridnate-social-content">
                    <h4>ASSIST</h4>
                    <h3>Communicate & Action.</h3>
                    <p>
                      Simplify permit communications with professional
                      consultants and contractors in one place. Enable teams to
                      brief, query and action permit information efficiently,
                      resulting in a faster approval process.{" "}
                    </p>
                    <a href="/request-demo" className="request">
                      Request a demo
                    </a>
                  </div>
                  <div className="cooridnate-img">
                    <img src={Report} alt="Report" />
                  </div>
                </div>
              )}
              {/* SEARCH  */}
              {featureTabs === "search" && (
                <div className="cooridnate-social">
                  <div className="cooridnate-social-content">
                    <h4>APPROVE </h4>
                    <h3>Approvals made easy.</h3>
                    <p>
                      PermitOne standardises and improves the Permit management
                      process, by removing the administrative tasks from project
                      teams – saving time and money.
                    </p>
                    <a href="/request-demo" className="request">
                      Request a demo
                    </a>
                  </div>
                  <div className="cooridnate-img">
                    <img src={Search} alt="Search" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <footer className="darkblue-bg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social mob-hide">
              <ul className="social-links">
                <li>
                  <a
                    href=" https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
          <div className="footer-links">
            <h4>SOLUTION</h4>
            <ul>
              <li>
                <Link to="/permit-management">permit management</Link>
              </li>
              <li>
                <Link to="/communication-portal">Communication portal</Link>
              </li>
              <li>
                <Link to="/document-control">document Control</Link>
              </li>
              <li>
                <Link to="/reporting-and-analytics">Reporting & Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>RESOURCES</h4>
            <ul>
              <li>
                <Link to="/request-demo">Request a demo</Link>
              </li>
              <li>
                <Link to="/templates">TEMPLATES</Link>
              </li>
              <li>
                <Link to="/blog">News & Articles</Link>
              </li>
              <li>
                <Link to="/api-integration">API Integration</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
            <div className="footer-social desk-hide">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/permit.one/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
