import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitEventLineItem,
  permitEventTypeToString,
} from "permit-one-common/src/interfaces/permitEvent";

import {
  permitEventTypeToIcon,
  PermitTimelineSkeleton,
} from "./PermitEventLog";

interface PermitEventLogProps {
  loading: boolean;
  permit?: PermitLineItem;
  permitEvents: PermitEventLineItem[];
}

export const PublicPermitEventLog = ({
  loading,
  permitEvents,
}: PermitEventLogProps): JSX.Element => {
  const isLoading = loading;
  return (
    <>
      {isLoading ? <PermitTimelineSkeleton /> : null}
      {!isLoading ? (
        <Timeline position="alternate">
          {permitEvents.map((event, index) => (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {event.created.toLocaleDateString()}{" "}
                  {event.created.toLocaleTimeString()}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {permitEventTypeToIcon(event.eventType)}
                {index < permitEvents.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  {permitEventTypeToString(event.eventType)}
                </Typography>
                <Typography>{event.info}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
          {permitEvents.length == 0 ? (
            <Typography
              sx={{ color: "black", fontSize: "16px", fontWeight: "500" }}
            >
              {"No permit events"}
            </Typography>
          ) : null}
        </Timeline>
      ) : null}
    </>
  );
};
