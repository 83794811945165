import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  Avatar,
  AvatarGroup,
  TableCell,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  Zoom,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import {
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { useState, useRef } from "react";

interface ProjectCardProps {
  project: ProjectLineItem;
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  handleViewProject: (project: ProjectLineItem) => void;
  handleEditProject: (project: ProjectLineItem) => void;
  handleArchiveProject: (project: ProjectLineItem) => void;
}

export const ProjectCard = ({
  handleArchiveProject,
  handleEditProject,
  handleViewProject,
  organisation,
  profile,
  project,
}: ProjectCardProps) => {
  const ref = useRef<Element>();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const canEdit =
    project.organisationId === organisation?.id && profile && profile.isAdmin;

  const menuItems = [
    {
      action: () => {
        handleClose();
        handleViewProject(project);
      },
      color: "black",
      icon: <RemoveRedEyeIcon />,
      name: "View",
    },
  ];
  if (canEdit) {
    menuItems.push({
      action: () => {
        handleClose();
        handleEditProject(project);
      },
      color: "black",
      icon: <EditIcon />,
      name: "Edit",
    });

    if (project.archived) {
      menuItems.push({
        action: () => {
          handleClose();
          setOpenConfirmDialog(true);
        },
        color: "green",
        icon: <RestoreIcon />,
        name: "Restore",
      });
    } else {
      menuItems.push({
        action: () => {
          handleClose();
          setOpenConfirmDialog(true);
        },
        color: "red",
        icon: <DeleteIcon />,
        name: "Archive",
      });
    }
  }
  return (
    <>
      <TableRow style={{ padding: "10px 0 0" }} hover>
        <TableCell onClick={() => handleViewProject(project)}>
          <span
            className={project.archived ? "archivedIcon" : "checkIcon"}
            style={{
              background: project.archived ? "red" : "#1ff300",
              borderRadius: "50%",
              boxShadow: "none",
              display: "block",
              height: "12px",
              margin: "0 auto",
              width: "12px",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "20%" }}
        >
          {project.projectName}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%" }}
        >
          {project.projectSector !== ProjectSector.Other
            ? projectSectorToString(project.projectSector)
            : project.projectSectorOther}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{
            alignContent: "left",
            justifyContent: "left",
            textAlign: "left !important",
            width: "10%",
          }}
        >
          {project.collaborators.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <AvatarGroup
                max={5}
                sx={{
                  "& .MuiAvatar-root": {
                    fontSize: "10px",
                    height: 23,
                    width: 23,
                  },
                }}
              >
                {project.collaborators.map((collaborator, index) => {
                  const initials =
                    `${collaborator.firstName[0]}${collaborator.lastName[0]}`.toUpperCase();
                  const fullName = `${collaborator.firstName} ${collaborator.lastName}`;

                  return (
                    <Tooltip
                      key={index}
                      title={fullName}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                    >
                      <Avatar alt={initials} src={collaborator.photoUrl}>
                        {(!collaborator.photoUrl ||
                          !collaborator.photoUrl.length) &&
                          initials}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </AvatarGroup>
            </Box>
          ) : (
            "No Collaborators"
          )}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {project.totalPermits}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {project.activePermits}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {project.archivedPermits}
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }} ref={ref}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                handleClick();
              }}
            >
              <Iconify icon={"eva:more-vertical-fill"} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
      <ConfirmationDialog
        message={
          !project.archived
            ? "Are you sure you want to archive this project?"
            : "Are you sure you want to restore this project?"
        }
        open={openConfirmDialog}
        title={!project.archived ? "Archive Project" : "Restore Project"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleArchiveProject(project)}
      />
    </>
  );
};
