import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import { FileRow } from "./FileRow";
import { FileRowSkeleton } from "./FileRowSkeleton";

interface SelectedDocumentsProps {
  loading: boolean;
  disabled: boolean;
  project: ProjectLineItem;
  documentCount: number;
  conditionDocuments: ConditionDocumentLineItem[];
  canEdit: boolean;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  removeFile: (documents: ConditionDocumentLineItem) => void;
}

export const SelectedDocumentsRow = ({
  canEdit,
  conditionDocuments,
  disabled,
  documentCount,
  loading,
  project,
  removeFile,
  updateDocuments,
}: SelectedDocumentsProps) => {
  const [documentKey, setDocumentKey] = useState<string>();

  const handleView = (uri: string) => {
    setDocumentKey(uri);
  };
  return (
    <>
      <Table aria-label="table" className="project-table popup-input">
        <TableHead>
          <TableRow>
            <TableHeader loading={loading} title={"Local Name"} width="25%" />
            <TableHeader loading={loading} title={"Title"} width="20%" />
            <TableHeader loading={loading} title={"Discipline"} width="20%" />
            <TableHeader loading={loading} title={"Category"} width="20%" />
            <TableHeader loading={loading} width="5%" title={""} />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <FileRowSkeleton key={index} />
              ))
            : null}
          {!loading &&
            conditionDocuments.map((conditionDocument, index) => (
              <FileRow
                canEdit={canEdit}
                key={conditionDocument.id}
                project={project}
                documentCount={documentCount}
                conditionDocument={conditionDocument}
                updateDocuments={updateDocuments}
                viewDocument={handleView}
                index={index}
                disabled={disabled}
                removeFile={removeFile}
              />
            ))}
          {!loading && conditionDocuments.length === 0 ? (
            <NoRows colSpan={6} title={"documents"} />
          ) : null}
        </TableBody>
      </Table>
      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </>
  );
};
