import { useAllFiles } from "@hooks/crud/useAllFiles";
import { useExcel } from "@hooks/crud/useExcel";
import { usePdf } from "@hooks/crud/usePdf";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Grid, Box } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import React, { ChangeEvent, useState } from "react";

import ConditionExport from "../ConditionExport/ConditionExport";
import ConditionImport from "../ConditionImport/ConditionImport";
import ShareCondition from "../ShareCondition/ShareCondition";

interface ConditionTableToolbarProps {
  loading: boolean;
  disabled: boolean;
  filterName: string;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  altControl?: React.ReactNode;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleOpenConditionEditModal: () => void;
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleShareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accessLevel: ShareConditionAccessLevel
  ) => Promise<void>;
}

export default function ConditionTableToolbar({
  conditions,
  disabled,
  filterName,
  handleImportConditons,
  handleOpenConditionEditModal,
  handleShareConditions,
  loading,
  onFilter,
  permit,
  profile,
  project,
  userOrganisation,
}: ConditionTableToolbarProps) {
  const { PDFDownloadProgress, downloadStepPDF, getPdfReport } = usePdf();
  const { downloadStepExcel, excelDownloadProgress, getExcelReport } =
    useExcel();
  const { allFilesDownloadProgress, downloadStepAllFiles, getFullReportInfo } =
    useAllFiles();
  const [importOpen, setImportOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);
  const isPermitApprover =
    profile &&
    permit &&
    permit.approvers.map((pa) => pa.id).includes(profile.id);

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0",
          padding: "0",
          width: "100%",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
          }}
        >
          <Grid item md={3} sx={{ padding: "0" }}>
            <StyledTextField
              className="search"
              loading={loading}
              disabled={disabled || loading}
              type="text"
              placeholder="Search Conditions..."
              name="filterProject"
              value={filterName}
              onChange={onFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            md={8}
            sx={{
              alignItems: "center",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              padding: "0",
            }}
          >
            {isProjectOwner || isPermitManager || isPermitApprover ? (
              <StyledButton
                loading={loading}
                onClick={() => {
                  setShareOpen(true);
                }}
                variant="outlined"
                disabled={loading}
                startIcon={<Iconify icon="foundation:share" />}
              >
                {"Share Checklist"}
              </StyledButton>
            ) : undefined}
            {isProjectOwner || isPermitManager || isPermitApprover ? (
              <StyledButton
                loading={loading}
                onClick={() => {
                  setExportOpen(true);
                }}
                variant="outlined"
                disabled={loading}
                startIcon={<Iconify icon="foundation:page-export-csv" />}
              >
                {"Download Checklist"}
              </StyledButton>
            ) : undefined}
            {isProjectOwner || isPermitManager ? (
              <>
                <StyledButton
                  loading={loading}
                  onClick={() => {
                    setImportOpen(true);
                  }}
                  variant="contained"
                  disabled={loading}
                  startIcon={<Iconify icon="eva:cloud-upload-outline" />}
                >
                  {"Import Checklist"}
                </StyledButton>

                <StyledButton
                  loading={loading}
                  onClick={() => handleOpenConditionEditModal()}
                  variant="contained"
                  disabled={loading}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {"Add Condition"}
                </StyledButton>
              </>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "0" }}>
        {importOpen && project && permit ? (
          <ConditionImport
            project={project}
            permit={permit}
            bcaCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.BCA
              ).length
            }
            daCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.DA
              ).length
            }
            loading={false}
            open={importOpen}
            handleImportConditons={handleImportConditons}
            setOpen={setImportOpen}
          />
        ) : null}
        {project && permit && exportOpen ? (
          <ConditionExport
            project={project}
            permit={permit}
            loading={false}
            open={exportOpen}
            setOpen={setExportOpen}
            getPdfReport={getPdfReport}
            downloadStepPDF={downloadStepPDF}
            PDFDownloadProgress={PDFDownloadProgress}
            getExcelReport={getExcelReport}
            downloadStepExcel={downloadStepExcel}
            excelDownloadProgress={excelDownloadProgress}
            getFullReportInfo={getFullReportInfo}
            downloadStepAllFiles={downloadStepAllFiles}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        ) : null}
        {project && permit && shareOpen ? (
          <ShareCondition
            permit={permit}
            loading={false}
            disabled={disabled}
            open={shareOpen}
            setOpen={setShareOpen}
            shareConditions={handleShareConditions}
          />
        ) : null}
      </Box>
    </>
  );
}
