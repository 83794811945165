// @mui
import {
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import React from "react";
const transparent = 10;

export const StyledRoot = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  minHeight: "auto !important",
  padding: "0 !important",
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  "&.Mui-focused": {
    boxShadow: `0 8px 16px 0 ${transparent}`,
    width: 320,
  },
  transition: theme.transitions.create(["box-shadow", "width"], {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.easeInOut,
  }),
  width: 240,
}));

// ----------------------------------------------------------------------

interface TableToolbarProps {
  loading: boolean;
  buttonTitle?: string;
  searchTitle?: string;
  numSelected: number;
  filterName: string;
  altControl?: React.ReactNode;
  handleOpen: () => void;
  onFilter: (event: any) => void;
  clearSelected: () => void;
}

export default function ListTableToolbar({
  altControl,
  buttonTitle,
  clearSelected,
  filterName,
  handleOpen,
  loading,
  numSelected,
  onFilter,
  searchTitle,
}: TableToolbarProps) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          alignItems: "flex-end",
          bgcolor: "primary.lighter",
          color: "primary.main",
          padding: "0px !important",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack
          direction="row"
          sx={{
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            component="div"
            variant="subtitle1"
            sx={{ color: "rgb(102, 102, 102)", paddingTop: "30px" }}
          >
            {numSelected} selected
          </Typography>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            alignContent={"flex-end"}
            alignItems={"flex-end"}
            spacing={2}
          >
            <StyledButton
              loading={false}
              color="error"
              variant="outlined"
              onClick={clearSelected}
            >
              Clear
            </StyledButton>
            {altControl}
          </Stack>
        </Stack>
      ) : searchTitle ? (
        <div className="search">
          {/* <StyledSearch
              value={filterName}
              onChange={onFilter}
              placeholder={searchTitle}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{  width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              /> */}
          <StyledTextField
            style={{ minWidth: "300px", width: "100%" }}
            className="search"
            loading={loading}
            type="text"
            placeholder={searchTitle}
            name="filterProject"
            value={filterName}
            onChange={onFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ height: 20, width: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : null}

      {numSelected == 0 && buttonTitle ? (
        <StyledButton
          loading={false}
          onClick={handleOpen}
          variant="contained"
          disabled={loading}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          {buttonTitle}
        </StyledButton>
      ) : null}
    </StyledRoot>
  );
}
