import {
  Box,
  Checkbox,
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";

export const DocumentTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell align="center" padding="checkbox">
          <Skeleton animation="wave">
            <Checkbox />
          </Skeleton>
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }}>
          <Box display="flex" justifyContent="flex-end">
            <Skeleton animation="wave" variant="circular">
              <IconButton size="large" color="inherit">
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
            </Skeleton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
