import {
  getApiData,
  listApiData,
  postApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodeCondition,
  encodeCondition,
  encodeShareCondition,
} from "permit-one-common/src/decoders/condition";
import {
  Condition,
  ConditionLineItem,
  createDefaultShareCondition,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import * as React from "react";
import { toast, ToastOptions } from "react-toastify";

export const useCondition = (
  projectId?: string,
  permitId?: string,
  conditionId?: string
) => {
  const [selectedCondition, setSelectedCondition] =
    React.useState<ConditionLineItem>();

  const [conditions, setConditions] = React.useState<ConditionLineItem[]>([]);
  const [isConditionLoading, setIsConditionLoading] =
    React.useState<boolean>(true);
  const [isConditionUpdating, setIsConditionUpdating] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const getCondition = async (id: string): Promise<void> => {
    try {
      setIsConditionLoading(true);
      const conditionResult = await getApiData("getCondition", "condition", id);
      if (conditionResult) {
        const conditionLineItem = await decodeCondition(
          conditionResult.data as Condition,
          getImageUrl
        );
        setSelectedCondition(conditionLineItem);
      } else {
        setSelectedCondition(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch condition");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const listConditions = async (permitId: string): Promise<void> => {
    try {
      setIsConditionLoading(true);
      const conditionResult = await listApiData(
        "listConditions",
        "condition",
        permitId
      );
      const conditionLineItems = await Promise.all(
        conditionResult.data.map((c) =>
          decodeCondition(c as Condition, getImageUrl)
        )
      );

      const sortedConditions = conditionLineItems.sort((a, b) => {
        return a.permitItemNumber - b.permitItemNumber;
      });
      setConditions(sortedConditions);
    } catch (e: any) {
      setError("Could not list conditions");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const listConditionsForProject = async (projectId: string): Promise<void> => {
    try {
      setIsConditionLoading(true);
      const conditionResult = await listApiData(
        "listConditionsForProject",
        "condition",
        projectId
      );
      const conditionLineItems = await Promise.all(
        conditionResult.data.map((c) =>
          decodeCondition(c as Condition, getImageUrl)
        )
      );
      setConditions(conditionLineItems);
    } catch (e: any) {
      setError("Could not list conditions");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const createCondition = async (
    condition: ConditionLineItem
  ): Promise<void> => {
    try {
      setIsConditionUpdating(true);
      await postApiData(
        "createCondition",
        "condition",
        encodeCondition(condition)
      );
      setConditions([...conditions, condition]);
    } catch (e: any) {
      setError("Could not list conditions");
    } finally {
      setIsConditionUpdating(false);
    }
  };

  const importConditions = async (
    conditionsToImport: ConditionLineItem[]
  ): Promise<void> => {
    try {
      setIsConditionLoading(true);
      const encodedConditionsToImport = conditionsToImport.map((c) =>
        encodeCondition(c)
      );
      const response = await postBatchApiData(
        "importConditions",
        "condition",
        encodedConditionsToImport
      );
      const decodedCondtions = await Promise.all(
        response.data.map((c) => decodeCondition(c as Condition, getImageUrl))
      );

      setConditions([...conditions, ...decodedCondtions]);
    } catch (e: any) {
      setError("Could not list conditions");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const updateCondition = async (
    condition: ConditionLineItem
  ): Promise<void> => {
    try {
      setIsConditionUpdating(true);
      await postApiData(
        "updateCondition",
        "condition",
        encodeCondition(condition)
      );
      condition.messageData = undefined;
      if (selectedCondition) {
        setSelectedCondition(condition);
      } else {
        setConditions(
          conditions.map((c) => {
            if (c.id === condition.id) {
              return condition;
            }
            return c;
          })
        );
      }
    } catch (e: any) {
      setError("Could not update condition");
    } finally {
      setIsConditionUpdating(false);
    }
  };

  const deleteCondition = async (
    condition: ConditionLineItem
  ): Promise<void> => {
    try {
      setIsConditionLoading(true);
      await postApiData(
        "deleteCondition",
        "condition",
        encodeCondition(condition)
      );
      setConditions(conditions.filter((p) => p.id !== condition.id));
    } catch (e: any) {
      setError("Could not delete condition");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const createShareConditions = async (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accessLevel: ShareConditionAccessLevel
  ): Promise<void> => {
    try {
      setIsConditionUpdating(true);

      const encodedShareCondition = encodeShareCondition(
        createDefaultShareCondition(
          email,
          firstName,
          lastName,
          projectId,
          permitId,
          accessLevel
        )
      );
      await postApiData(
        "createShareCondition",
        "shareCondition",
        encodedShareCondition
      );
      toast(`Invite sent to ${email} !`, {
        type: "success",
      } as ToastOptions);
    } catch (e: any) {
      setError("Could not share conditions");
      toast(`Failed to send invite to ${email} !`, {
        type: "error",
      } as ToastOptions);
    } finally {
      setIsConditionUpdating(false);
    }
  };

  React.useEffect(() => {
    if (conditionId) {
      getCondition(conditionId);
    } else if (permitId) {
      listConditions(permitId);
    } else if (projectId) {
      listConditionsForProject(projectId);
    }
  }, [projectId, permitId, conditionId]);

  return {
    conditions,
    createCondition,
    createShareConditions,
    deleteCondition,
    error,
    getCondition,
    importConditions,
    isConditionLoading,
    isConditionUpdating,
    listConditions,
    selectedCondition,
    updateCondition,
  };
};
