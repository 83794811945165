import { Users } from "@stories/pages/Users/Users";

interface UsersViewProps {
  isOpen: boolean;
  handleDrawerOpen: () => void;
}
export const UsersView = ({ isOpen }: UsersViewProps) => {
  console.log(isOpen);
  return <Users />;
};
