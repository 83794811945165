import { useTable } from "@hooks/utils/useTable";
import { Box, Grid, Table, TableBody } from "@mui/material";
import { ListPermitsTabs } from "@stories/molecules/ListPermitsTabs/ListPermitsTabs";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import {
  PermitStatus,
  permitTypeToString,
} from "permit-one-common/src/entities/permit";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { useEffect, useState } from "react";

import { PermitTableRow } from "./PermitTableRow";
import { PermitTableRowSkeleton } from "./PermitTableRowSkeleton";

interface PermitTableProps {
  loading: boolean;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permits: PermitLineItem[];
  searchString: string;
  handleViewPermit: (permit: PermitLineItem) => void;
  handleEditPermit: (permit: PermitLineItem) => void;
  handleDeletePermit: (permit: PermitLineItem) => void;
  handleDestroyPermit: (permit: PermitLineItem) => void;
  handleRestorePermit: (permit: PermitLineItem) => void;
}

export const PermitTable = ({
  handleDeletePermit,
  handleDestroyPermit,
  handleEditPermit,
  handleRestorePermit,
  handleViewPermit,
  loading,
  permits,
  profile,
  project,
  searchString,
  userOrganisation,
}: PermitTableProps) => {
  const params = new URLSearchParams(location.search);
  const initialPermitStatus = parseInt(params.get("status") || "0");
  const { handleRequestSort, handleSelectAllClick, order, orderBy, selected } =
    useTable();

  const [selectedPermitStatus, setSelectedPermitStatus] =
    useState<number>(initialPermitStatus);

  useEffect(() => {
    setSelectedPermitStatus(initialPermitStatus);
  }, [initialPermitStatus]);

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedPermitStatus(newValue);
  };

  const filteredPermits = permits
    .filter(
      (p) =>
        p.permitName.toLowerCase().includes(searchString.toLowerCase()) ||
        permitTypeToString(p.permitType)
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        p.permitDescription
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        p.permitScope.toLowerCase().includes(searchString.toLowerCase())
    )
    .filter((p) => {
      if (selectedPermitStatus === 0) {
        return p.status !== PermitStatus.Archived;
      } else if (selectedPermitStatus === 1) {
        return p.status === PermitStatus.Active;
      } else if (selectedPermitStatus === 2) {
        return p.status === PermitStatus.InReview;
      } else if (selectedPermitStatus === 3) {
        return p.status === PermitStatus.Complete;
      } else if (selectedPermitStatus === 4) {
        return p.status === PermitStatus.Archived;
      }
    });

  let sortedPermits = filteredPermits.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.permitName.toLowerCase() < b.permitName.toLowerCase() ? -1 : 1;
      case 1:
        return a.permitType.toLocaleLowerCase() <
          b.permitType.toLocaleLowerCase()
          ? -1
          : 1;
      case 2:
        // if (a.manager && b.manager) {
        //   return a.manager < b.manager ? -1 : 1;
        // }
        // if (!a.manager && b.manager) {
        //   return -1;
        // }
        // if (a.manager && !b.manager) {
        //   return 1;
        // }
        return 0;
      case 3:
        // if (a.approver && b.approver) {
        //   return a.approver < b.approver ? -1 : 1;
        // }
        // if (!a.approver && b.approver) {
        //   return -1;
        // }
        // if (a.approver && !b.approver) {
        //   return 1;
        // }
        return 0;
      case 4:
        return (a?.endDate || new Date()).getTime() <
          (b?.endDate || new Date()).getTime()
          ? -1
          : 1;
      case 5:
        return a.status < b.status ? -1 : 1;
      default:
        return 0;
    }
  });

  sortedPermits = order === "asc" ? sortedPermits : sortedPermits.reverse();

  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "status", label: "Status" },
    { alignRight: true, id: "permit-name", label: "Permit Name" },
    { alignRight: true, id: "permit-type", label: "Permit Type" },
    { alignRight: true, id: "manager", label: "Manager" },
    { alignRight: true, id: "approver", label: "Approver" },
    {
      alignRight: false,
      id: "days-active",
      label: "Days Active",
    },
    { id: "" },
  ];

  const isNotFound = !filteredPermits.length && !!searchString;

  return (
    <>
      <Grid container>
        <Grid item md={12} sx={{ padding: "25px 0px 0" }}>
          <ListPermitsTabs
            loading={loading}
            value={selectedPermitStatus}
            handleChange={handleConditionStatusChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "20px" }}>
        <Table stickyHeader aria-label="sticky table" className="project-table">
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={filteredPermits.length}
            numSelected={selected.length}
            showCheckbox={false}
            onSelectAllClick={(e: any) =>
              handleSelectAllClick(
                e,
                filteredPermits.map((u) => u.id)
              )
            }
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableBody>
            {!loading
              ? sortedPermits
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <PermitTableRow
                        key={row.id}
                        profile={profile}
                        userOrganisation={userOrganisation}
                        project={project}
                        permit={row}
                        handleViewPermit={handleViewPermit}
                        handleEditPermit={handleEditPermit}
                        handleDeletePermit={handleDeletePermit}
                        handleDestroyPermit={handleDestroyPermit}
                        handleRestorePermit={handleRestorePermit}
                      />
                    );
                  })
              : null}
            {loading
              ? Array.from({ length: 6 }, (_, index) => (
                  <PermitTableRowSkeleton key={index} />
                ))
              : null}
            {!loading && isNotFound && searchString.length && (
              <NoResultsFoundRow
                colSpan={TABLE_HEAD.length + 1}
                filterName={searchString}
              />
            )}
            {sortedPermits.length === 0 && !loading && !searchString.length ? (
              <NoRows colSpan={TABLE_HEAD.length + 1} title={"Permits"} />
            ) : null}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
