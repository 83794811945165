import { useDownloadDocuments } from "@hooks/crud/useDownloadDocuments";
import { useTable } from "@hooks/utils/useTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButton, Table, TableBody, Tooltip, Grid } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";

import { ConditionDocumentTableRow } from "./ConditionDocumentTableRow";
import { ConditionDocumentTableRowSkeleton } from "./ConditionDocumentTableRowSkeleton";

interface ConditionDocumentTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  documents: ConditionDocumentLineItem[];
  handleOpenBulk: () => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleRemoveDocument: (document: ConditionDocumentLineItem) => void;
}

export const ConditionDocumentTable = ({
  documents,
  handleEditDocument,
  handleOpenBulk,
  handleRemoveDocument,
  loading,
  project,
}: ConditionDocumentTableProps) => {
  const {
    clearSelected,
    handleClick,
    handleFilterByName,
    handleSelectAllClick,
    selected,
  } = useTable();

  const onEditDocument = (document: ConditionDocumentLineItem) => {
    handleEditDocument(document);
    clearSelected();
  };
  const onRemoveDocument = (document: ConditionDocumentLineItem) => {
    handleRemoveDocument(document);
    clearSelected();
  };

  const { downloadProgress, downloadStep, getDocumentsZip } =
    useDownloadDocuments();

  const handleDownloadAll = async () => {
    if (project) {
      const selectedDocuments = documents
        .filter((d) => selected.includes(d.id))
        .map((d) => d.id);

      if (selectedDocuments.length > 0) {
        await getDocumentsZip(project.id, selectedDocuments);
      }
    }
  };

  const altControl = (
    <Tooltip title="Download All">
      <IconButton onClick={handleDownloadAll}>
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  );

  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: false, id: "document-number", label: "Code" },
    { alignRight: false, id: "document-title", label: "Title" },
    { alignRight: false, id: "document-revision", label: "Revision" },
    { alignRight: false, id: "document-discipline", label: "Discipline" },
    { alignRight: false, id: "document-category", label: "Category" },
    { alignRight: false, id: "document-created-by", label: "Creator" },
    { alignRight: false, id: "document-modified", label: "Upload" },
    { alignRight: false, id: "document-file-type", label: "Type" },
    { alignRight: false, id: "document-file-size", label: "Size" },
    { id: "", static: true },
  ];

  const { filterName, handleRequestSort, order, orderBy } = useTable();

  const filteredDocuments =
    documents.length > 0
      ? documents.filter(
          (u) =>
            u.documentTitle
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase()) ||
            u.documentCategory
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase())
        )
      : documents;

  let sortedDocuments = filteredDocuments.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.documentNumber.toLowerCase() < b.documentNumber.toLowerCase()
          ? -1
          : 1;
      case 1:
        return a.documentTitle.toLowerCase() < b.documentTitle.toLowerCase()
          ? -1
          : 1;
      case 2:
        return a.revisionNumber < b.revisionNumber ? -1 : 1;
      case 3:
        return a.documentDiscipline.toLowerCase() <
          b.documentDiscipline.toLowerCase()
          ? -1
          : 1;
      case 4:
        return a.documentCategory.toLowerCase() <
          b.documentCategory.toLowerCase()
          ? -1
          : 1;
      case 5:
        return a.createdByName.toLowerCase() < b.createdByName.toLowerCase()
          ? -1
          : 1;
      case 6:
        return a.created.getTime() < b.created.getTime() ? -1 : 1;
      case 7:
        return a.fileType < b.fileType ? -1 : 1;
      case 8:
        return a.fileSize < b.fileSize ? -1 : 1;
      default:
        return 0;
    }
  });

  sortedDocuments =
    order === "asc" ? sortedDocuments : sortedDocuments.reverse();

  return (
    <>
      <Grid sx={{ padding: "0 0 20px" }}>
        <ListTableToolbar
          loading={loading}
          handleOpen={handleOpenBulk}
          buttonTitle={"Add Documents"}
          searchTitle="Search Documents..."
          numSelected={selected.length}
          filterName={filterName}
          clearSelected={clearSelected}
          onFilter={handleFilterByName}
          altControl={altControl}
        />
      </Grid>

      <Table stickyHeader aria-label="sticky table" className="project-table">
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={sortedDocuments.length}
          numSelected={selected.length}
          showCheckbox
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          onSelectAllClick={(e: any) =>
            handleSelectAllClick(
              e,
              filteredDocuments.map((u) => u.id)
            )
          }
        />
        <TableBody>
          {!loading
            ? sortedDocuments.map((row) => {
                const selectedDocument = selected.indexOf(row.id) !== -1;
                return (
                  <ConditionDocumentTableRow
                    key={row.id}
                    document={row}
                    selected={selectedDocument}
                    handleClick={handleClick}
                    handleEditDocument={onEditDocument}
                    handleRemoveDocument={onRemoveDocument}
                  />
                );
              })
            : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <ConditionDocumentTableRowSkeleton key={index} />
              ))
            : null}
          {!loading && sortedDocuments.length === 0 ? (
            <NoRows colSpan={11} title={"documents"} />
          ) : null}
        </TableBody>
      </Table>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Exporting Documents Zip"}
      />
    </>
  );
};
