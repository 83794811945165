import { Box, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import React from "react";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";

export const fileTypeToIcon = (fileUrl: string) => {
  const fileName = fileUrl.split("/").pop();
  if (!fileName) {
    return null; // Early return if there's no file name
  }

  const fileExtension = fileName.split(".").pop()?.toLowerCase(); // Convert to lowercase for consistency
  const defaultIconStyles = {
    labelColor: "gray", // Default color if the extension is not found
    radius: 2,
  };

  return (
    <Tooltip TransitionComponent={Zoom} placement="top" title={fileName}>
      <Box sx={{ maxWidth: "100px", textAlign: "center" }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FileIcon
            radius={2}
            labelColor={
              (fileExtension &&
                defaultStyles[fileExtension as DefaultExtensionType]
                  ?.labelColor) ||
              defaultIconStyles.labelColor
            }
            extension={fileExtension}
            {...(defaultStyles[fileExtension as DefaultExtensionType] ||
              defaultIconStyles)} // Use default styles if no specific style found
          />
          <Typography
            variant="subtitle2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {fileName}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  );
};
