import { Box, Skeleton, TableCell, Typography } from "@mui/material";
import React from "react";

interface TableHeaderProps {
  loading: boolean;
  title: string;
  align?: string;
  width?: string;
}

export const TableHeader = ({
  align = "left",
  loading,
  title,
  width,
}: TableHeaderProps) => {
  return (
    <TableCell align="center" sx={{ width: width }}>
      {loading ? (
        <Box display="flex" justifyContent={align}>
          <Skeleton
            animation="wave"
            sx={{ magin: align == "center" ? "auto" : "" }}
          >
            <Typography>{title}</Typography>
          </Skeleton>
        </Box>
      ) : (
        <Typography sx={{ textAlign: align }}>{title}</Typography>
      )}
    </TableCell>
  );
};
