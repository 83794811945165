import { LoadingSteps, Steps } from "@stories/atoms/Steps/Steps";

const steps = [
  "Permit Details",
  "Building Works",
  "Development Approval Details",
  "Address",
  "Applicant",
  "Owner",
  "Additional Info",
];

interface PermitStepsProps {
  loading: boolean;
  activeStep: number;
}

export const PermitSteps = ({ activeStep, loading }: PermitStepsProps) => {
  if (loading) {
    return <LoadingSteps activeStep={activeStep} steps={steps} />;
  }
  return <Steps activeStep={activeStep} steps={steps} />;
};
