import { getApiData, postApiData } from "@hooks/utils/api";
import { decodeOrganisation, encodeOrganisation } from "permit-one-common/src/decoders/organisation";
import { Organisation } from "permit-one-common/src/entities/organisation";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import * as React from "react";
import { ToastOptions, toast } from "react-toastify";

export const useOrganisation = (profileOrganisation?: OrganisationLineItem) => {
  const [projectOrganisation, setProjectOrganisation] =
    React.useState<OrganisationLineItem>();
  const [userOrganisation, setUserOrganisation] = React.useState<
    OrganisationLineItem | undefined
  >(profileOrganisation);

  const [isOrganisationLoading, setIsOrganisationLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getOrganisation = async (
    id: string
  ): Promise<OrganisationLineItem | null> => {
    try {
      setIsOrganisationLoading(true);
      const organisationResult = await getApiData(
        "getOrganisation",
        "organisation",
        id
      );
      if (
        !organisationResult ||
        !organisationResult.data ||
        Object.keys(organisationResult.data).length === 0
      ) {
        return null;
      }

      return await decodeOrganisation(organisationResult.data as Organisation);
    } catch (e: any) {
      setError("Could not fetch organisation");
    } finally {
      setIsOrganisationLoading(false);
    }
    return null;
  };

  const getProjectOrganisation = async (id: string): Promise<void> => {
    try {
      setIsOrganisationLoading(true);
      const organisationResult = await getApiData(
        "getProjectOrganisation",
        "organisation",
        id
      );
      if (
        !organisationResult ||
        !organisationResult.data ||
        Object.keys(organisationResult.data).length === 0
      ) {
        return undefined;
      }

      const organisationLineItem = await decodeOrganisation(
        organisationResult.data as Organisation
      );
      setProjectOrganisation(organisationLineItem);
    } catch (e: any) {
      setError("Could not fetch organisation");
    } finally {
      setIsOrganisationLoading(false);
    }
  };

  const getProfileOrganisation = async (id: string): Promise<void> => {
    try {
      setIsOrganisationLoading(true);
      const organisationResult = await getApiData(
        "getOrganisation",
        "organisation",
        id
      );
      if (
        !organisationResult ||
        !organisationResult.data ||
        Object.keys(organisationResult.data).length === 0
      ) {
        return undefined;
      }
      const organisationLineItem = await decodeOrganisation(
        organisationResult.data as Organisation
      );
      setUserOrganisation(organisationLineItem);
    } catch (e: any) {
      setError("Could not fetch organisation");
    } finally {
      setIsOrganisationLoading(false);
    }
  };

  const createOrganisation = async (
    organisation: OrganisationLineItem
  ): Promise<OrganisationLineItem | undefined> => {
    try {
      setIsOrganisationLoading(true);
      const encodedOrganisation = encodeOrganisation(organisation);
      const upsertedOrganisation = await postApiData(
        "createOrganisation",
        "organisation",
        encodedOrganisation
      );
      const decodedOrganisation = await decodeOrganisation(
        upsertedOrganisation.data as Organisation
      );
      setUserOrganisation(decodedOrganisation);
      toast("Organisation created!", {
        type: "success",
      } as ToastOptions);
      return organisation;
    } catch (e: any) {
      setError("Could not create organisation");
    } finally {
      setIsOrganisationLoading(false);
    }
    return undefined;
  };

  const updateOrganisation = async (
    organisation: OrganisationLineItem
  ): Promise<OrganisationLineItem | undefined> => {
    try {
      setIsOrganisationLoading(true);
      const encodedOrganisation = encodeOrganisation(organisation);
      await postApiData(
        "updateOrganisation",
        "organisation",
        encodedOrganisation
      );

      setUserOrganisation(organisation);
      toast("Organisation updated!", {
        type: "success",
      } as ToastOptions);
      return organisation;
    } catch (e: any) {
      setError("Could not update organisation");
      toast("Could not update organisation!", {
        type: "error",
      } as ToastOptions);
    } finally {
      setIsOrganisationLoading(false);
    }
    return undefined;
  };

  const deleteOrganisation = async (
    organisation: OrganisationLineItem
  ): Promise<void> => {
    try {
      setIsOrganisationLoading(true);
      const encodedOrganisation = encodeOrganisation(organisation);
      await postApiData(
        "removeOrganisation",
        "organisation",
        encodedOrganisation
      );
      setUserOrganisation(undefined);
    } catch (e: any) {
      setError("Could not delete organisation");
    } finally {
      setIsOrganisationLoading(false);
    }
  };

  return {
    createOrganisation,
    deleteOrganisation,
    error,
    getOrganisation,
    getProfileOrganisation,
    getProjectOrganisation,
    isOrganisationLoading,
    projectOrganisation,
    updateOrganisation,
    userOrganisation,
  };
};
