import { listApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { decodeCondition } from "permit-one-common/src/decoders/condition";
import { Condition, ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import * as React from "react";

export const useTask = (projectId?: string) => {
  const [tasks, setTasks] = React.useState<ConditionLineItem[]>([]);
  const [isTaskLoading, setIsTaskLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listTasks = async (projectId: string): Promise<void> => {
    try {
      setIsTaskLoading(true);
      const tasksResult = await listApiData("listTasks", "task", projectId);
      const taskLineItems = await Promise.all(
        tasksResult.data.map((c) => decodeCondition(c as Condition, getImageUrl))
      );
      setTasks(taskLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsTaskLoading(false);
    }
  };

  const getTaskCounts = async (projectId: string): Promise<void> => {
    try {
      setIsTaskLoading(true);
      const tasksResult = await listApiData("getTaskCounts", "task", projectId);
      const taskLineItems = await Promise.all(
        tasksResult.data.map((c) => decodeCondition(c as Condition, getImageUrl))
      );
      setTasks(taskLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsTaskLoading(false);
    }
  };

  React.useEffect(() => {
    if (projectId) {
      listTasks(projectId);
    } else {
      setIsTaskLoading(false);
    }
  }, [projectId]);

  return {
    error,
    getTaskCounts,
    isTaskLoading,
    listTasks,
    tasks,
  };
};
