import { UploadStatus, uploadImage } from "@hooks/utils/useUpload";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import { Button, IconButton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { QuillEditor } from "@stories/atoms/QuillEditor/QuillEditor";
import SubCard from "@stories/ui-component/cards/SubCard";
import { gridSpacing } from "constant";
import { createDefaultMail } from "permit-one-common/src/interfaces/mail";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";

import { MailInputProps } from "./MailProps";
import { fileTypeToIcon } from "../../../utils/file";
import { UploadModal } from "../BulkDocumentForm/UploadModal";


const MailInput = ({
  existingMail,
  isLoading,
  profile,
  submitMail,
}: MailInputProps) => {
  const theme = useTheme();

  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);

  const [message, setMessage] = useState("");

  const sendReply = async () => {
    if (profile) {
      const uploadKeys = await handleUpload();
      const newMail = createDefaultMail(
        existingMail?.id,
        message,
        profile,
        uploadKeys
      );
      await submitMail({
        ...existingMail,
        mail: [...existingMail.mail, newMail],
      });
    }
  };

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleUpload = async () => {
    const uploadKeys = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload[i];
      if (fileToUpload) {
        setUploadProgress(0);
        if (fileToUpload) {
          const fileKey = `attachments/${existingMail?.id}/${fileToUpload.name}`;
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
            }
          );
          setUploadedIndices([...uploadedIndice, i]);
          uploadKeys.push(fileKey);
        }
      }
      setUploadStatus(UploadStatus.Saving);
    }
    return uploadKeys;
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        setFilesToUpload(Array.from(e.currentTarget?.files));
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={gridSpacing}
        className="messageBox"
        sx={{ margin: "30px 0", padding: "0 !important", width: "100%" }}
      >
        <Grid item md={12} sx={{ padding: "0 !important" }}>
          <SubCard
            sx={{
              "& .quill": {
                "& .ql-container": {
                  "& .ql-editor": {
                    minHeight: 225,
                  },
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  borderColor: theme.palette.primary.light,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                },
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
              },
              bgcolor: theme.palette.primary[200] + 40,
            }}
          >
            <QuillEditor
              loading={isLoading}
              onChange={(value: string) => {
                setMessage(value);
              }}
              value={message}
            />
            <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Stack direction="row">
                  <IconButton size="large" onClick={onFileInput}>
                    <input
                      hidden
                      ref={fileInput}
                      accept="*/*"
                      multiple
                      type="file"
                      onChange={handleSelectFiles}
                    />
                    <AttachmentTwoToneIcon />
                  </IconButton>
                  {filesToUpload.map((file) => {
                    return fileTypeToIcon(file.name);
                  })}
                </Stack>
              </Grid>
              <Grid item sx={{ flexGrow: 1 }} />
              <Grid item>
                <Button variant="contained" onClick={sendReply}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
      <UploadModal total={uploadProgress} uploadStatus={uploadStatus} />
    </>
  );
};

export default MailInput;
