import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./style.css";
import AOS from "aos";
import ManageReview from "assets/images/1-min.png";
import Monitor from "assets/images/2-min.png";
import Report from "assets/images/3-min.png";
import Search from "assets/images/4-min.png";
import logo1 from "assets/images/AR-min.png";
import logo7 from "assets/images/DP-min.png";
import FooterLogo from "assets/images/foot-logo.png";
import Logo from "assets/images/head-logo.svg";
import HeroBg from "assets/images/homeHero.png";
import logo6 from "assets/images/HT-min.png";
import "aos/dist/aos.css";
import "./permitStyles.css";
import indsImg1 from "assets/images/indsslide1.png";
import indsImg2 from "assets/images/indsslide2.png";
import indsImg3 from "assets/images/indsslide3.png";
import indsImg4 from "assets/images/indsslide4.png";
import indsImg5 from "assets/images/indsslide5.png";
import indsImg6 from "assets/images/indsslide6.png";
import instgram from "assets/images/instgram-logo.webp";
import logo5 from "assets/images/JH-min.png";
import logo4 from "assets/images/Jun-min.png";
import logo3 from "assets/images/knowles-min.png";
import linkedin from "assets/images/linkedin-logo.webp";
import LogoActive from "assets/images/LogoActive.png";
import logo2 from "assets/images/mpx-min.png";
import ctaImg2 from "assets/images/permitone-cta.png";
import logo8 from "assets/images/seemode-min.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import AnimatedCounter from "./animated";

export const Home = () => {
  const [IsSticky, setIsSticky] = useState(false);
  const [IsActive, setIsActive] = useState(false);

  const settingsAnimation = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0",
    className: "center",
    dots: false,
    focusOnSelect: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    vertical: true,
    verticalSwiping: true,
  };

  const settingsLogoslider = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 374,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 5,
  };

  const settingsIndsSlider = {
    arrows: true,
    autoplay: false,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: "0",
    cssEase: "linear",
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          centerMode: false,
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 3,
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether the animation should only happen once
    });
  }, []);

  const [menus, setMenus] = useState([
    {
      isOpen: false,
      items: [
        { label: "permit management", url: "/permit-management" },
        { label: "Communication portal", url: "/communication-portal" },
        { label: "document Control", url: "/document-control" },
        { label: "Reporting & Analytics", url: "/reporting-and-analytics" },
      ],
      label: "Solutions",
    },
    {
      isOpen: false,
      items: [
        { label: "Templates", url: "/templates" },
        { label: "News & Articles", url: "/blog" },
        { label: "API Integration", url: "/api-integration" },
      ],
      label: "Resources",
    },
    {
      isOpen: false,
      items: [
        { label: "Our Team", url: "/our-team" },
        { label: "Support", url: "/support" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Terms & Conditions ", url: "/terms-conditions" },
      ],
      label: "Company",
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[menuIndex].isOpen = !updatedMenus[menuIndex].isOpen;
      return updatedMenus;
    });
  };

  return (
    <>
      <header className={IsSticky === true ? "sub-header active" : ""}>
        <div className="container-new">
          <div className="navigation">
            <a href="/" className="site-logo">
              {IsSticky === true ? (
                <img src={LogoActive} alt="socialpro" />
              ) : (
                <img src={Logo} alt="socialpro" />
              )}
            </a>
            <ul>
              <li>
                <span>Solutions</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/permit-management">permit management</Link>
                  </li>
                  <li>
                    <Link to="/communication-portal">Communication portal</Link>
                  </li>
                  <li>
                    <Link to="/document-control">document Control</Link>
                  </li>
                  <li>
                    <Link to="/reporting-and-analytics">
                      Reporting & Analytics
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Resources</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/templates">Templates</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Articles</Link>
                  </li>
                  <li>
                    <Link to="/api-integration">API Integration</Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Company</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div className="head-btns">
              <a href="/request-demo" className="demo-btn">
                Demo
              </a>
              <a href="/login" className="nav-login">
                <svg
                  data-bbox="0 0 50 50"
                  data-type="shape"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                >
                  <g>
                    <path
                      d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>Sign in</span>
              </a>
            </div>

            {IsActive === false ? (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  height={40}
                >
                  <path
                    fillRule="evenodd"
                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  height={40}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        {/* Mob Navigation  */}

        <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
          <div className="mob-nav">
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo" className="demo-link">
                  Demo
                </a>
                <a href="/login" className="nav-login sub-head">
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                        fill="#005CD1"
                      />
                    </g>
                  </svg>
                  <span>Sign in</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* Hero Section  */}
      <main className="hero-new">
        <div className="container-new">
          <div className="hero-main">
            <div
              className="hero-content"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="hero-text">
                <h1>
                  Designed for <span>permits.</span>
                  <span>Fast track</span> your project.
                </h1>
                <p>
                  Streamline compliance by automating checklists, emails and
                  spreadsheets within a single shared platform, so you can start
                  construction sooner.
                </p>

                <div className="hero-btns">
                  <a href="/request-demo" className="discover">
                    Request a demo
                  </a>
                  <a href="/request-demo" className="discover outline-btn">
                    Contact sales
                  </a>
                </div>
              </div>
              <div className="hero-image">
                <img src={HeroBg} alt="HeroBg" className="desk-hero" />
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Logo Slider  */}
      <div className="bg-white">
        <div className="container-new slider-logo">
          <h3>Brought to you by the people that worked for</h3>
          <div className="slider-logo-wrap">
            <Slider {...settingsLogoslider}>
              <div>
                <img src={logo1} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo2} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo3} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo4} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo5} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo6} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo7} alt="sliderlogo1" />
              </div>
              <div>
                <img src={logo8} alt="sliderlogo1" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="counter-section">
        <div className="container-new">
          <h4 className="about-title">why choose permitone?</h4>
          <h3 className="about-tagline">Prepare and submit permits faster.</h3>
          <div className="counter">
            <div className="count-box">
              <div>
                {" "}
                <AnimatedCounter from={0} to={60} />
                <span>%</span>
              </div>
              <h5>increase in productivity</h5>
              <p>
                With PermitOne’s powerful platform, you can efficiently allocate
                resources – removing the manual requirement for processing and
                managing conditions.
              </p>
            </div>
            <div className="count-box">
              <div>
                {" "}
                <AnimatedCounter from={0} to={50} />
                <span>X</span>
              </div>
              <h5>faster permits</h5>
              <p>
                PermitOne is designed to streamline the permit management
                process, allowing you to save valuable time and expedite project
                timelines.
              </p>
            </div>
            <div className="count-box">
              <div>
                {" "}
                <AnimatedCounter from={0} to={4000} />
                <span>+</span>
              </div>
              <h5>person hours saved a year</h5>
              <p>
                With better visibility into permit status, comprehensive
                reporting, and streamlined processes - PermitOne reduces the
                manual handling.
              </p>
            </div>
            <div className="count-box">
              <div>
                {" "}
                <AnimatedCounter from={0} to={1} />
                <span />
              </div>
              <h5>powerful platform</h5>
              <p>
                Empower your project to work better, together, by bringing the
                right systems, processes, and information onto a single platform
                with PermitOne.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section  */}
      <div className="" style={{ background: "#fff" }}>
        <div className="container-new feature-tab-section" id="tabsWrapper">
          <h4 className="feature-title">PERMIT MANAGER</h4>
          <h3 className="feature-tagline">
            Create, manage & approve in one place.
          </h3>
          <p className="feature-txt-new">
            There&#39;s no more double handling - use PermitOne to streamline
            the permit approval process with your team. Standardising and
            simplying permits on an easy-to-use portal; removing the need to
            work across multiple traditional and outdated programs.
          </p>

          <div className="manage-project-wrap">
            <div className="zigzag">
              <div className="zig-content">
                <h4>CONSOLIDATE</h4>
                <h3>One checklist to rule them all.</h3>
                <p>
                  Create a single Permit Checklist that can be queried, updated,
                  shared, actioned, and approved with project teams, easily
                  controlled on our PermitOne portal.
                </p>
                <a href="/request-demo" className="btn">
                  Discover more
                </a>
              </div>

              <div className="zig-img">
                <img src={ManageReview} alt="ManageReview" />
              </div>
            </div>
            <div className="zigzag zig-reverse">
              <div className="zig-content">
                <h4>MANAGE</h4>
                <h3>Automate permit management.</h3>
                <p>
                  Take the time and costs out of administrative permit
                  management tasks, by delegating and actioning permit
                  requirements with project teams in a smart permit checklist.{" "}
                </p>
                <a href="/request-demo" className="btn">
                  Discover more
                </a>
              </div>
              <div className="zig-img">
                <img src={Monitor} alt="Monitor" />
              </div>
            </div>
            <div className="zigzag">
              <div className="zig-content">
                <h4>ASSIST</h4>
                <h3>Communicate & Action.</h3>
                <p>
                  Simplify permit communications with professional consultants
                  and contractors in one place. Enable teams to brief, query and
                  action permit information efficiently, resulting in a faster
                  approval process.{" "}
                </p>
                <a href="/request-demo" className="btn">
                  Discover more
                </a>
              </div>

              <div className="zig-img">
                <img src={Report} alt="Report" />
              </div>
            </div>
            <div className="zigzag zig-reverse">
              <div className="zig-content">
                <h4>APPROVE </h4>
                <h3>Approvals made easy.</h3>
                <p>
                  PermitOne standardises and improves the Permit management
                  process, by removing the administrative tasks from project
                  teams – saving time and money.
                </p>
                <a href="/request-demo" className="btn">
                  Discover more
                </a>
              </div>
              <div className="zig-img">
                <img src={Search} alt="Monitor" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white inds-tabs-wrap">
        <div className="container-new feature-tab-section" id="tabsWrapper">
          <h4 className="feature-title">INDUSTRY CERTIFIED</h4>
          <h3 className="feature-tagline">
            Software built for construction professionals
          </h3>
          <p className="feature-txt-new">
            Designed by industry professionals for industry professionals,
            PermitOne is the ultimate permit management tool. Our platform
            offers streamlined processes, real-time updates, and seamless
            collaboration capabilities, empowering industry professionals to
            efficiently manage permits.
          </p>
          <div className="inds-tabs">
            <Slider {...settingsIndsSlider}>
              <div className="cooridnate-social-content">
                <img src={indsImg6} alt="" />
                <h3>Certifiers & Surveyors</h3>
                <p>
                  PermitOne empowers building certifiers with a robust platform,
                  streamlining permit management by facilitating efficient
                  creation, management, and review of applications, inspection
                  tracking, and compliance monitoring. Customisable checklists,
                  document tools, and automated notifications enable precise
                  assessments, inspection scheduling, and seamless communication
                  with stakeholders.
                </p>
              </div>
              <div className="cooridnate-social-content">
                <img src={indsImg5} alt="" />
                <h3>Town Planners</h3>
                <p>
                  PermitOne accelerates permit approvals for town planners
                  through automated notifications, real-time collaboration, and
                  thorough reporting. Its intuitive interface and customisable
                  checklists facilitate efficient assessment of applications,
                  informed decision-making, and active contribution to
                  construction projects, ensuring adherence to quality
                  standards.
                </p>
              </div>

              <div className="cooridnate-social-content">
                <img src={indsImg4} alt="" />
                <h3>Developers</h3>
                <p>
                  PermitOne aids developers with a comprehensive platform,
                  streamlining permit management, progress tracking, and
                  compliance assurance. Real-time updates, collaboration tools,
                  and customisable reports empower developers to monitor permit
                  status, allocate resources efficiently, and make informed,
                  data-driven decisions.
                </p>
              </div>

              <div className="cooridnate-social-content">
                <img src={indsImg3} alt="" />
                <h3>Consultants</h3>
                <p>
                  PermitOne&#39;s centralised hub empowers consultants, offering
                  easy access to permit details and seamless collaboration.
                  Customisable checklists and automated data entry ensure
                  compliance, progress tracking, and meaningful contributions to
                  construction projects. The intuitive interface and real-time
                  updates streamline workflows, enhance communication,
                  delivering high-quality consulting services for project
                  success.
                </p>
              </div>

              <div className="cooridnate-social-content">
                <img src={indsImg2} alt="" />
                <h3>Contractors</h3>
                <p>
                  PermitOne simplifies permit management for contractors with
                  automated notifications, document tools, and seamless
                  collaboration. It enables easy access to permit information,
                  effective communication with project teams, and efficient
                  permit compliance. Streamlining workflows, reducing manual
                  paperwork, and focusing on core construction tasks enhance
                  productivity, minimising delays.
                </p>
              </div>

              <div className="cooridnate-social-content">
                <img src={indsImg1} alt="" />
                <h3>Project Managers</h3>
                <p>
                  PermitOne aids project managers with real-time collaboration,
                  task allocation, and automated notifications. Its intuitive
                  interface and reporting enhance visibility, enabling informed
                  decisions to keep construction projects on schedule and within
                  budget, ensuring efficient team coordination, permit progress
                  tracking, and compliance.
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* CTA Section API */}

      {/* <div className="cta-section api-section bg-white">
        <div className="cta-wrapper">
          <div className="cta-new-section">
            <img src={ctaImg} alt="" />
            <div className="box">
              <h4>API INTEGRATION</h4>
              <h3
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                Everything in one place.
              </h3>
              <p>
                PermitOne allows seamless integration into your existing tools
                for permit & construction management, so your systems talk to
                each other.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* new form Section  */}

      <div className="cta-section fast-track">
        <div className="cta-wrapper">
          <div className="cta-new-section">
            <img src={ctaImg2} alt="" />
            <div className="box">
              <div className="content">
                <div className="choose-socialpro">
                  <h2
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-easing="linear"
                  >
                    Choose <span>PermitOne</span> for
                  </h2>
                </div>
              </div>
              <div className="animate-slider animated-heading">
                <Slider {...settingsAnimation}>
                  <h5>Document Control</h5>
                  <h5>Project Transparency</h5>
                  <h5>Faster Permits</h5>
                  <h5>Software Integrations</h5>
                  <h5>Team Collaboration</h5>
                  <h5>Mail Management</h5>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="forms-section bg-white get-started ">
        <div className="container-new">
          <div className="col-left">
            <h4 className="feature-title">get started</h4>
            <h2>See how PermitOne can help fast track your project.</h2>
            <h3>
              Click below to request a demo of our platform or to chat with a
              member of our team.
            </h3>
            <a href="/request-demo" className="btn">
              Request a Demo
            </a>
          </div>
          <div className="col-right">
            <div className="contact-form-new">
              <img src={HeroBg} alt="HeroBg" className="desk-hero" />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <footer className="darkblue-bg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social mob-hide">
              <ul className="social-links">
                <li>
                  <a
                    href=" https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
          <div className="footer-links">
            <h4>SOLUTION</h4>
            <ul>
              <li>
                <Link to="/permit-management">permit management</Link>
              </li>
              <li>
                <Link to="/communication-portal">Communication portal</Link>
              </li>
              <li>
                <Link to="/document-control">document Control</Link>
              </li>
              <li>
                <Link to="/reporting-and-analytics">Reporting & Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>RESOURCES</h4>
            <ul>
              <li>
                <Link to="/request-demo">Request a demo</Link>
              </li>
              <li>
                <Link to="/templates">TEMPLATES</Link>
              </li>
              <li>
                <Link to="/blog">News & Articles</Link>
              </li>
              <li>
                <Link to="/api-integration">API Integration</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
            <div className="footer-social desk-hide">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/permit.one/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
