import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitLodgementLineItem,
  createDefaultPermitLodgement,
} from "permit-one-common/src/interfaces/permitLodgement";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";

import { LodgementForm } from "../LodgementForm/LodgementForm";

interface LodgementModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  createLodgement: (lodgements: PermitLodgementLineItem) => void;
  handleClose: () => void;
}

export default function LodgementModal({
  createLodgement,
  handleClose,
  open,
  permit,
  project,
}: LodgementModalProps) {
  const onSubmit = async (lodgement: PermitLodgementLineItem) => {
    await createLodgement(lodgement);
    handleClose();
  };
  const title = "Create Lodgement";
  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} className="bgBlueOverlay">
        <DialogTitle
          sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                background: "#005cd1",
              },
              background: "#000",
              color: "#fff",
              position: "absolute",
              right: 14,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px 35px 40px" }}>
          <LodgementForm
            project={project}
            permit={permit}
            lodgement={createDefaultPermitLodgement(project, permit)}
            handleSubmit={onSubmit}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
