import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { NavItemType } from "types";

import { getDashboardNav } from "./dashboard";
import { getDirectoryNav } from "./directory";
import { getWorkingNav } from "./getWorkingNav";

export const getNavMenuItems = (
  project?: ProjectLineItem
): { items: NavItemType[] } => {
  const items = [getDashboardNav(project?.id)];
  items.push(getWorkingNav(project));
  items.push(getDirectoryNav(project?.id));
  return { items };
};
