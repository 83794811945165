import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import { Grid, Stack, Typography } from "@mui/material";
import AttachmentCard from "@stories/ui-component/cards/AttachmentCard";
import { gridSpacing } from "constant";
import Parser from "html-react-parser";

import { MailBodyProps } from "./MailProps";

const MessageBody = ({ data }: MailBodyProps) => {
  return (
    <Grid item xs={12}>
      <Grid
        item
        sx={{
          background: "#E9F0F5",
          borderRadius: "12px",
          margin: "20px 0 20px ",
          maxWidth: "max-content",
          minWidth: "200px !important",
          padding: "10px 15px",
          width: "100% !important",
        }}
        className="messageText"
      >
        <Typography
          sx={{ color: "black", fontSize: "16px", margin: "0 !important" }}
        >
          {Parser(data.message)}
        </Typography>
      </Grid>

      {data?.attachments && (
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ padding: "10px 0" }}
              >
                <AttachmentTwoToneIcon fontSize="small" />
                <Typography variant="h5" sx={{ fontSize: "14px !important" }}>
                  {data?.attachments.length}{" "}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px !important" }}>
                  Attachments
                </Typography>
              </Stack>
            </Grid>
            {data?.attachments.map((item, index) => (
              <Grid item md={5} key={index}>
                <AttachmentCard uri={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MessageBody;
