import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Skeleton, TableCell, TableRow } from "@mui/material";

export const FileRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" animation="wave" />
      </TableCell>
      <TableCell align="right" sx={{ width: "5%" }}>
        <Box display="flex" gap="5px" justifyContent={"right"}>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="large">
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
