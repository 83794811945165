"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeImportedConditionDiscipline = exports.encodeShareCondition = exports.decodeShareCondition = exports.encodeCondition = exports.decodeCondition = exports.decodeConditionCount = void 0;
var profile_1 = require("./profile");
var condition_1 = require("../entities/condition");
var string_1 = require("../utils/string");
var decodeConditionCount = function (conditionCount) { return __awaiter(void 0, void 0, void 0, function () {
    var safePermit;
    return __generator(this, function (_a) {
        safePermit = (0, string_1.reversePostgresSafe)(conditionCount);
        return [2 /*return*/, {
                approved: safePermit.approved,
                assigned: safePermit.assigned,
                inReview: safePermit.in_review,
                notApplicable: safePermit.notApplicable,
                notAssigned: safePermit.not_assigned,
                overdue: safePermit.overdue,
                rejected: safePermit.rejected,
            }];
    });
}); };
exports.decodeConditionCount = decodeConditionCount;
var decodeCondition = function (condition, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var safeCondition, assigneeProfiles, _a, managerProfiles, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                safeCondition = (0, string_1.reversePostgresSafe)(condition);
                if (!safeCondition.assignees) return [3 /*break*/, 2];
                return [4 /*yield*/, Promise.all(safeCondition.assignees.map(function (a) { return (0, profile_1.decodeProfile)(a, getImageUrl); }))];
            case 1:
                _a = _c.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = [];
                _c.label = 3;
            case 3:
                assigneeProfiles = _a;
                if (!safeCondition.managers) return [3 /*break*/, 5];
                return [4 /*yield*/, Promise.all(safeCondition.managers.map(function (a) { return (0, profile_1.decodeProfile)(a, getImageUrl); }))];
            case 4:
                _b = _c.sent();
                return [3 /*break*/, 6];
            case 5:
                _b = [];
                _c.label = 6;
            case 6:
                managerProfiles = _b;
                return [2 /*return*/, {
                        assignees: assigneeProfiles,
                        collaborators: "",
                        conditionAuthority: safeCondition.condition_authority,
                        conditionComments: safeCondition.condition_comments,
                        conditionDetails: safeCondition.condition_details,
                        conditionName: safeCondition.condition_name,
                        created: new Date(safeCondition.created),
                        discipline: safeCondition.discipline,
                        disciplineOther: safeCondition.discipline_other,
                        dueDate: new Date(safeCondition.due_date),
                        id: safeCondition.condition_id,
                        managers: managerProfiles,
                        markedAsNotApplicable: safeCondition.marked_as_not_applicable,
                        messageData: safeCondition.message_data,
                        permitId: safeCondition.permit_id,
                        permitItemNumber: safeCondition.permit_item_number,
                        permitName: safeCondition.permit_name,
                        permitType: safeCondition.permit_type,
                        projectId: safeCondition.project_id,
                        status: safeCondition.status,
                    }];
        }
    });
}); };
exports.decodeCondition = decodeCondition;
var encodeCondition = function (condition) {
    var safeCondition = (0, string_1.makePostgresSafe)(condition);
    var assigneeProfiles = safeCondition.assignees.map(function (a) { return (0, profile_1.encodeProfile)(a); });
    var managerProfiles = safeCondition.managers.map(function (a) { return (0, profile_1.encodeProfile)(a); });
    return (0, string_1.makePostgresSafe)({
        assignees: assigneeProfiles,
        condition_authority: safeCondition.conditionAuthority,
        condition_comments: safeCondition.conditionComments,
        condition_details: safeCondition.conditionDetails,
        condition_id: safeCondition.id,
        condition_name: safeCondition.conditionName,
        created: safeCondition.created,
        discipline: safeCondition.discipline,
        discipline_other: safeCondition.disciplineOther,
        due_date: safeCondition.dueDate,
        managers: managerProfiles,
        marked_as_not_applicable: safeCondition.markedAsNotApplicable,
        message_data: safeCondition.messageData,
        permit_id: safeCondition.permitId,
        permit_item_number: safeCondition.permitItemNumber,
        permit_name: safeCondition.permitName,
        permit_type: safeCondition.permitType,
        project_id: safeCondition.projectId,
        status: safeCondition.status,
    });
};
exports.encodeCondition = encodeCondition;
var decodeShareCondition = function (shareCondition) {
    var safeShareCondition = (0, string_1.reversePostgresSafe)(shareCondition);
    return {
        accessLevel: safeShareCondition.access_level,
        email: safeShareCondition.email,
        firstName: safeShareCondition.first_name,
        id: safeShareCondition.share_condition_id,
        lastName: safeShareCondition.last_name,
        permitId: safeShareCondition.permit_id,
        projectId: safeShareCondition.project_id,
    };
};
exports.decodeShareCondition = decodeShareCondition;
var encodeShareCondition = function (shareCondition) {
    var safeShareCondition = (0, string_1.makePostgresSafe)(shareCondition);
    return (0, string_1.makePostgresSafe)({
        access_level: safeShareCondition.accessLevel,
        email: safeShareCondition.email,
        first_name: safeShareCondition.firstName,
        last_name: safeShareCondition.lastName,
        permit_id: safeShareCondition.permitId,
        project_id: safeShareCondition.projectId,
        share_condition_id: safeShareCondition.id,
    });
};
exports.encodeShareCondition = encodeShareCondition;
var decodeImportedConditionDiscipline = function (discipline) {
    switch (discipline) {
        case "Access":
            return condition_1.ConditionDiscipline.Access;
        case "Accounting":
            return condition_1.ConditionDiscipline.Accounting;
        case "Acoustics":
            return condition_1.ConditionDiscipline.Acoustics;
        case "Administration":
            return condition_1.ConditionDiscipline.Administration;
        case "Arborist":
            return condition_1.ConditionDiscipline.Arborist;
        case "Architectural":
            return condition_1.ConditionDiscipline.Architectural;
        case "BCA Consultant":
            return condition_1.ConditionDiscipline.BCAConsultant;
        case "Bushfire":
            return condition_1.ConditionDiscipline.Bushfire;
        case "Civil Engineering":
            return condition_1.ConditionDiscipline.Civil;
        case "Client / Owner":
            return condition_1.ConditionDiscipline.Client;
        case "Contamination":
            return condition_1.ConditionDiscipline.Contamination;
        case "Construction":
            return condition_1.ConditionDiscipline.Construction;
        case "Ecological":
            return condition_1.ConditionDiscipline.Ecological;
        case "Electrical":
            return condition_1.ConditionDiscipline.Electrical;
        case "Environmental":
            return condition_1.ConditionDiscipline.Environmental;
        case "ESD":
            return condition_1.ConditionDiscipline.ESD;
        case "Façade":
            return condition_1.ConditionDiscipline.Façade;
        case "Fire Engineering":
            return condition_1.ConditionDiscipline.FireEngineering;
        case "Fire Penetrations":
            return condition_1.ConditionDiscipline.FirePenetrations;
        case "Fire Protection":
            return condition_1.ConditionDiscipline.FireProtection;
        case "Geotechnical":
            return condition_1.ConditionDiscipline.Geotechnical;
        case "Heritage":
            return condition_1.ConditionDiscipline.Heritage;
        case "Hydraulic":
            return condition_1.ConditionDiscipline.Hydraulic;
        case "Interior Design":
            return condition_1.ConditionDiscipline.InteriorDesign;
        case "Landscaping":
            return condition_1.ConditionDiscipline.Landscaping;
        case "Legal":
            return condition_1.ConditionDiscipline.Legal;
        case "Lifts & Conveyors":
            return condition_1.ConditionDiscipline.LiftsConveyors;
        default:
            return condition_1.ConditionDiscipline.Other;
    }
};
exports.decodeImportedConditionDiscipline = decodeImportedConditionDiscipline;
