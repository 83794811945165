import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Skeleton, TableCell, TableRow } from "@mui/material";

export const CommentTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="circular">
          <IconButton color="error" size="large">
            <DeleteIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
