// third-party



import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { NavItemType } from "types";

export const getDashboardNav = (projectId?: string): NavItemType => {
  const theme = useTheme();
  return {
    children: [
      {
        icon: (
          <TrackChanges
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        ),
        id: "overview",
        title: "Project Dashboard",
        type: "item",
        url: projectId ? RouteHelper.overview(projectId) : "",
      },
    ],
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "project",
    title: "Overview",
    type: "group",
  };
};
