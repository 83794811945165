import { Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ChangeEvent, useState } from "react";

export interface RejectDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (rejectionMessage: string) => void;
}

export const RejectDialog = ({
  onCancel,
  onConfirm,
  open,
}: RejectDialogProps) => {
  const [rejectMessage, setRejectMessage] = useState<string>("");
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle>{"Reject Submission"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            {
              "This action will return the condition to the . All data currently entered will be saved as a draft."
            }
          </Typography>
          <StyledTextField
            data-test-id="hours-comments-input"
            variant="outlined"
            loading={false}
            disabled={false}
            rows={5}
            multiline
            id={"reject-message"}
            name={"recject-message"}
            label={"Reason"}
            fullWidth
            maxLength={300}
            margin="dense"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              setRejectMessage(e.target.value);
            }}
            value={rejectMessage}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <StyledButton
          id="confirm-dialog-button-cancel"
          loading={false}
          onClick={onCancel}
          variant="outlined"
        >
          {"Cancel"}
        </StyledButton>
        <StyledButton
          data-test-id="confirm-dialog-button-ok"
          loading={false}
          disabled={rejectMessage.length === 0}
          onClick={() => onConfirm(rejectMessage)}
          sx={{
            background: "#e30020 !important",
            color: "#fff",
          }}
        >
          {"Reject"}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
