import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aos from "aos";
import FooterLogo from "assets/images/foot-logo.png";
import instgram from "assets/images/instgram-logo.webp";
import linkedin from "assets/images/linkedin-logo.webp";
import LogoActive from "assets/images/LogoActive.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import "aos/dist/aos.css";

export const PrivacyPolicy = () => {
  const [IsSticky, setIsSticky] = useState(false);
  const [IsActive, setIsActive] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [menus, setMenus] = useState([
    {
      isOpen: false,
      items: [
        { label: "permit management", url: "/permit-management" },
        { label: "Communication portal", url: "/communication-portal" },
        { label: "document Control", url: "/document-control" },
        { label: "Reporting & Analytics", url: "/reporting-and-analytics" },
      ],
      label: "Solutions",
    },
    {
      isOpen: false,
      items: [
        { label: "Templates", url: "/templates" },
        { label: "News & Articles", url: "/blog" },
        { label: "API Integration", url: "/api-integration" },
      ],
      label: "Resources",
    },
    {
      isOpen: false,
      items: [
        { label: "Our Team", url: "/our-team" },
        { label: "Support", url: "/support" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Terms & Conditions ", url: "/terms-conditions" },
      ],
      label: "Company",
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[menuIndex].isOpen = !updatedMenus[menuIndex].isOpen;
      return updatedMenus;
    });
  };

  return (
    <>
      {/* header Section  */}
      <header
        className={IsSticky === true ? "active sub-header" : "sub-header"}
      >
        <div className="container-new">
          <div className="navigation">
            <a href="/" className="site-logo">
              <img src={LogoActive} alt="socialpro" />
              {/* {IsSticky === true ? (
                <img src={Logo} alt="socialpro" />
              ) : (
                <img src={LogoActive} alt="socialpro" />
              )} */}
            </a>
            <ul>
              <li>
                <span>Solutions</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/permit-management">permit management</Link>
                  </li>
                  <li>
                    <Link to="/communication-portal">Communication portal</Link>
                  </li>
                  <li>
                    <Link to="/document-control">document Control</Link>
                  </li>
                  <li>
                    <Link to="/reporting-and-analytics">
                      Reporting & Analytics
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Resources</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/templates">templates</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Articles</Link>
                  </li>
                  <li>
                    <Link to="/api-integration">API Integration</Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Company</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="head-btns">
              <a href="/request-demo" className="demo-btn">
                Request a Demo
              </a>
              <a href="/login" className="nav-login sub-head">
                <svg
                  data-bbox="0 0 50 50"
                  data-type="shape"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                  fill="#fff"
                >
                  <g>
                    <path
                      d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                      fill="#005CD1"
                    />
                  </g>
                </svg>
                <span>Login</span>
              </a>
            </div>

            {IsActive === false ? (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {/* Mob Navigation  */}

        <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
          <div className="mob-nav">
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo" className="demo-link">
                  Request a Demo
                </a>
                <a href="/login" className="nav-login">
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                        fill="#005CD1"
                      />
                    </g>
                  </svg>
                  <span>Login</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* secondary Hero section */}
      <div className=" bg-overlay sec-hero banner-pt terms-hero">
        <div className="container-new">
          <h3>LEGAL</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            Privacy Policy
          </h2>
          <p>JANUARY 2024</p>
        </div>
      </div>

      {/* text Section  */}
      <div className="definitions-section  bg-white ">
        <div className="container-new">
          <div className="def-txt" data-aos="fade-up" data-aos-duration="1000">
            <p>
              At PermitOne, we take data and its collection seriously, always
              working to ensure local laws are followed, our customers personal
              information is stored correctly nor distributed or sold outside
              the terms set below. If you have any questions about this Policy,
              including any requests to exercise your legal rights, please
              contact us by email at{" "}
              <a href="mailto:support@permitoneapp.com">
                support@permitoneapp.com
              </a>
              .
            </p>

            <p>
              We are strongly committed to the transparency of data collection
              and its appropriate use before, during and after obtaining your
              personal information. The policies outlined below are applicable
              to data and information collected through PermitOne and their
              related entities, whether that be obtained through their network
              of websites including PermitOneapp.com (including any optimised
              versions for mobile tablets or wireless viewing); any and all
              email newsletters published or distributed by PermitOne; all apps
              published by PermitOne, including the ‘PermitOne’ Web-App; or use
              any other services made available by PermitOne (&#34;Service&#34;)
              and all other interactive features and communications provided by
              PermitOne (&#34;Platform&#34;), however accessed and/or used, that
              are operated by us, made available by us, or produced and
              maintained by us and our related companies (collectively
              &#34;PermitOne Software Pty Ltd&#34; or &#34;PermitOne&#34;,
              &#34;we&#34;, &#34;us&#34;, or &#34;our&#34;).
            </p>

            <p>
              Please be advised that the practices described in this Privacy
              Policy apply to information gathered online through our Platform,
              through our websites and otherwise by our customer service
              personnel. It does not apply to information that you may submit to
              organisations to which we may link or who may link to us or
              information that we may receive about you from other
              organisations.
            </p>

            <p>
              If you are using our service, you are accepting the practices
              described in our Privacy Policy. If you do not agree to the terms
              of this Privacy Policy, please do not use the Platform. We reserve
              the right to modify or amend the terms of our Privacy Policy from
              time to time without notice. Your continued use of our Platform
              following the posting of changes to these terms will mean you
              accept those changes. If we intend to apply the modifications or
              amendments to this Privacy Policy retroactively or to personal
              information already in our possession, we will provide you with
              notice of the modifications or amendments.
            </p>

            <p>
              If you have any questions about this Privacy Policy or don’t see
              your concerns addressed here, please contact us by email at
              support@permitoneapp.com.This policy is effective from 1st
              December 2023.
            </p>

            <h4>Hosting</h4>
            <p>
              Our Server hosts, AWS, are located in Australia. As is true of
              most websites, we gather certain information automatically through
              your use of the Site. This information may include Internet
              protocol (IP) addresses, browser type, Internet service provider
              (ISP), referring or exit pages, the files viewed on the Site
              (e.g., HTML pages, graphics, etc.), operating system, date/time
              stamp, and clickstream data to analyze trends in the aggregate and
              administer the site, this data is saved into server logs. For more
              information, please see AWS’s privacy policy.
            </p>

            <h4>Google Analytics</h4>
            <p>
              When someone visits PermitOneapp.com we use a third party service,
              Google Analytics, to collect standard internet log information and
              details of visitor behaviour patterns. We do this to find out
              things such as the number of visitors to the various parts of the
              site. This information does not identify anyone and we don’t use
              any method that would allow ourselves or Google to work out the
              identities of those visiting our website. If we do want to collect
              personally identifiable information through our website, we will
              be up front about this. We will make it clear when we collect
              personal information and will explain what we intend to do with
              it.
            </p>

            <h4>What we Collect</h4>
            <p>We may collect the following information:</p>

            <ul>
              <li>Name, job title and address;</li>
              <li>Contact information including email address;</li>
              <li>
                Demographic information such as postcode, preferences and
                interests;
              </li>
              <li>
                Other information relevant to customer surveys and/or offers.
              </li>
            </ul>

            <p>
              If you contact us about a trade account then we will also collect
              details about your business and your role in it for example, if
              you are a partnership we will require details about your business
              interest and may also require details about your business
              partners. To open an account you will also need to provide us with
              financial information such as bank account details and credit
              history information and will also be required to provide us with
              details of trade referees. This is all information which we may
              retain on our records for future reference.
            </p>

            <p>
              If you contact us through this website (including if you complete
              a contact form), by telephone, email or if you write to us, the
              content of those communications may also include personal data and
              we may also keep a copy of that correspondence on our records.
            </p>

            <p>
              If you provide us with information about any other person, for
              example any other individuals within your organisation or your
              business partners, then you must ensure that you have consent to
              pass those details to us and for us to use those details in
              accordance with this privacy policy. You should also make sure
              that you bring this privacy policy to their attention.
            </p>

            <h4>What we do with the information we gather</h4>

            <p>
              We require this information for the proper administration of our
              business, to understand your needs and to provide you with a
              better service, and in particular for the following reasons:
            </p>

            <ul>
              <li>
                {" "}
                Account management and dealing with invoicing arrangements;
              </li>
              <li>Internal record keeping;</li>
              <li>
                We may use the information to improve our products and services;
              </li>
              <li>
                {" "}
                We may periodically send promotional email about new products,
                special offers or other information which we think you may find
                interesting using the email address which you have provided;
              </li>
              <li>
                {" "}
                From time to time, we may also use your information to contact
                you for market research purposes. We may contact you by email,
                phone, or mail. We may use the information to customise the
                website according to your interests.
              </li>
            </ul>

            <p>
              If at any time you wish to stop receiving the type of information
              referred to in (d) and (e) above then you can contact us at any
              time, using the contact details shown below.
            </p>
            <p>
              We might also use your personal details to deal with any enquiries
              or complaints which you may raise or if you report any problems
              with our site and to contact you and deal with those issues as
              appropriate.
            </p>

            <p>
              We may also use your information for other business purposes such
              as market research activities that we may undertake on occasion.
              This may also involve the collection of survey information,
              involving opinions on our products or may include technical
              information such as Internet Protocol (IP) address information
              used to connect your computer to the Internet or traffic data
              about visits to our website and the products searched for or
              viewed.
            </p>

            <h4>Disclosure of data to third parties</h4>

            <p>
              From time to time we might also appoint external third parties to
              help us with our business functions for example, external
              consultants and sub-contractors. These might include delivery
              contractors, marketing consultants or companies who help us to
              manage our website. We therefore, may make your data available to
              those entities if reasonable to do so as part of our usual
              business practices. When we make your personal data available to
              such third parties for those purposes, we will only make available
              to them the data that they need to perform their obligations to
              us.
            </p>

            <p>
              If you are an account holder and customer, we will disclose your
              data to third party credit reference agencies and to any trade
              referees who you may refer us to so that we can take up
              appropriate references and perform necessary checks. We will keep
              the details of such checks on our records and may also disclose
              those details to third parties for the purpose of ongoing risk
              assessment.
            </p>

            <p>
              We will never disclose any personal data to a third party that has
              been directly inputted into your organisation login instance of
              PermitOne, i.e. any personal beneficiary and/or survey data or any
              other data you may directly enter into our Platform.
            </p>
            <h4>Data breaches</h4>

            <p>
              In the event that we discover or suspect a data breach we will
              notify you as soon as we are able to provide sufficient
              information as to the nature and extent of the breach. The notice
              will include, where available at the time of sending, the
              following:
            </p>

            <ul>
              <li>The dates and times of the security event</li>
              <li>The details of the event including:</li>
              <li>A description of the data involved in the event</li>
              <li>
                The facts of the event or details of the decision to investigate
                a suspected event
              </li>
              <li>
                The steps we are taking or planning to take to remedy or
                mitigate the vulnerability.
              </li>
            </ul>

            <h4>Links to other websites</h4>
            <p>
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </p>

            <h4>Controlling your personal information</h4>

            <p>
              If you do not wish us to collect and use your personal data as set
              out in this privacy policy then you should refrain from sending
              any such information to us. By providing information to us, you
              will be taken to have given your consent to the use of your
              personal information in accordance with this privacy policy.
            </p>

            <p>
              You may instruct us not to process your personal data for
              marketing purposes by email at any time.
            </p>

            <p>
              If you believe that any information we are holding on you is
              incorrect or incomplete, please write to or email us as soon as
              possible, at the above address. We will promptly correct any
              information found to be incorrect.
            </p>

            <h4>Our rights</h4>
            <p>
              We may disclose information about you to any of our employees,
              officers, agents, suppliers or subcontractors insofar as
              reasonably necessary for the purposes as set out in this privacy
              policy.
            </p>

            <p>In addition, we may disclose information about you:</p>

            <ul>
              <li> To the extent that we are required to do so by law;</li>
              <li>
                In connection with any legal proceedings or prospective legal
                proceedings;
              </li>
              <li>
                {" "}
                In order to establish, exercise or defend our legal rights
                (including providing information to others for the purposes of
                fraud prevention and reducing credit risk);
              </li>
              <li>
                {" "}
                To the purchaser (or prospective purchaser) of any business or
                asset which we are (or are contemplating) selling.
              </li>
            </ul>

            <p>
              We may also monitor user and network traffic for site security
              purposes and may use any information, including personal data to
              prevent any unauthorised attempts to tamper with our website or
              cause damage to our property. We also reserve our rights to
              disclose your personal information either where you have given us
              explicit consent to do so or otherwise where we may do so in
              accordance with applicable data protection legislation.
            </p>

            <p>
              Except as provided in this privacy policy, we will not provide
              your information to third parties.
            </p>

            <h4>Security of your personal data</h4>

            <p>
              We are committed to ensuring that your information is secure. We
              will take reasonable technical and organisational precautions to
              prevent the loss, misuse or alteration of your personal
              information including putting in place suitable physical,
              electronic and managerial procedures to safeguard and secure the
              information we collect online.
            </p>

            <p>
              We will store all the personal information you provide on our
              secure (password- and firewall- protected) servers. All electronic
              transactions you make to or receive from us will be encrypted
              using SSL technology.
            </p>

            <p>
              However, data transmission over the internet is inherently
              insecure, and we cannot guarantee the security of data sent over
              the internet.
            </p>

            <p>
              You are responsible for keeping your password and user details
              confidential. We will not ask you for your password at any time.
            </p>

            <h4>Cookies</h4>
            <p>
              This website uses cookies. You can find out more information about
              cookies and the way in which we use them in our Cookies Policy
              which you can access Here
            </p>

            <h4>Changes to this policy</h4>
            <p>
              We may update this privacy policy from time-to-time by posting a
              new version on our website. You should check this page
              occasionally to ensure you are happy with any changes.
            </p>

            <p>
              We may also notify you of changes to our privacy policy by email.
            </p>

            <h4>Severability</h4>
            <p>
              If a provision of this Privacy Policy is determined by any court
              or other competent authority to be unlawful and/or unenforceable,
              the other provisions will continue in effect. If any unlawful
              and/or unenforceable provision would be lawful or enforceable if
              part of it were deleted, that part will be deemed to be deleted,
              and the rest of the provision will continue in effect.
            </p>

            <h4>Contact</h4>
            <p>
              If you have any questions about this privacy policy or our
              treatment of your personal data, please write to us by email to{" "}
              <a href="mailto:support@permitoneapp.com">
                support@permitoneapp.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <footer className="darkblue-bg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social mob-hide">
              <ul className="social-links">
                <li>
                  <a
                    href=" https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
          <div className="footer-links">
            <h4>SOLUTION</h4>
            <ul>
              <li>
                <Link to="/permit-management">permit management</Link>
              </li>
              <li>
                <Link to="/communication-portal">Communication portal</Link>
              </li>
              <li>
                <Link to="/document-control">document Control</Link>
              </li>
              <li>
                <Link to="/reporting-and-analytics">Reporting & Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>RESOURCES</h4>
            <ul>
              <li>
                <Link to="/request-demo">Request a demo</Link>
              </li>
              <li>
                <Link to="/templates">TEMPLATES</Link>
              </li>
              <li>
                <Link to="/blog">News & Articles</Link>
              </li>
              <li>
                <Link to="/api-integration">API Integration</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
            <div className="footer-social desk-hide">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/permit.one/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br />
                SOFTWARE PTY LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
