import { Container, styled } from "@mui/material";
import ForgotPasswordForm from "@stories/organisms/ForgotPasswordForm/ForgotPasswordForm";
import ForgotPasswordSubmitForm from "@stories/organisms/ForgotPasswordSubmitForm/ForgotPasswordSubmitForm";
import logo from "assets/images/LogoActive.png";
import { useState } from "react";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "auto",
  maxWidth: 480,
  padding: theme.spacing(12, 0),
}));

const ForgotPassword = () => {
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  return (
    <StyledRoot
      className="loginform"
      style={{
        alignItems: "center",
        background:
          " linear-gradient(90deg, rgba(0, 32, 71, 1) 0%, rgba(0, 92, 209, 1) 61%)",
        display: "flex",
        height: "100vh",
        padding: "40px",
        position: "relative",
      }}
    >
      <Container
        style={{
          background: "white",
          borderRadius: "10px",
          maxWidth: "475px",
        }}
      >
        <StyledContent style={{ padding: "40px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ margin: "0 auto", maxWidth: "210px" }}
          />
          {showEnterCode && emailAddress ? (
            <ForgotPasswordSubmitForm emailAddress={emailAddress} />
          ) : (
            <ForgotPasswordForm
              setShowEnterCode={setShowEnterCode}
              setEmailAddress={setEmailAddress}
            />
          )}
        </StyledContent>
      </Container>
    </StyledRoot>
  );
};

export default ForgotPassword;
