import { Drawer } from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { drawerWidth } from "constant";

const openedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.background.default,
  borderRight: "none",
  boxShadow: theme.palette.mode === "dark" ? theme.customShadows.z1 : "none",
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen + 200,
    easing: theme.transitions.easing.sharp,
  }),
  width: drawerWidth,
  zIndex: 1099,
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.background.default,
  borderRight: "none",
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen + 200,
    easing: theme.transitions.easing.sharp,
  }),
  width: 72,
  zIndex: 1099,
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open, theme }) => ({
  borderRight: "0px",
  boxSizing: "border-box",
  flexShrink: 0,
  whiteSpace: "nowrap",
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default MiniDrawerStyled;
