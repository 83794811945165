import { Avatar, Skeleton, Stack, TableCell, TableRow } from "@mui/material";

export const DirectoryTableRowSkeleton = () => {
  return (
    <TableRow hover tabIndex={-1} role="checkbox">
      <TableCell align="center" sx={{ width: "20%" }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton animation="wave" variant="circular">
            <Avatar alt={""} src={""} />
          </Skeleton>
          <Skeleton animation="wave" width={100} />
        </Stack>
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
