import { useProfileContext } from "@hooks/context/useProfileContext";
import { useGlobalDirectory } from "@hooks/crud/useGlobalDirectory";
import { Box } from "@mui/material";
import { PageHeaderWithIcon } from "@stories/organisms/PageHeaderWithIcon/PageHeaderWithIcon";
import { GlobalDirectoryTable } from "@stories/organisms/Tables/GlobalDirectoryTable/GlobalDirectoryTable";

export const GlobalDirectory = (): JSX.Element => {
  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const { directory, isDirectoryLoading } = useGlobalDirectory();

  const loading = isAuthProfileLoading || isDirectoryLoading;
  return (
    <Box>
      <PageHeaderWithIcon
        loading={loading}
        title="Global Directory"
        userProfile={userProfile}
      />
      <Box sx={{ padding: "0 40px 40px" }}>
        <GlobalDirectoryTable loading={loading} directory={directory} />
      </Box>
    </Box>
  );
};
