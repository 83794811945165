import { Order } from "@hooks/utils/useTable";
import { Table, TableBody, TableContainer, Paper } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import {
  ConditionLineItem,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";

import PublicEditableTableRow from "./PublicEditableTableRow";
import EditableTableRowSkeleton from "./PublicEditableTableRowSkeleton";

interface PublicEditableTableProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  documents: ConditionDocumentLineItem[];
  comments: ConditionCommentLineItem[];
  order: Order;
  orderBy: number;
  selected: string[];
  canEdit: boolean;
  handleRequestSort: (property: number) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteComment: (comment: ConditionCommentLineItem) => void;
  sendForReview: (condition: ConditionLineItem) => void;
  uploadDocument: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
}

function PublicEditableTable({
  canEdit,
  comments,
  conditions,
  createComments,
  createDocuments,
  deleteComment,
  deleteDocument,
  disabled,
  documents,
  handleEditCondition,
  handleRequestSort,
  loading,
  order,
  orderBy,
  permit,
  project,
  selected,
  sendForReview,
  shareCondition,
  updateDocuments,
  uploadDocument,
}: PublicEditableTableProps) {
  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: true, id: "item", label: "Item" },
    { alignRight: true, id: "details", label: "Details" },
    { alignRight: true, id: "comments", label: "Comments" },
    { alignRight: true, id: "discipline", label: "Discipline" },
    { alignRight: true, id: "manager-notes", label: "Manager Notes" },
    { alignRight: false, id: "status", label: "Status" },
    { alignRight: false, id: "actions", label: "Actions", static: true },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={conditions.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={() => console.log("Not implemented")}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />
        <TableBody>
          {loading
            ? Array.from(new Array(10)).map((_, index) => (
                <EditableTableRowSkeleton key={index} />
              ))
            : null}
          {!loading && permit && project && shareCondition
            ? conditions.map((row) => {
                const documentsForCondition = documents.filter(
                  (d) => d.conditionId == row.id
                );
                const commentsForCondition = comments.filter(
                  (c) => c.conditionId == row.id
                );
                return (
                  <PublicEditableTableRow
                    key={row.id}
                    disabled={disabled}
                    condition={row}
                    loading={loading}
                    project={project}
                    permit={permit}
                    canEdit={canEdit}
                    comments={commentsForCondition}
                    documents={documentsForCondition}
                    createDocuments={createDocuments}
                    createComments={createComments}
                    sendForReview={sendForReview}
                    conditions={conditions}
                    handleEditCondition={handleEditCondition}
                    uploadDocument={uploadDocument}
                    deleteComment={deleteComment}
                    deleteDocument={deleteDocument}
                    updateDocuments={updateDocuments}
                    shareCondition={shareCondition}
                  />
                );
              })
            : null}
          {!loading && conditions.length === 0 ? (
            <NoRows title={"Conditions"} colSpan={7} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PublicEditableTable;
