import { Link } from "@mui/material";
import Logo from "@stories/ui-component/Logo";
import { DASHBOARD_PATH } from "config";
import { Link as RouterLink } from "react-router-dom";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="theme-logo">
    <Logo />
  </Link>
);

export default LogoSection;
