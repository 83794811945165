import { useProject } from "@hooks/crud/useProject";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useProfileContext } from "./useProfileContext";

type PC = {
  isProjectLoading: boolean;
  error: string | null;
  selectedProject?: ProjectLineItem;
  allProjects: ProjectLineItem[];
  setSelectedProject: (project: ProjectLineItem) => void;
  getProject: (id: string) => void;
  createProject: (project: ProjectLineItem) => Promise<void>;
  updateProject: (project: ProjectLineItem) => Promise<void>;
  deleteProject: (project: ProjectLineItem) => Promise<void>;
};

export const ProjectContext = React.createContext<PC>({
  allProjects: [],
  createProject: () => Promise.resolve(),
  deleteProject: () => Promise.resolve(),
  error: null,
  getProject: () => Promise.resolve(),
  isProjectLoading: true,
  selectedProject: undefined,
  setSelectedProject: () => Promise.resolve(),
  updateProject: () => Promise.resolve(),
});

type ProjectProviderProps = {
  children: React.ReactNode;
};

const ProjectProvider = (props: ProjectProviderProps) => {
  const { projectId } = useParams();
  if (!projectId) {
    throw Error("Project id is required");
  }
  const { userOrganisation, userProfile } = useProfileContext();
  if (!userProfile) {
    throw Error("User profile is required");
  }
  const {
    createProject,
    deleteProject,
    error,
    getProject,
    isProjectLoading,
    projects,
    selectedProject,
    setSelectedProject,
    updateProject,
  } = useProject(userProfile);

  React.useEffect(() => {
    if (projectId && userOrganisation) {
      getProject(projectId);
    }
  }, [projectId, userOrganisation]);

  return (
    <ProjectContext.Provider
      value={{
        allProjects: projects,
        createProject,
        deleteProject,
        error: error,
        getProject,
        isProjectLoading,
        selectedProject: selectedProject,
        setSelectedProject,
        updateProject,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

const useProjectContext = () => React.useContext(ProjectContext);

export { ProjectProvider, useProjectContext };
