import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  TableCell,
  TableRow,
  Skeleton,
  Button,
  Chip,
  IconButton,
} from "@mui/material";

function EditableTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell sx={{ width: "5%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "15%" }}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto", width: "80%" }}>
          <Chip label="Not Assigned" />
        </Skeleton>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ margin: "auto", width: "100%" }}
        >
          <Button>Button</Button>
        </Skeleton>
      </TableCell>
      <TableCell align="right" sx={{ width: "5%" }}>
        <Skeleton
          variant="circular"
          animation="wave"
          sx={{ marginLeft: "auto" }}
        >
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
}

export default EditableTableRowSkeleton;
