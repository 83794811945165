import FolderCopy from "@mui/icons-material/FolderCopy";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { NavItemType } from "types";

export const getProjectsNav = (): NavItemType => {
  const theme = useTheme();
  return {
    children: [
      {
        breadcrumbs: false,
        icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
        id: "list-projects",
        title: "All Projects",
        type: "item",
        url: RouteHelper.listProjects,
      },
    ],
    icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
    id: "projects",
    title: "Projects",
    type: "group",
  };
};
