import {
  Box,
  Button,
  Chip,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";

export const TaskTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell align="center">
          <Skeleton animation="wave" variant="rounded">
            <Chip variant="outlined" label="Status" />
          </Skeleton>
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            gap="5px"
            justifyContent={"right"}
            height={"37px"}
          >
            <Skeleton animation="wave" variant="rounded">
              <Button
                color="primary"
                className="blackBtn"
                sx={{
                  "&:hover ": {
                    background: "#e3f2fd",
                  },
                  borderColor: "#0063c6",
                  color: "#0063c6",
                }}
                size="large"
               />
            </Skeleton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
