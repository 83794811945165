"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProject = exports.decodeProject = void 0;
var organisation_1 = require("./organisation");
var profile_1 = require("./profile");
var string_1 = require("../utils/string");
var decodeProject = function (project, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var safeProject, collaborators;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                safeProject = (0, string_1.reversePostgresSafe)(project);
                return [4 /*yield*/, Promise.all(project.collaborators.map(function (c) { return (0, profile_1.decodeProfile)(c, getImageUrl); }))];
            case 1:
                collaborators = _a.sent();
                return [2 /*return*/, {
                        activePermits: safeProject.active_permits,
                        archived: safeProject.archived,
                        archivedPermits: safeProject.archived_permits,
                        collaborators: collaborators,
                        completionDate: safeProject.completion_date
                            ? new Date(safeProject.completion_date)
                            : undefined,
                        contractor: safeProject.contractor,
                        country: safeProject.country,
                        created: new Date(safeProject.created),
                        developer: safeProject.developer,
                        dpsMpsNumber: safeProject.dps_mps_number,
                        estimatedProgress: 0,
                        estimatedProjectCost: safeProject.estimated_project_cost,
                        id: safeProject.project_id,
                        inReviewPermits: safeProject.in_review_permits,
                        lotNumber: safeProject.lot_number,
                        organisationId: safeProject.organisation_id,
                        owner: safeProject.owner
                            ? (0, organisation_1.decodeOrganisation)(safeProject.owner)
                            : undefined,
                        postCode: safeProject.post_code,
                        projectCode: safeProject.project_code,
                        projectDescription: safeProject.project_description,
                        projectName: safeProject.project_name,
                        projectSector: safeProject.project_sector,
                        projectSectorOther: safeProject.project_sector_other,
                        startDate: safeProject.start_date
                            ? new Date(safeProject.start_date)
                            : undefined,
                        state: safeProject.state,
                        streetName: safeProject.street_name,
                        streetNumber: safeProject.street_number,
                        suburb: safeProject.suburb,
                        totalPermits: safeProject.total_permits,
                    }];
        }
    });
}); };
exports.decodeProject = decodeProject;
var encodeProject = function (project) {
    var safeProject = (0, string_1.makePostgresSafe)(project);
    var collaborators = project.collaborators.map(function (c) { return (0, profile_1.encodeProfile)(c); });
    return {
        active_permits: safeProject.activePermits,
        archived: safeProject.archived,
        archived_permits: safeProject.archivedPermits,
        collaborators: collaborators,
        completion_date: safeProject.completionDate,
        contractor: safeProject.contractor,
        country: safeProject.country,
        created: safeProject.created,
        developer: safeProject.developer,
        dps_mps_number: safeProject.dpsMpsNumber,
        estimated_project_cost: safeProject.estimatedProjectCost,
        in_review_permits: safeProject.inReviewPermits,
        lot_number: safeProject.lotNumber,
        organisation_id: safeProject.organisationId,
        owner: safeProject.owner
            ? (0, organisation_1.encodeOrganisation)(safeProject.owner)
            : undefined,
        post_code: safeProject.postCode,
        project_code: safeProject.projectCode,
        project_description: safeProject.projectDescription,
        project_id: safeProject.id,
        project_name: safeProject.projectName,
        project_sector: safeProject.projectSector,
        project_sector_other: safeProject.projectSectorOther,
        start_date: safeProject.startDate,
        state: safeProject.state,
        street_name: safeProject.streetName,
        street_number: safeProject.streetNumber,
        suburb: safeProject.suburb,
        total_permits: safeProject.totalPermits,
    };
};
exports.encodeProject = encodeProject;
