"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRichTextJsonToHTML = exports.formatText = exports.formatHtmlText = exports.isRichText = exports.isHyperLink = void 0;
var isHyperLink = function (value) {
    return Boolean(value && value.hyperlink);
};
exports.isHyperLink = isHyperLink;
var isRichText = function (value) {
    return Boolean(value && Array.isArray(value.richText));
};
exports.isRichText = isRichText;
var formatHtmlText = function (value) {
    if ((0, exports.isRichText)(value)) {
        return richToHtml(value);
    }
    else if (Array.isArray(value)) {
        return value
            .map(function (v) { return ((0, exports.isHyperLink)(v) ? v.text : v); })
            .reduce(function (acc, v) {
            return acc + "<p>".concat(v, "</p>");
        }, "");
    }
    return "<p>".concat(value, "</p>");
};
exports.formatHtmlText = formatHtmlText;
var formatText = function (value) {
    if ((0, exports.isRichText)(value)) {
        return richToHtml(value);
    }
    else if (Array.isArray(value)) {
        return value.map(function (v) { return ((0, exports.isHyperLink)(v) ? v.text : v); }).join(", ");
    }
    return value;
};
exports.formatText = formatText;
var richToHtml = function (rich) {
    var str = rich.richText
        .map(function (_a) {
        var font = _a.font, text = _a.text;
        return [
            font.bold ? "<strong>" : "",
            font.italic ? "<em>" : "",
            text,
            font.italic ? "</em>" : "",
            font.bold ? "</strong>" : "",
        ].join("");
    })
        .join("");
    // simple tag combining where possible
    return str.replace(/<\/strong><strong>/g, "").replace(/<\/em><em>/g, "");
};
var parseRichTextJsonToHTML = function (json) {
    return richToHtml(json);
};
exports.parseRichTextJsonToHTML = parseRichTextJsonToHTML;
