import { Grid, Stack } from "@mui/material";
import EditableTable from "@stories/atoms/EditableTable/EditableTable";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";
import ConditionTableToolbar from "@stories/molecules/ListTable/ConditionTableToolbar";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ChangeEvent, useEffect, useState } from "react";

interface ConditionTableProps {
  loading: boolean;
  disabled: boolean;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  handleOpenConditionDocumentModal: (condition: ConditionLineItem) => void;
  handleOpenConditionEditModal: () => void;
  setConditions: (conditions: ConditionLineItem[]) => void;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleViewCondition: (condition: ConditionLineItem) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleShareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accessLevel: ShareConditionAccessLevel
  ) => Promise<void>;
  showToolbar?: boolean;
}
export const ConditionTable = ({
  conditions,
  disabled,
  handleDeleteCondition,
  handleEditCondition,
  handleImportConditons,
  handleOpenConditionDocumentModal,
  handleOpenConditionEditModal,
  handleShareConditions,
  loading,
  permit,
  profile,
  project,
  showToolbar = true,
  userOrganisation,
}: ConditionTableProps) => {
  const params = new URLSearchParams(location.search);
  const initialConditionStatus = parseInt(params.get("status") || "0");

  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<number>(initialConditionStatus);

  const [filterName, setFilterName] = useState<string>("");

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  };

  useEffect(() => {
    setSelectedConditionStatus(initialConditionStatus);
  }, [initialConditionStatus]);

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedConditionStatus(newValue);
  };

  // const isNotFound = !sortedConditions.length && !!filterName;

  // const isProjectOwner =
  //   userOrganisation && project && project.owner?.id === userOrganisation.id;
  // const isPermitManager =
  //   profile &&
  //   permit &&
  //   permit.managers.map((pm) => pm.id).includes(profile.id);

  // const canEdit = isProjectOwner || isPermitManager;

  const bcaConditions = conditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.BCA
  );
  const daConditions = conditions.filter(
    (c) => c.conditionAuthority === ConditionAuthority.DA
  );

  return (
    <>
      {showToolbar ? (
        <ConditionTableToolbar
          loading={loading}
          disabled={disabled}
          profile={profile}
          userOrganisation={userOrganisation}
          project={project}
          permit={permit}
          handleOpenConditionEditModal={handleOpenConditionEditModal}
          filterName={filterName}
          handleImportConditons={handleImportConditons}
          onFilter={handleFilterByName}
          handleShareConditions={handleShareConditions}
          conditions={conditions}
        />
      ) : null}
      <Grid item md={12} sx={{ padding: "30px 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={handleConditionStatusChange}
        />
      </Grid>
      <Stack spacing={2}>
        <EditableTable
          loading={loading}
          disabled={disabled}
          filterName={filterName}
          project={project}
          userOrganisation={userOrganisation}
          permit={permit}
          conditions={bcaConditions}
          handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
          handleOpenConditionEditModal={handleOpenConditionEditModal}
          handleEditCondition={handleEditCondition}
          handleDeleteCondition={handleDeleteCondition}
          selectedConditionStatus={selectedConditionStatus}
          selected={[]}
          title={"BCA Conditions"}
        />
        <EditableTable
          loading={loading}
          disabled={disabled}
          filterName={filterName}
          project={project}
          userOrganisation={userOrganisation}
          permit={permit}
          conditions={daConditions}
          handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
          handleOpenConditionEditModal={handleOpenConditionEditModal}
          handleEditCondition={handleEditCondition}
          handleDeleteCondition={handleDeleteCondition}
          selectedConditionStatus={selectedConditionStatus}
          selected={[]}
          title={"DA Conditions"}
        />
      </Stack>
    </>
  );
};
