import { Typography } from "@mui/material";

import { getNavMenuItems } from "../menu-items";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

interface MenuListProps {
  loading: boolean;
  accessId: string;
  isDrawOpen: boolean;
  selectedItem: string[];
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
}
const MenuList = ({
  accessId,
  isDrawOpen,
  loading,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: MenuListProps) => {
  // const getMenu = Menu();
  const menuItem = getNavMenuItems(accessId);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            loading={loading}
            key={`nav-bar-item-${item.id}`}
            item={item}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
