export interface PermitMultiProgressBarProps {
  loading: boolean;
  assigned: number;
  assignedText: string;
  archived: number;
  archivedText: string;
  approved: number;
  approvedText: string;
  notApplicable?: number;
  notApplicableText?: string;
  notAssigned?: number;
  notAssignedText?: string;
}
export const PermitMultiProgressBar = ({
  approved,
  approvedText,
  archived,
  archivedText,
  assigned,
  assignedText,
  loading,
  notApplicable,
  notApplicableText,
  notAssigned,
  notAssignedText,
}: PermitMultiProgressBarProps) => {
  let total = assigned + archived + approved;
  if (notAssigned !== undefined) {
    total += notAssigned;
  } else if (notApplicable !== undefined) {
    total += notApplicable;
  }

  const assignedPercentage = (assigned / total) * 100;
  const archivedPercentage = (archived / total) * 100;
  const approvedPercentage = (approved / total) * 100;
  const notApplicablePercentage = notApplicable
    ? (notApplicable / total) * 100
    : 0;
  //const notAssignedPercentage = notAssigned ? (notAssigned / total) * 100 : 0;

  if (loading) {
    return (
      <div style={{ width: "538px" }}>
        <div className="progress">
          <div className="progress-bar progress-bar-ruby" role="progressbar" />
        </div>
      </div>
    );
  }
  return (
    <div style={{ width: "538px" }}>
      <div className="progress">
        {assignedPercentage ? (
          <div
            className="progress-bar progress-bar-ruby"
            role="progressbar"
            style={{ background: "#016BE2", width: `${assignedPercentage}%` }}
          />
        ) : undefined}
        {archivedPercentage > 0 ? (
          <div
            className="progress-bar progress-bar-css"
            role="progressbar"
            style={{ background: "#E10023", width: `${archivedPercentage}%` }}
          />
        ) : undefined}
        {approvedPercentage > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{ background: "#03A742", width: `${approvedPercentage}%` }}
          />
        ) : undefined}

        {notApplicable !== undefined && notApplicable > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{
              background: "#A9A9A9",
              width: `${notApplicablePercentage}%`,
            }}
          />
        ) : undefined}

        {/*{notAssigned !== undefined && notAssigned > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{
              width: `${notAssignedPercentage}%`,
              background: "#F26602",
            }}
          ></div>
        ) : undefined}*/}
      </div>

      <div
        className="progress-bar-labels"
        style={{
          columnGap: "10px",
          display: "flex",
          flexWrap: "wrap",
          margin: "10px 0 0 auto",
          marginTop: "10px",
          maxWidth: "493px",
          rowGap: "5px",
        }}
      >
        <div
          className="prog-bar-box"
          style={{
            alignItems: "center",
            display: "flex",
            gap: "4px",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              background: " #016BE2",
              borderRadius: "50%",
              display: "inline-block",
              height: "10px",
              width: "10px",
            }}
          />
          <span className="prog-bar-label">{assignedText}</span>
          <span className="prog-bar-percentage">({assigned})</span>
        </div>
        <div
          className="prog-bar-box"
          style={{
            alignItems: "center",
            display: "flex",
            gap: "4px",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              background: "#E10023",
              borderRadius: "50%",
              display: "inline-block",
              height: "10px",
              width: "10px",
            }}
          />
          <span className="prog-bar-label">{archivedText}</span>
          <span className="prog-bar-percentage">({archived})</span>
          <div
            className="prog-bar-box"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "4px",
            }}
          >
            <span
              className="prog-bar-dot"
              style={{
                background: "#03A742",
                borderRadius: "50%",
                display: "inline-block",
                height: "10px",
                width: "10px",
              }}
            />
            <span className="prog-bar-label">{approvedText}</span>
            <span className="prog-bar-percentage">({approved})</span>
          </div>
        </div>

        {notApplicable !== undefined ? (
          <div
            className="prog-bar-box"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "4px",
            }}
          >
            <span
              className="prog-bar-dot"
              style={{
                background: "#A9A9A9",
                borderRadius: "50%",
                display: "inline-block",
                height: "10px",
                width: "10px",
              }}
            />
            <span className="prog-bar-label">{notApplicableText}</span>
            <span className="prog-bar-percentage">({notApplicable})</span>
          </div>
        ) : undefined}

        {notAssigned !== undefined ? (
          <div
            className="prog-bar-box"
            style={{
              alignItems: "center",
              display: "flex",
              gap: "4px",
            }}
          >
            <span
              className="prog-bar-dot"
              style={{
                background: "#f26602",
                borderRadius: "50%",
                display: "inline-block",
                height: "10px",
                width: "10px",
              }}
            />
            <span className="prog-bar-label">{notAssignedText}</span>
            <span className="prog-bar-percentage">({notAssigned})</span>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
