import { listApiData, postApiData, postBatchApiData } from "@hooks/utils/api";
import {
  decodeConditionComment,
  encodeConditionComment,
} from "permit-one-common/src/decoders/conditionComment";
import { ConditionComment } from "permit-one-common/src/entities/conditionComment";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import * as React from "react";

export const useConditionComment = (conditionId?: string) => {
  const [comments, setComments] = React.useState<ConditionCommentLineItem[]>(
    []
  );
  const [isCommentsLoading, setIsCommentsLoading] =
    React.useState<boolean>(true);
  const [isCommentsUpdating, setIsCommentsUpdating] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const listConditionComments = async (conditionId: string): Promise<void> => {
    try {
      setIsCommentsLoading(true);
      const commentResult = await listApiData(
        "listConditionComments",
        "conditionComment",
        conditionId
      );
      const documentLineItems = await Promise.all(
        commentResult.data.map((c) =>
          decodeConditionComment(c as ConditionComment)
        )
      );
      setComments(documentLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsCommentsLoading(false);
    }
  };

  const createComments = async (
    commentsToCreate: ConditionCommentLineItem[]
  ): Promise<void> => {
    try {
      setIsCommentsUpdating(true);

      const encodedComments = commentsToCreate.map((d) =>
        encodeConditionComment(d)
      );
      const response = await postBatchApiData(
        "upsertConditionComments",
        "conditionComment",
        encodedComments
      );
      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionComment(d as never as ConditionComment)
        )
      );

      setComments([...comments, ...decodedDocuments]);
    } catch (e: any) {
      console.log(e);
      setError("Could not create comments");
    } finally {
      setIsCommentsUpdating(false);
    }
  };

  const updateComments = async (
    commentsToCreate: ConditionCommentLineItem[]
  ): Promise<void> => {
    try {
      setIsCommentsUpdating(true);

      const encodedComments = commentsToCreate.map((d) =>
        encodeConditionComment(d)
      );
      const response = await postBatchApiData(
        "upsertConditionComments",
        "conditionComment",
        encodedComments
      );
      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionComment(d as never as ConditionComment)
        )
      );
      setComments(
        comments.map((c) => {
          const matchingComment = decodedDocuments.find((d) => d.id === c.id);
          return matchingComment ? matchingComment : c;
        })
      );
    } catch (e: any) {
      setError("Could not update comments");
    } finally {
      setIsCommentsUpdating(false);
    }
  };

  const deleteComment = async (
    comment: ConditionCommentLineItem
  ): Promise<void> => {
    try {
      setIsCommentsLoading(true);
      await postApiData(
        "deleteConditionDocument",
        "conditionComment",
        encodeConditionComment(comment)
      );
      setComments(comments.filter((p) => p.id !== comment.id));
    } catch (e: any) {
      setError("Could not delete document");
    } finally {
      setIsCommentsLoading(false);
    }
  };

  React.useEffect(() => {
    if (conditionId) {
      listConditionComments(conditionId);
    }
  }, [conditionId]);

  return {
    comments,
    createComments,
    deleteComment,
    error,
    isCommentsLoading,
    isCommentsUpdating,
    updateComments,
  };
};
