import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  ConditionLineItem,
  createDefaultCondition,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { convertWordToHtml, extractTablesFromHtml } from "permit-one-common/src/utils/html";
import { useRef, useState } from "react";

interface ConditionImportProps {
  loading: boolean;
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  bcaCount: number;
  daCount: number;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  setOpen: (open: boolean) => void;
}

enum ImportStep {
  Init = "Init",
  Parse = "Parse",
  Upload = "Upload",
}

export default function ConditionImport({
  bcaCount,
  daCount,
  handleImportConditons,
  loading,
  open,
  permit,
  project,
  setOpen,
}: ConditionImportProps) {
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      setStep(ImportStep.Parse);

      try {
        const files = e.currentTarget.files;
        if (!files.length) {
          return [];
        }

        const html = await convertWordToHtml(files[0]);
        const tableData = await extractTablesFromHtml(html, bcaCount, daCount);
        if (tableData.length) {
          const conditions = tableData.map((table) =>
            table.map((cell, index) => {
              const newCondition = createDefaultCondition(
                project,
                permit,
                index
              );
              return {
                ...newCondition,
                conditionAuthority: cell.authority,
                conditionComments: cell.conditionComments,
                conditionDetails: cell.conditionDetails,
                discipline: cell.discipline,
                status: cell.status,
              } as ConditionLineItem;
            })
          );

          await handleImportConditons(conditions.flat());
        }
        return;
      } catch (e: any) {
        alert(e);
      } finally {
        setOpen(false);
        setStep(ImportStep.Init);
      }
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="bgBlueOverlay"
    >
      <DialogTitle>Import Checklist</DialogTitle>
      <DialogContent dividers>
        {step === ImportStep.Init ? (
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              margin: "0",
              padding: "20px 30px",
            }}
          >
            <Stack spacing={2}>
              <StyledButton
                loading={false}
                onClick={onFileInput}
                variant="contained"
                disabled={loading}
                startIcon={<CloudUploadIcon />}
              >
                <input
                  hidden
                  ref={fileInput}
                  accept="*/docx"
                  type="file"
                  onChange={onUpload}
                />
                {"Upload"}
              </StyledButton>
              <a
                href={process.env.PUBLIC_URL + "/TemplateChecklist.docx"}
                download="Permit-One-Condition-Template.docx"
              >
                {"Download Checklist Template"}
              </a>
            </Stack>
          </Grid>
        ) : null}
        {step !== ImportStep.Init ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2}>
              <Box sx={{ margin: "auto !important" }}>
                <CircularProgress />
              </Box>
              <Typography>
                {step === ImportStep.Parse ? "Parsing..." : "Uploading..."}
              </Typography>
            </Stack>
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
