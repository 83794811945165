import useConfig from "@hooks/utils/useConfig";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  ButtonBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ForwardRefExoticComponent, RefAttributes, forwardRef } from "react";
import { Link } from "react-router-dom";
import { LinkTarget, NavItemType } from "types";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface NavItemProps {
  loading: boolean;
  item: NavItemType;
  level: number;
  isDrawOpen: boolean;
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
  selectedItem: string[];
}

const NavItem = ({
  isDrawOpen,
  item,
  level,
  loading,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: NavItemProps) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const { borderRadius } = useConfig();

  const isSelected = selectedItem.findIndex((id) => id === item.id) > -1;

  const itemIcon = item?.icon ? (
    item.icon
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected
          ? theme.palette.primary.light
          : theme.palette.text.primary,
        height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget: LinkTarget = "_self";
  if (item?.target) {
    itemTarget = "_blank";
  }

  const listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item?.url || ""} target={itemTarget} />
    )),
  };

  listItemProps.component.displayName = "Link";

  const itemHandler = (_: string) => {
    if (matchesSM) setIsDrawOpen(false);
    // dispatch(activeItem([id]));
    // if (matchesSM) dispatch(openDrawer(false));
    // dispatch(activeID(parentId));
  };

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" && isDrawOpen
      ? "text.primary"
      : "secondary.main";

  return (
    <>
      {!loading ? (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          disableRipple={!isDrawOpen}
          sx={{
            borderRadius: `${borderRadius}px`,
            mb: 0.5,
            pl: isDrawOpen ? `${level * 24}px` : 1.25,
            zIndex: 1201,
            ...(isDrawOpen &&
              level === 1 &&
              theme.palette.mode !== "dark" && {
                "&.Mui-selected": {
                  "&:hover": {
                    background: theme.palette.primary.light,
                    color: iconSelectedColor,
                  },
                  background: theme.palette.primary.light,
                  color: iconSelectedColor,
                },
                "&:hover": {
                  background: theme.palette.primary.light,
                },
              }),
            ...((!isDrawOpen || level !== 1) && {
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
              "&:hover": {
                bgcolor: "transparent",
              },
              py: level === 1 ? 0 : 1,
            }),
          }}
          selected={isSelected}
          onClick={() => setSelectedItem(item.id ? [item.id] : [])}
        >
          <ButtonBase
            aria-label="theme-icon"
            sx={{ borderRadius: `${borderRadius}px` }}
            disableRipple={isDrawOpen}
          >
            <ListItemIcon
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                minWidth: level === 1 ? 36 : 18,
                ...(!isDrawOpen &&
                  level === 1 && {
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light + 25
                          : "primary.light",
                    },
                    alignItems: "center",
                    borderRadius: `${borderRadius}px`,
                    height: 46,
                    justifyContent: "center",
                    width: 46,
                    ...(isSelected && {
                      "&:hover": {
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.light + 30
                            : theme.palette.primary.light,
                      },
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light + 25
                          : theme.palette.primary.light,
                    }),
                  }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          </ButtonBase>

          {(isDrawOpen || (!isDrawOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? "body1" : "body1"}
                  color="inherit"
                >
                  {item.title}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          sx={{
            alignItems: "flex-start",
            backgroundColor: level > 1 ? "transparent !important" : "inherit",
            borderRadius: 0,
            mb: 0.5,
            pl: 2,
            py: 1,
          }}
          selected={isSelected}
          onClick={() => {
            if (item.id) {
              itemHandler(item?.id);
            }
          }}
        >
          <Skeleton animation="wave" variant="circular">
            <ListItemIcon
              sx={{
                minWidth: !item?.icon ? 18 : 36,
                my: "auto",
              }}
            >
              {itemIcon}
            </ListItemIcon>
          </Skeleton>

          <Skeleton animation="wave" width={200} sx={{ marginLeft: "20px" }}>
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? "h5" : "body1"}
                  color="inherit"
                >
                  {item.title}
                </Typography>
              }
              secondary={
                item.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {item.caption}
                  </Typography>
                )
              }
            />
          </Skeleton>
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
