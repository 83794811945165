import LoadingButton from "@mui/lab/LoadingButton";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import React from "react";

interface PricingCardProps {
  title: string;
  price: number;
  disabled: boolean;
  description: string;
  handleBuyNow: () => void;
}

const PricingCard = ({
  description,
  disabled,
  handleBuyNow,
  price,
  title,
}: PricingCardProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleClick = () => {
    setLoading(true);
    handleBuyNow();
  };
  return (
    <Card
      sx={{
        backgroudncolor: "gray",
        margin: "20px",
        minWidth: 275,
      }}
    >
      <CardContent>
        <Chip
          label={title}
          color="primary"
          variant="outlined"
          sx={{
            marginBottom: 2,
          }}
        />
        <Typography
          sx={{
            fontSize: 30,
            fontWeight: "bold",
            marginBottom: 2,
          }}
          color="textPrimary"
        >
          ${price}
        </Typography>
        <Typography sx={{ marginBottom: 2 }} color="textSecondary">
          {description}
        </Typography>
        <LoadingButton
          size="small"
          onClick={handleClick}
          loading={loading}
          disabled={disabled}
          variant="contained"
        >
          <span>Buy Now</span>
        </LoadingButton>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
