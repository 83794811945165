import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useRef, useState } from "react";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "../ListTable/ListTableRowMenu";


interface UserTableRowProps {
  profile: ProfileLineItem;
  disabled: boolean;
  handleDeleteUser: (profile: ProfileLineItem) => void;
  handleEditUser: (profile: ProfileLineItem) => void;
}

export const UserTableRow = ({
  disabled,
  handleDeleteUser,
  handleEditUser,
  profile,
}: UserTableRowProps) => {
  const ref = useRef<Element>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const menuItems = [
    {
      action: () => {
        handleEditUser(profile);
      },
      color: "info.main",
      icon: <EditIcon />,
      name: "Edit",
    },
    {
      action: () => {
        setOpenConfirmDialog(true);
      },
      color: "error.main",
      icon: <DeleteForeverIcon />,
      name: "Remove",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { email, firstName, id, invited, isAdmin, lastName } = profile;

  return (
    <>
      <TableRow hover key={id} tabIndex={-1}>
        <TableCell align="left">
          {firstName.length > 0 ? firstName : "-"}
        </TableCell>
        <TableCell align="left">
          {lastName.length > 0 ? lastName : "-"}
        </TableCell>
        <TableCell align="left">
          {`${email} ${invited ? "- (Invited)" : ""}`}
        </TableCell>
        <TableCell align="left">{isAdmin ? "Admin" : "User"}</TableCell>
        <TableCell align="right" ref={ref}>
          <IconButton
            size="small"
            color="inherit"
            disabled={disabled}
            onClick={() => {
              handleOpen();
            }}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
      <ConfirmationDialog
        message={"Are you sure you want to remove this user?"}
        open={openConfirmDialog}
        title={"Remove user"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDeleteUser(profile)}
      />
    </>
  );
};
