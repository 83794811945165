import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Skeleton, styled } from "@mui/material";

export const StyledLoadingButtonBase = styled(LoadingButton)(() => ({
  color: "#fff",
  px: 2.75,
  py: 1.5,
}));

type StyledButtonProps = ButtonProps & { loading: boolean };

export const StyledButton = (props: StyledButtonProps) => {
  const { loading, ...otherProps } = props;
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <Button {...otherProps}>{props.children}</Button>
      </Skeleton>
    );
  }

  return <Button {...otherProps}>{props.children}</Button>;
};

type LoadingButtonPropsExtended = LoadingButtonProps & {
  action?: () => Promise<void>;
};

export const StyledLoadingButton = (props: LoadingButtonPropsExtended) => {
  if (props.loading) {
    return (
      <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
        <LoadingButton {...props}>{props.children}</LoadingButton>
      </Skeleton>
    );
  }
  return <LoadingButton {...props}>{props.children}</LoadingButton>;
};
