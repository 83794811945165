import { downloadFile } from "@hooks/utils/useUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ListTableRowMenu } from "@stories/molecules/ListTable/ListTableRowMenu";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import { fileSizeToMb } from "permit-one-common/src/utils/number";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { useRef, useState } from "react";

interface ConditionDocumentTableRowProps {
  document: ConditionDocumentLineItem;
  selected: boolean;
  handleClick: (id: string) => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleRemoveDocument: (document: ConditionDocumentLineItem) => void;
}

export const ConditionDocumentTableRow = ({
  document,
  handleClick,
  handleEditDocument,
  handleRemoveDocument,
  selected,
}: ConditionDocumentTableRowProps) => {
  const ref = useRef<Element>();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [open, setOpen] = useState(false);

  const menuItems = [
    // {
    //   name: "View",
    //   color: "black",
    //   icon: <RemoveRedEyeIcon />,
    //   action: () => {
    //     if (document && document.url.length > 0) {
    //       const targetUrl = document.url[document.url.length - 1];
    //       downloadFile(
    //         targetUrl,
    //         getDocumentName(document.documentTitle, targetUrl),
    //         undefined,
    //         true
    //       );
    //     }
    //   },
    // },
    {
      action: () => {
        handleEditDocument(document);
      },
      color: "black",
      icon: <EditIcon />,
      name: "Edit",
    },
    {
      action: () => {
        if (document && document.url.length > 0) {
          downloadFile(
            document.url,
            getDocumentName(document.documentTitle, document.url)
          );
        }
      },
      color: "black",
      icon: <CloudDownloadIcon />,
      name: "Download",
    },
    {
      action: () => {
        setOpenConfirmDialog(true);
      },
      color: "red",
      icon: <DeleteIcon />,
      name: "Remove",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center" padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={() => handleClick(document.id)}
          />
        </TableCell>
        <TableCell align="center">{document.documentNumber}</TableCell>
        <TableCell align="center">{document.documentTitle}</TableCell>
        <TableCell align="center">{document.revisionNumber}</TableCell>
        <TableCell align="center">
          {conditionDisciplineToString(document.documentDiscipline)}
        </TableCell>
        <TableCell align="center">
          {documentCategoryToString(document.documentCategory)}
        </TableCell>
        <TableCell align="center">{document.createdByName}</TableCell>
        <TableCell align="center">
          {document.modified.toLocaleDateString()}
        </TableCell>
        <TableCell align="center">{document.fileType}</TableCell>
        <TableCell align="center">{fileSizeToMb(document.fileSize)}</TableCell>
        <TableCell ref={ref}>
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              handleOpen();
            }}
          >
            <Iconify icon={"eva:more-vertical-fill"} />
          </IconButton>
        </TableCell>
      </TableRow>
      {ref.current && open ? (
        <ListTableRowMenu
          menuItems={menuItems}
          anchorElement={ref.current}
          handleCloseMenu={handleClose}
        />
      ) : null}
      <ConfirmationDialog
        message={
          "Are you sure you want to remove this document from this condition?"
        }
        open={openConfirmDialog}
        title={"Remove document"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleRemoveDocument(document)}
      />
    </>
  );
};
