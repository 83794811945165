import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { drawerWidth } from "constant";
import { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams } from "react-router-dom";

import LogoSection from "../LogoSection";
import MenuList from "../MenuList";
import MiniDrawerStyled from "./MiniDrawerStyled";


interface SidebarProps {
  accessId: string;
  isDrawOpen: boolean;
  selectedItem: string[];
  setIsDrawOpen: (open: boolean) => void;
  setSelectedItem: (item: string[]) => void;
}

const Sidebar = ({
  isDrawOpen,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: SidebarProps) => {
  const { accessId } = useParams();
  if (!accessId) {
    throw Error("accessId is required");
  }
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const drawerContent = (
    <>
      <MenuList
        loading={false}
        accessId={accessId}
        isDrawOpen={isDrawOpen}
        setIsDrawOpen={setIsDrawOpen}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </>
  );

  const drawerSX = {
    background: "transparent",
    marginTop: "17px",
    paddingLeft: isDrawOpen ? "16px" : 0,
    paddingRight: isDrawOpen ? "16px" : 0,
  };

  const drawer = matchDownMd ? (
    <Box sx={drawerSX}>{drawerContent}</Box>
  ) : (
    <PerfectScrollbar
      component="div"
      style={{
        height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 89px)",
        ...drawerSX,
      }}
    >
      {drawerContent}
    </PerfectScrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
      className="sidebar"
    >
      {matchDownMd || isDrawOpen ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={isDrawOpen}
          onClose={() => setIsDrawOpen(!isDrawOpen)}
          sx={{
            "& .MuiDrawer-paper": {
              background: "transparent",
              borderRight: "none",
              color: "white",
              mt: matchDownMd ? 0 : 11,
              width: drawerWidth,
              zIndex: 1099,
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={isDrawOpen}>
          {logo}
          {drawer}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;
