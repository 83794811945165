import { usePermitEvent } from "@hooks/crud/usePermitEvent";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonIcon from "@mui/icons-material/Person";
import PreviewIcon from "@mui/icons-material/Preview";
import RepeatIcon from "@mui/icons-material/Repeat";
import WarningAmber from "@mui/icons-material/WarningAmber";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as RouteHelper from "@utils/routes";
import { PermitEventType } from "permit-one-common/src/entities/permitEvent";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitEventLineItem,
  permitEventTypeToString,
} from "permit-one-common/src/interfaces/permitEvent";
import { Link } from "react-router-dom";

interface PermitEventLogProps {
  loading: boolean;
  permit?: PermitLineItem;
}

export const permitEventToLink = (permitEvent: PermitEventLineItem): string => {
  switch (permitEvent.eventType) {
    default:
    case PermitEventType.PermitCreated:
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.PermitApproved:
    case PermitEventType.PermitInReview:
    case PermitEventType.PermitRejected:
    case PermitEventType.PermitUpdated:
    case PermitEventType.PermitUploadedDocument:
      return RouteHelper.conditions(
        permitEvent.projectId,
        permitEvent.permitId
      );
    case PermitEventType.ConditionAssigned:
    case PermitEventType.ConditionApproved:
    case PermitEventType.ConditionInReview:
    case PermitEventType.ConditionRejected:
    case PermitEventType.ConditionUpdated:
    case PermitEventType.ConditionDocumentUploaded:
      return RouteHelper.conditions(
        permitEvent.projectId,
        permitEvent.permitId
      );
    case PermitEventType.ConditionDocumentRevisionUploaded:
      return RouteHelper.condition(
        permitEvent.projectId,
        permitEvent.permitId,
        permitEvent.conditionId || "",
        "document"
      );
  }
};

export const permitEventTypeToIcon = (
  permitEventType: PermitEventType
): React.ReactNode => {
  switch (permitEventType) {
    case PermitEventType.PermitCreated:
    case PermitEventType.ConditionCreated:
      return (
        <TimelineDot color="primary">
          <AddCircleOutline />
        </TimelineDot>
      );
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.ConditionAssigned:
      return (
        <TimelineDot color="primary">
          <PersonIcon />
        </TimelineDot>
      );
    case PermitEventType.PermitApproved:
    case PermitEventType.ConditionApproved:
      return (
        <TimelineDot color="success">
          <CheckCircleOutline />
        </TimelineDot>
      );
    case PermitEventType.PermitInReview:
    case PermitEventType.ConditionInReview:
      return (
        <TimelineDot color="primary">
          <PreviewIcon />
        </TimelineDot>
      );
    case PermitEventType.PermitRejected:
    case PermitEventType.ConditionRejected:
      return (
        <TimelineDot color="error">
          <WarningAmber />
        </TimelineDot>
      );
    case PermitEventType.PermitUpdated:
    case PermitEventType.ConditionUpdated:
      return (
        <TimelineDot color="primary">
          <RepeatIcon />
        </TimelineDot>
      );
    case PermitEventType.PermitUploadedDocument:
    case PermitEventType.ConditionDocumentUploaded:
    case PermitEventType.ConditionDocumentRevisionUploaded:
      return (
        <TimelineDot color="primary">
          <CloudUploadIcon />
        </TimelineDot>
      );
  }
  return <AddCircleOutline />;
};

export const PermitTimelineSkeleton: React.FC = () => {
  const skeletonItems = Array.from({ length: 5 }); // Adjust the number based on the expected number of events

  return (
    <Timeline>
      {skeletonItems.map((_, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Skeleton
              variant="text"
              width={100}
              animation="wave"
              sx={{ marginLeft: "auto" }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              animation="wave"
            />
            {index < skeletonItems.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Skeleton variant="text" width="40%" animation="wave" />
            <Skeleton variant="text" width="60%" animation="wave" />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export const PermitEventLog = ({
  loading,
  permit,
}: PermitEventLogProps): JSX.Element => {
  const { isPermitEventLoading, permitEvents } = usePermitEvent(permit?.id);
  const isLoading = loading || isPermitEventLoading;
  return (
    <>
      {isLoading ? <PermitTimelineSkeleton /> : null}
      {!isLoading ? (
        <Timeline position="alternate">
          {permitEvents.map((event, index) => (
            <Link to={permitEventToLink(event)} key={event.id}>
              <TimelineItem key={event.id}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {event.created.toLocaleDateString()}{" "}
                    {event.created.toLocaleTimeString()}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {permitEventTypeToIcon(event.eventType)}
                  {index < permitEvents.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6" component="span">
                    {permitEventTypeToString(event.eventType)}
                  </Typography>
                  <Typography>
                    {event.info}
                    {event?.name ? ` by ${event.name}` : ""}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Link>
          ))}
          {permitEvents.length == 0 ? (
            <Typography
              sx={{ color: "black", fontSize: "16px", fontWeight: "500" }}
            >
              {"No permit events"}
            </Typography>
          ) : null}
        </Timeline>
      ) : null}
    </>
  );
};
