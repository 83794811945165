import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButton, Tooltip, Zoom } from "@mui/material";
interface DownloadButtonProps {
  disabled: boolean;
  onClick: () => void;
}
export const DownloadButton = ({ disabled, onClick }: DownloadButtonProps) => {
  return (
    <Tooltip TransitionComponent={Zoom} placement="top" title="Download">
      <IconButton
        color="primary"
        size="large"
        disabled={disabled}
        onClick={onClick}
      >
        <CloudDownloadIcon
          sx={{
            fontSize: "1.1rem",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
