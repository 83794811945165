import { Chip, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
export const MailRowSkeleton = () => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      sx={{
        "& td:last-of-type>div": {
          "& button + button": {
            ml: 0.625,
          },
          background:
            theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
          borderRadius: "12px",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
          display: "none",
          position: "absolute",
          px: 1.75,
          py: 1,
          right: 5,
          top: "50%",
          transform: "translateY(-50%)",
        },
        "&:hover": {
          "& td:last-of-type>div": {
            display: "block",
          },
        },
        bgcolor: "",
      }}
      tabIndex={-1}
    >
      <TableCell align="center" style={{ width: "10%" }}>
        <Skeleton animation="wave">
          <Chip
            label={"READ"}
            sx={{
              backgroundColor: theme.palette.warning.dark,
            }}
          />
        </Skeleton>
      </TableCell>
      <TableCell sx={{ cursor: "pointer", width: "20%" }}>
        <Skeleton animation="wave">
          <Typography variant={"body2"}>{"Message body"}</Typography>
        </Skeleton>
      </TableCell>
      <TableCell align="center" style={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" style={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ position: "relative", width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
