"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultConditionDocumentRevision = void 0;
var uuid_1 = require("uuid");
var createDefaultConditionDocumentRevision = function (conditionDocumentId, revisionNumber, url, createdBy, createdByName, fileType, fileSize, profileId, shareConditionId) {
    return {
        conditionDocumentId: conditionDocumentId,
        created: new Date(),
        createdByName: createdByName,
        fileSize: fileSize,
        fileType: fileType,
        id: (0, uuid_1.v4)(),
        profileId: profileId,
        revisionNumber: revisionNumber,
        shareConditionId: shareConditionId,
        url: url,
    };
};
exports.createDefaultConditionDocumentRevision = createDefaultConditionDocumentRevision;
