import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodePermit,
  encodePermit,
} from "permit-one-common/src/decoders/permit";
import {
  Permit,
  PermitLineItem,
} from "permit-one-common/src/interfaces/permit";
import * as React from "react";

export const usePermit = (projectId?: string, permitId?: string) => {
  const [selectedPermit, setSelectedPermit] = React.useState<PermitLineItem>();
  const [permits, setPermits] = React.useState<PermitLineItem[]>([]);
  const [isPermitLoading, setIsPermitLoading] = React.useState<boolean>(true);
  const [isPermitUpdating, setIsPermitUpdating] =
    React.useState<boolean>(false);

  const [error, setError] = React.useState<string | null>(null);

  const getProfileForPermit = async (p: Permit): Promise<PermitLineItem> => {
    return decodePermit(p, getImageUrl);
  };

  // const getConditionStatusForPermit = async (p: Permit): Promise<Permit> => {
  //   const conditionsForPermit = await listApiData(
  //     "listConditions",
  //     "conditions",
  //     p.id
  //   );
  //   const conditions = conditionsForPermit.map((d) => d.data as Condition);

  //   p.totalConditions = conditions.length;
  //   p.approvedConditions = conditions.filter(
  //     (i) => i.status === encodeConditionStatus(ConditionStatus.Approved)
  //   ).length;
  //   p.assignedConditions = conditions.filter(
  //     (i) => i.status === encodeConditionStatus(ConditionStatus.Assigned)
  //   ).length;
  //   p.notAssignedConditions = conditions.filter(
  //     (i) => i.status === encodeConditionStatus(ConditionStatus.NotAssigned)
  //   ).length;
  //   p.overDueConditions = conditions.filter(
  //     (i) => i.dueDate.getTime() < new Date().getTime()
  //   ).length;

  //   return p;
  // };

  const getPermit = async (id: string, isUpdate = false): Promise<void> => {
    try {
      if (isUpdate) {
        setIsPermitUpdating(true);
      } else {
        setIsPermitLoading(true);
      }
      const permitResponse = (await getApiData("getPermit", "permit", id))
        .data as Permit;
      if (permitResponse) {
        // await getConditionStatusForPermit(permitResponse);
        const permitLineItem = await getProfileForPermit(permitResponse);
        setSelectedPermit(permitLineItem);
      } else {
        setSelectedPermit(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch permit");
    } finally {
      if (isUpdate) {
        setIsPermitUpdating(false);
      } else {
        setIsPermitLoading(false);
      }
    }
  };

  const listPermits = async (projectId: string): Promise<void> => {
    try {
      setIsPermitLoading(true);
      const permits = await listApiData("listPermits", "permit", projectId);

      // const permitsWithCounts = await Promise.all(
      //   permits.map((p) => getConditionStatusForPermit(p.data as Permit))
      // );
      const permitLineItems = await Promise.all(
        permits.data.map((p) => getProfileForPermit(p as Permit))
      );
      setPermits(permitLineItems);
    } catch (e: any) {
      setError("Could not list permits");
    } finally {
      setIsPermitLoading(false);
    }
  };

  const createPermit = async (permit: PermitLineItem): Promise<void> => {
    try {
      setIsPermitLoading(true);
      await postApiData("createPermit", "permit", encodePermit(permit));
      setPermits([...permits, permit]);
    } catch (e: any) {
      setError("Could not list permits");
    } finally {
      setIsPermitLoading(false);
    }
  };

  const updatePermit = async (permit: PermitLineItem): Promise<void> => {
    try {
      setIsPermitLoading(true);
      await postApiData("updatePermit", "permit", encodePermit(permit));

      if (permit.archived) {
        setPermits(permits.filter((p) => p.id !== permit.id));
      } else {
        setPermits(
          permits.map((c) => {
            if (c.id === permit.id) {
              return permit;
            }
            return c;
          })
        );
      }
    } catch (e: any) {
      setError("Could not update permit");
    } finally {
      setIsPermitLoading(false);
    }
  };

  const deletePermit = async (permit: PermitLineItem): Promise<void> => {
    try {
      setIsPermitLoading(true);
      await postApiData("deletePermit", "permit", encodePermit(permit));
      setPermits(permits.filter((p) => p.id !== permit.id));
    } catch (e: any) {
      setError("Could not delete permit");
    } finally {
      setIsPermitLoading(false);
    }
  };

  React.useEffect(() => {
    if (permitId) {
      setIsPermitLoading(false);
    } else if (projectId) {
      listPermits(projectId);
    }
  }, [projectId]);

  React.useEffect(() => {
    if (permitId) {
      getPermit(permitId);
    }
  }, [permitId]);

  return {
    createPermit,
    deletePermit,
    error,
    getPermit,
    isPermitLoading,
    isPermitUpdating,
    listPermits,
    permits,
    selectedPermit,
    updatePermit,
  };
};
