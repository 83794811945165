import { useProfileContext } from "@hooks/context/useProfileContext";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "@stories/ui-component/cards/MainCard";
import Transitions from "@stories/ui-component/extended/Transitions";
import {
  IconLogout,
  IconSettings,
  IconUser,
  IconBuilding,
  IconFolder,
} from "@tabler/icons";
import * as RouteHelper from "@utils/routes";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isAuthProfileLoading, signOut, userOrganisation, userProfile } =
    useProfileContext();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    try {
      await signOut();
    } catch (err) {
      console.error(err);
    }
  };
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        className="profileChip"
        sx={{
          "& .MuiChip-label": {
            lineHeight: 0,
          },
          '&[aria-controls="menu-list-grow"], &:hover': {
            "& svg": {
              stroke: theme.palette.primary.main,
            },
            background: `${theme.palette.primary.light}!important`,
            color: theme.palette.primary.main,
          },
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "12px",
          height: "48px",
          transition: "all .2s ease-in-out",
        }}
        icon={
          !isAuthProfileLoading && userProfile ? (
            <Avatar
              src={userProfile.photoUrl}
              alt="user-images"
              sx={{
                ...theme.typography.mediumAvatar,
                cursor: "pointer",
                margin: "8px 0 8px 8px !important",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          ) : (
            <Skeleton animation={"wave"} variant="circular">
              <Avatar />
            </Skeleton>
          )
        }
        label={
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              fontSize: "14px !important",
              fontWeight: "500",
            }}
          >
            {userProfile?.name}
          </Typography>
        }
        variant="filled"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        aria-label="user-account"
      />

      <Popper
        className="popup"
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 1.5, pt: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                          backgroundColor: theme.palette.background.paper,
                          maxWidth: 350,
                          minWidth: 300,
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          width: "100%",
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: "20px", padding: "6px 12px" }}
                          selected={selectedIndex === 0}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handleListItemClick(
                              event,
                              0,
                              RouteHelper.listProjects
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconFolder stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Projects</Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: "20px", padding: "6px 12px" }}
                          selected={selectedIndex === 1}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handleListItemClick(
                              event,
                              1,
                              RouteHelper.userAccount
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                Account Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        {userOrganisation ? (
                          <>
                            <ListItemButton
                              sx={{
                                borderRadius: "20px",
                                padding: "6px 12px",
                              }}
                              selected={selectedIndex === 2}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  2,
                                  RouteHelper.businessAccount
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconBuilding stroke={1.5} size="20px" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Business Account
                                  </Typography>
                                }
                              />
                            </ListItemButton>

                            {userProfile?.isAdmin ? (
                              <ListItemButton
                                sx={{
                                  borderRadius: "20px",
                                  padding: "6px 12px",
                                }}
                                selected={selectedIndex === 3}
                                onClick={(
                                  event: React.MouseEvent<HTMLDivElement>
                                ) =>
                                  handleListItemClick(
                                    event,
                                    3,
                                    RouteHelper.users
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <IconUser stroke={1.5} size="20px" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant="body2">
                                      Users
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            ) : null}
                          </>
                        ) : null}
                        <ListItemButton
                          sx={{ borderRadius: "20px", padding: "6px 12px" }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="20px" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
