import { usePermitEvent } from "@hooks/crud/usePermitEvent";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";
import { Loader } from "@stories/atoms/Loaders/Loader/Loader";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { permitEventTypeToString } from "permit-one-common/src/interfaces/permitEvent";

import { permitEventTypeToIcon } from "../PermitEventLog/PermitEventLog";

interface ConditionEventLogProps {
  loading: boolean;
  condition?: ConditionLineItem;
}

export const ConditionEventLog = ({
  condition,
  loading,
}: ConditionEventLogProps): JSX.Element => {
  const { isPermitEventLoading, permitEvents } = usePermitEvent(
    condition?.permitId,
    condition?.id
  );
  if (loading || isPermitEventLoading) {
    return <Loader />;
  }
  if (!condition) {
    throw new Error(
      "Missing project, permit, or condition in ConditionEventLog"
    );
  }
  return (
    <>
<Timeline position="alternate" sx={{ marginTop: "0" }} className="timeLine">
        {permitEvents.map((c, index) => {
          return (
            <TimelineItem key={c.id}>
              <TimelineSeparator>
                {permitEventTypeToIcon(c.eventType)}
                {index < permitEvents.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <div className="list" style={{border:'1px solid rgb(242, 242, 242', borderRadius:'4px', display:'flex', flexDirection:'column', minWidth:'300px',
              padding:'15px 10px',
              }}>
                {/* <Typography sx={{fontWeight:'500', color:'black', fontSize:'12px'}}>Date</Typography> */}
                <TimelineOppositeContent
                variant="body1"
                color="black"
                sx={{color:'rgb(102, 102, 102)', fontSize:'11px', fontWeight:'400' , padding:'0', textAlign:'left !important'}}
              >
                {c.created.toDateString()}
              </TimelineOppositeContent>
           
              <TimelineContent sx={{ padding: "0 !important" }}>
              <Typography sx={{color:'black',fontSize:'18px !important',fontWeight:'600'}}>
                  {permitEventTypeToString(c.eventType)}
                </Typography>
                <Typography variant="h6"  sx={{color:'rgb(102, 102, 102)', fontSize:'12px !important' , 
                fontWeight:'400', textDecoration:'none !important',}}>{c.info}</Typography>
              </TimelineContent>
              </div>
            </TimelineItem>
          );
        })}
        {permitEvents.length == 0 ? (
          <Typography sx={{color:'black', fontSize:'20px'}}>{"No condition events"}</Typography>
        ) : null}
      </Timeline>
    </>
  );
};
