import { MenuItem, Popover, Typography } from "@mui/material";

interface ListMenuContentItem {
  name: string;
  color: string;
  icon: React.ReactNode;
  action: () => void;
}

interface ListTableRowMenuProps {
  menuItems: ListMenuContentItem[];
  anchorElement: Element | undefined;
  handleCloseMenu: () => void;
}
export const ListTableRowMenu = ({
  anchorElement,
  handleCloseMenu,
  menuItems,
}: ListTableRowMenuProps) => {
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={handleCloseMenu}
      anchorOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      PaperProps={{
        sx: {
          "& .MuiMenuItem-root": {
            borderRadius: 0.75,
            px: 1,
            typography: "body2",
          },
          p: 1,
          width: 160,
        },
      }}
    >
      {menuItems.map((m, index) => {
        return (
          <MenuItem
            key={`table-row-menu-item-${index}`}
            sx={{ color: m.color }}
            onClick={() => {
              handleCloseMenu();
              m.action();
            }}
          >
            {m.icon}
            <Typography ml={1}>{m.name}</Typography>
          </MenuItem>
        );
      })}
    </Popover>
  );
};
