"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPermitInspection = void 0;
var uuid_1 = require("uuid");
var createDefaultPermitInspection = function (project, permit) {
    return {
        created: new Date(),
        id: (0, uuid_1.v4)(),
        inspectionName: "",
        permitId: permit.id,
        projectId: project.id,
        url: "",
    };
};
exports.createDefaultPermitInspection = createDefaultPermitInspection;
