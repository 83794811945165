import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import * as React from "react";

import MailEmpty from "./MailEmpty";
import { MailListProps } from "./MailProps";
import { MailRow } from "./MailRow";
import { MailRowSkeleton } from "./MailRowSkeleton";


const MailList = ({
  data,
  isLoading,
  profile,
  setSelectedMail,
}: MailListProps) => {
  return (
    <Table stickyHeader aria-label="sticky table" className="project-table">
      <TableHead>
        <TableRow style={{ border: "none", padding: "10px 0 0" }}>
          <TableHeader loading={isLoading} title="" />
          <TableHeader loading={isLoading} title="Subject" />
          <TableHeader loading={isLoading} title="Project" />
          <TableHeader loading={isLoading} title="Attachments" />
          <TableHeader loading={isLoading} align="center" title="Received" />
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading && data.length > 0
          ? data.map((row, index) => {
              return (
                <MailRow
                  row={row}
                  profile={profile}
                  key={index}
                  setSelectedMail={setSelectedMail}
                />
              );
            })
          : null}

        {!isLoading && data.length == 0 ? <MailEmpty /> : null}
        {isLoading
          ? Array.from({ length: 6 }, (_, index) => (
              <MailRowSkeleton key={index} />
            ))
          : null}
      </TableBody>
    </Table>
  );
};

export default MailList;
