import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from "react";

interface LayoutProps {
  children: React.ReactNode;
}

export const BasicLayout = (props: LayoutProps) => {
  const { children } = props;
  return (
    <>
      <GlobalStyles
        styles={{ ul: { listStyle: "none", margin: 0, padding: 0 } }}
      />
      {children}
    </>
  );
};
