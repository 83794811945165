import { useProjectContext } from "@hooks/context/useProjectContext";
import { useTask } from "@hooks/crud/useTask";
import { useTaskCount } from "@hooks/crud/useTaskCount";
import { Box, Grid, Typography } from "@mui/material";
import { TaskSummary } from "@stories/organisms/Summary/TaskSummary";
import { TaskTable } from "@stories/organisms/Tables/TaskTable/TaskTable";
import * as RouteHelper from "@utils/routes";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Tasks = (): JSX.Element => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { selectedProject } = useProjectContext();
  const { isTaskLoading, tasks } = useTask(projectId);
  const { isTaskCountLoading, taskCounts } = useTaskCount(selectedProject?.id);

  const params = new URLSearchParams(location.search);
  const initialConditionStatus = parseInt(params.get("status") || "0");
  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<number>(initialConditionStatus);

  useEffect(() => {
    setSelectedConditionStatus(initialConditionStatus);
  }, [initialConditionStatus]);

  const handleViewCondition = (task: ConditionLineItem) => {
    const params = new URLSearchParams({
      id: task.id,
    });

    navigate(
      `${RouteHelper.conditions(
        task.projectId,
        task.permitId
      )}?${params.toString()}`
    );
  };

  const loading = isTaskLoading || isTaskCountLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0 !important",
          marginBottom: "10px !important",
          padding: "20px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            color: "#333333",
            fontSize: "28px !important",
            fontWeight: "700",
          }}
        >
          My Tasks
        </Typography>
      </Grid>
      <Box sx={{ padding: "15px 40px 40px" }}>
        <TaskSummary
          taskCounts={taskCounts}
          project={selectedProject}
          loading={loading}
        />
        <TaskTable
          loading={loading}
          conditions={tasks}
          selectedConditionStatus={selectedConditionStatus}
          handleViewCondition={handleViewCondition}
          setConditionStatus={setSelectedConditionStatus}
        />
      </Box>
    </Box>
  );
};
