import EditIcon from "@mui/icons-material/Edit";
import { TableCell, TableRow, Chip, Box } from "@mui/material";
import PublicConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/PublicConditionDocumentModal";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import Iconify from "../Iconify/Iconify";
import { QuillInput } from "../QuillInput/QuillInput";
import { StyledButton } from "../StyledButton/StyledButton";


export function createMarkup(dirty: string) {
  return { __html: dirty };
}

interface PublicEditableTableRowProps {
  loading: boolean;
  disabled: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  conditions: ConditionLineItem[];
  canEdit: boolean;
  documents: ConditionDocumentLineItem[];
  comments: ConditionCommentLineItem[];
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  sendForReview: (condition: ConditionLineItem) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteComment: (comment: ConditionCommentLineItem) => void;
  shareCondition?: ShareConditionLineItem;
  uploadDocument: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
}

function PublicEditableTableRow({
  canEdit,
  comments,
  condition,
  conditions,
  createComments,
  createDocuments,
  deleteComment,
  deleteDocument,
  disabled,
  documents,
  handleEditCondition,
  loading,
  permit,
  project,
  sendForReview,
  shareCondition,
  updateDocuments,
  uploadDocument,
}: PublicEditableTableRowProps) {
  const [currentCondition, setCurrentCondition] =
    useState<ConditionLineItem>(condition);
  const [openBulk, setOpenBulk] = useState(false);

  const [showAssigneeNotesEditor, setShowAssigneeNotesEditor] =
    useState<boolean>(false);

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  const handleTextChange = (
    id: string,
    column: keyof ConditionLineItem,
    newText: string
  ) => {
    const matchingCondition = conditions.find((item) => item.id === id);
    if (!matchingCondition) {
      return;
    }
    if (matchingCondition[column] !== newText) {
      setCurrentCondition({ ...matchingCondition, [column]: newText });
    }
  };

  const handleTextSave = () => {
    handleEditCondition(currentCondition);
  };

  return (
    <>
      <TableRow key={condition.id}>
        <TableCell sx={{ border: "1px solid #000", width: "5%" }}>
          {conditonNameToString(
            condition.conditionAuthority,
            condition.permitItemNumber
          )}
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.conditionDetails || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          <div
            style={{
              padding: "5px !important",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.conditionComments || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          <div
            style={{
              padding: "5px !important",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.discipline || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          onClick={() => {
            if (canEdit) {
              setShowAssigneeNotesEditor(true);
            }
          }}
          sx={{
            verticalAlign: "top",
            width: "15%",
          }}
        >
          {showAssigneeNotesEditor ? (
            <QuillInput
              loading={false}
              disabled={disabled}
              value={currentCondition.assigneeComments}
              onChange={(value) =>
                handleTextChange(condition.id, "assigneeComments", value)
              }
              onBlur={() => {
                setShowAssigneeNotesEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                padding: "5px !important",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.assigneeComments || "",
                }}
              />
              {canEdit ? (
                <EditIcon
                  style={{
                    color: disabled ? "#b4b4b4" : undefined,
                    cursor: "pointer",
                    height: "20px",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20px",
                  }}
                />
              ) : null}
            </div>
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            justifyContent: "center",
            width: "10%",
          }}
        >
          <Box display="flex" justifyContent="center">
            {disabled ? (
              <Chip
                label={conditionStatusToString(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                variant="filled"
                background-color={"#b6b6b6"}
                sx={{
                  backgroundColor: "#b6b6b6",
                }}
              />
            ) : (
              <Chip
                label={conditionStatusToString(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                variant="filled"
                background-color={conditionStatusToColor(
                  condition.status,
                  Boolean(condition.markedAsNotApplicable)
                )}
                sx={{
                  backgroundColor: conditionStatusToColor(
                    condition.status,
                    Boolean(condition.markedAsNotApplicable)
                  ),
                }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ width: "15%" }}>
          <Box display="flex" justifyContent="center">
            <StyledButton
              loading={loading}
              onClick={() => {
                handleOpenBulk();
              }}
              variant="outlined"
              disabled={loading}
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
            >
              {"Documents"}
            </StyledButton>
          </Box>
        </TableCell>
      </TableRow>
      {shareCondition && project ? (
        <PublicConditionDocumentModal
          open={openBulk}
          canEdit={canEdit}
          shareCondition={shareCondition}
          project={project}
          documents={documents}
          comments={comments}
          handleClose={handleCloseBulk}
          permit={permit}
          condition={condition}
          handleEditCondition={sendForReview}
          createComments={createComments}
          createDocuments={createDocuments}
          deleteComment={deleteComment}
          deleteDocument={deleteDocument}
          updateDocuments={updateDocuments}
          uploadFile={uploadDocument}
          loading={loading}
          disabled={disabled}
        />
      ) : null}
    </>
  );
}

export default PublicEditableTableRow;
