"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermitInspection = exports.decodePermitInspection = void 0;
var string_1 = require("../utils/string");
var decodePermitInspection = function (permitInspection) {
    var safePermitInspection = (0, string_1.reversePostgresSafe)(permitInspection);
    return {
        created: new Date(safePermitInspection.created),
        id: safePermitInspection.permit_inspection_id,
        inspectionName: safePermitInspection.inspection_name,
        permitId: safePermitInspection.permit_id,
        projectId: safePermitInspection.project_id,
        url: safePermitInspection.url,
    };
};
exports.decodePermitInspection = decodePermitInspection;
var encodePermitInspection = function (permitInspection) {
    var safePermitInspection = (0, string_1.makePostgresSafe)(permitInspection);
    return {
        created: safePermitInspection.created,
        inspection_name: safePermitInspection.inspectionName,
        permit_id: safePermitInspection.permitId,
        permit_inspection_id: safePermitInspection.id,
        project_id: safePermitInspection.projectId,
        url: safePermitInspection.url,
    };
};
exports.encodePermitInspection = encodePermitInspection;
