import { Autocomplete, Grid, Skeleton, TextField } from "@mui/material";
import { permitTypeToAbbreviationString } from "permit-one-common/src/entities/permit";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";

interface ListDocumentTabsProps {
  loading: boolean;
  value: number;
  permitId?: string;
  project?: ProjectLineItem;
  allPermits: PermitLineItem[];
  setPermitId: (permitId?: string) => void;
  handleChange: (newValue: number) => void;
}

export const ListDocumentTabs = ({
  allPermits,
  loading,
  permitId,
  project,
  setPermitId,
}: ListDocumentTabsProps): JSX.Element => {
  const onChange = (
    _: React.SyntheticEvent,
    newValue: PermitLineItem | null
  ) => {
    // Update the permit ID based on the selected value or null if cleared
    setPermitId(newValue ? newValue.id : undefined);
  };
  return (
    <Grid container mt={2}>
      {/* <Grid item md={8}>
        <List
          className="mailList"
          sx={{ display: "flex", maxWidth: "max-content" }}
        >
          <TabButton
            isLoading={loading}
            title={"All"}
            tabIndex={0}
            selectedTab={value}
            handleSelect={handleChange}
          />
          <TabButton
            isLoading={loading}
            title={"Approved"}
            tabIndex={1}
            selectedTab={value}
            handleSelect={handleChange}
          />
          <TabButton
            isLoading={loading}
            title={"In Review"}
            tabIndex={2}
            selectedTab={value}
            handleSelect={handleChange}
          />
        </List>
      </Grid> */}
      <Grid item md={4}>
        {loading ? (
          <Skeleton animation="wave" variant="rounded" height={56} width="100%">
            <Autocomplete
              options={allPermits}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Permit Filter"
                  placeholder="Search or select a permit"
                  fullWidth
                />
              )}
              clearOnEscape
            />
          </Skeleton>
        ) : (
          <Autocomplete
            options={allPermits}
            getOptionLabel={(permit) =>
              `${permit.permitName} (${
                project?.projectCode || ""
              }-${permitTypeToAbbreviationString(permit.permitType)}-${
                permit.permitNumber
              })`
            }
            value={
              permitId
                ? allPermits.find((permit) => permit.id === permitId)
                : null
            }
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Permit Filter"
                placeholder="Search or select a permit"
                fullWidth
              />
            )}
            clearOnEscape
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        )}
      </Grid>
    </Grid>
  );
};
