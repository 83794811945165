import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { UserForm } from "@stories/molecules/UserForm/UserForm";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface UserModalProps {
  open: boolean;
  loading: boolean;
  organisation?: OrganisationLineItem;
  profiles: ProfileLineItem[];
  existingUser?: ProfileLineItem;
  updateUser: (profile: ProfileLineItem) => void;
  inviteUser: (profile: ProfileLineItem) => void;
  handleClose: () => void;
}

export default function UserModal({
  existingUser,
  handleClose,
  inviteUser,
  loading,
  open,
  organisation,
  profiles,
  updateUser,
}: UserModalProps) {
  const onSubmit = async (profile: ProfileLineItem) => {
    if (profile) {
      if (existingUser) {
        updateUser({
          ...existingUser,
          ...profile,
        });
      } else {
        await inviteUser(profile);
      }
      handleClose();
    }
  };

  const title = "Invite User";

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <UserForm
          handleSubmit={onSubmit}
          organisation={organisation}
          existingUser={existingUser}
          profiles={profiles}
          isLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
