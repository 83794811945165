import { useDirectory } from "@hooks/crud/useDirectory";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";

import { CollaboratorTable } from "../Tables/CollaboratorTable/CollaboratorTable";

interface PermitCollaboratorsProps {
  loading: boolean;
  permit?: PermitLineItem;
}
export const PermitCollaborators = ({
  permit,
}: PermitCollaboratorsProps): JSX.Element => {
  const { directory, isDirectoryLoading } = useDirectory(undefined, permit?.id);
  return (
    <CollaboratorTable loading={isDirectoryLoading} profiles={directory} />
  );
};

interface PublicPermitCollaboratorsProps {
  loading: boolean;
  directory: DirectoryContactLineItem[];
}

export const PublicPermitCollaborators = ({
  directory,
  loading,
}: PublicPermitCollaboratorsProps): JSX.Element => {
  return <CollaboratorTable loading={loading} profiles={directory} />;
};
