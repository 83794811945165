"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeConditionDocumentRevision = exports.decodeConditionDocumentRevision = void 0;
var string_1 = require("../utils/string");
var decodeConditionDocumentRevision = function (documentRevision) {
    var safeDocumentRevision = (0, string_1.reversePostgresSafe)(documentRevision);
    return {
        conditionDocumentId: safeDocumentRevision.condition_document_id,
        created: safeDocumentRevision.created,
        createdByName: safeDocumentRevision.created_by_name,
        fileSize: safeDocumentRevision.file_size,
        fileType: safeDocumentRevision.file_type,
        id: safeDocumentRevision.condition_document_revision_id,
        profileId: safeDocumentRevision.profile_id,
        revisionNumber: safeDocumentRevision.revision_number,
        shareConditionId: safeDocumentRevision.share_condition_id,
        url: safeDocumentRevision.url,
    };
};
exports.decodeConditionDocumentRevision = decodeConditionDocumentRevision;
var encodeConditionDocumentRevision = function (document) {
    var safeDocumentRevision = (0, string_1.makePostgresSafe)(document);
    return {
        condition_document_id: safeDocumentRevision.conditionDocumentId,
        condition_document_revision_id: safeDocumentRevision.id,
        created: safeDocumentRevision.created,
        created_by_name: safeDocumentRevision.createdByName,
        file_size: safeDocumentRevision.fileSize,
        file_type: safeDocumentRevision.fileType,
        profile_id: safeDocumentRevision.profileId,
        revision_number: safeDocumentRevision.revisionNumber,
        share_condition_id: safeDocumentRevision.shareConditionId,
        url: safeDocumentRevision.url,
    };
};
exports.encodeConditionDocumentRevision = encodeConditionDocumentRevision;
