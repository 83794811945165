import { DialogContentText, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import React from "react";

import ProgressTable from "./ProgressTable";
import { ReadOnlyCommentTable } from "./ReadOnlyCommentTable";
import { ReadOnlyDocumentTable } from "./ReadOnlyDocumentTable";
import { AcceptModal } from "../AcceptModal/AcceptModal";
import { RejectDialog } from "../RejectModal/RejectModal";
import { SubmitModal } from "../SubmitModal/SubmitModal";

interface ConditionReviewFormProps {
  project: ProjectLineItem;
  permit: PermitLineItem;
  canEdit: boolean;
  condition: ConditionLineItem;
  comments: ConditionCommentLineItem[];
  documents: ConditionDocumentLineItem[];
  userProfile?: ProfileLineItem;
  onSendForReview: () => void;
  onAccept: () => void;
  onReject: (rejectionMessage: string) => void;
  handleBack: () => void;
  handleClose: () => void;
}

export const ConditionReviewForm = ({
  canEdit,
  comments,
  condition,
  documents,
  handleBack,
  handleClose,
  onAccept,
  onReject,
  onSendForReview,
  permit,
  project,
  userProfile,
}: ConditionReviewFormProps) => {
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [openAcceptModal, setOpenAcceptModal] = React.useState(false);
  const [openSubmitModal, setOpenSubmitModal] = React.useState(false);

  const submitters = comments
    .map((c) => {
      return `${c.firstName} ${c.lastName}`;
    })
    .filter((c, i, arr) => i != arr.indexOf(c));

  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "0 0 10px",
        }}
      >
        Review documents and comments
      </DialogContentText>
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
          <Grid container sx={{ padding: "0 " }} spacing={2}>
            <Grid item xs={12} md={8} sx={{ padding: "0 0 20px" }}>
              <Stack spacing={2}>
                <ReadOnlyDocumentTable
                  documentCount={documents.length}
                  conditionDocuments={documents}
                  project={project}
                />
                <ReadOnlyCommentTable conditionComments={comments} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} sx={{ padding: "0 0 20px" }}>
              <Stack spacing={2}>
                <ProgressTable
                  condition={condition}
                  submitter={submitters}
                  documents={documents}
                  comments={comments}
                  approver={permit.approvers}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <StyledButton
              loading={false}
              color="primary"
              variant="outlined"
              sx={{ cursor: "pointer !important" }}
              onClick={handleBack}
            >
              Back
            </StyledButton>
            <StyledButton
              loading={false}
              color="primary"
              variant="outlined"
              sx={{ cursor: "pointer !important" }}
              onClick={handleClose}
            >
              Exit
            </StyledButton>
            {userProfile &&
            canEdit &&
            (condition.status === ConditionStatus.NotAssigned ||
              condition.status === ConditionStatus.Assigned ||
              condition.status === ConditionStatus.Rejected ||
              condition.status === ConditionStatus.Overdue) ? (
              <StyledButton
                loading={false}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ cursor: "pointer !important" }}
                onClick={() => {
                  setOpenSubmitModal(true);
                }}
              >
                Send for Approval
              </StyledButton>
            ) : null}
            {permit?.approvers &&
            canEdit &&
            userProfile &&
            permit.approvers.map((a) => a.id).includes(userProfile.id) &&
            condition?.status === ConditionStatus.InReview ? (
              <>
                <StyledButton
                  loading={false}
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{ cursor: "pointer !important" }}
                  onClick={() => {
                    setOpenAcceptModal(true);
                  }}
                >
                  Accept
                </StyledButton>
                <StyledButton
                  loading={false}
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{ cursor: "pointer !important" }}
                  onClick={() => {
                    setOpenRejectModal(true);
                  }}
                >
                  Reject
                </StyledButton>
              </>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
      {canEdit && openRejectModal ? (
        <RejectDialog
          open={openRejectModal}
          onCancel={(): void => {
            setOpenRejectModal(false);
          }}
          onConfirm={onReject}
        />
      ) : null}
      {openAcceptModal ? (
        <AcceptModal
          open={openAcceptModal}
          onCancel={(): void => {
            setOpenAcceptModal(false);
          }}
          onConfirm={onAccept}
        />
      ) : null}
      {canEdit && openSubmitModal ? (
        <SubmitModal
          open={openSubmitModal}
          onCancel={(): void => {
            setOpenSubmitModal(false);
          }}
          onConfirm={onSendForReview}
        />
      ) : null}
    </>
  );
};
