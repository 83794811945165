import { useTable } from "@hooks/utils/useTable";
import { Grid, Table, TableBody, Box } from "@mui/material";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";

import { TaskTableRow } from "./TaskTableRow";
import { TaskTableRowSkeleton } from "./TaskTableRowSkeleton";

interface TaskTableProps {
  loading: boolean;
  selectedConditionStatus: number;
  conditions: ConditionLineItem[];
  setConditionStatus: (status: number) => void;
  handleViewCondition: (condition: ConditionLineItem) => void;
}
export const TaskTable = ({
  conditions,
  handleViewCondition,
  loading,
  selectedConditionStatus,
  setConditionStatus,
}: TaskTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: false, id: "task-status", label: "Status" },
    { alignRight: false, id: "permit-name", label: "Permit Name" },
    { alignRight: false, id: "condition-name", label: "Permit Type" },
    { alignRight: false, id: "action-required", label: "Condition Details" },
    { alignRight: false, id: "discipline", label: "Discipline" },
    { alignRight: false, id: "approver", label: "Permit Approver" },
    { alignRight: false, id: "manager", label: "Permit Manager" },
    { id: "" },
  ];

  const {
    clearSelected,
    filterName,
    handleFilterByName,
    handleRequestSort,
    handleSelectAllClick,
    order,
    orderBy,
    selected,
  } = useTable();

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            (t.permitName &&
              t.permitName.toLowerCase().includes(filterName.toLowerCase())) ||
            (t.conditionComments &&
              t.conditionComments
                .toLowerCase()
                .includes(filterName.toLowerCase())) ||
            conditonNameToString(t.conditionAuthority, t.permitItemNumber)
              .toLowerCase()
              .includes(filterName.toLowerCase()) ||
            (t.discipline &&
              t.discipline.toLowerCase().includes(filterName.toLowerCase()))
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          !c.markedAsNotApplicable &&
          (c.status === ConditionStatus.Assigned ||
            c.status === ConditionStatus.Rejected)
        );
      case 2:
        return (
          !c.markedAsNotApplicable && c.status === ConditionStatus.InReview
        );
      case 3:
        return (
          !c.markedAsNotApplicable && c.status === ConditionStatus.Approved
        );
      case 4:
        return c.markedAsNotApplicable;
    }
  });

  let sortedConditions = filteredConditions.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.status < b.status ? -1 : 1;
      case 1:
        return a.permitName.toLocaleLowerCase() <
          b.permitName.toLocaleLowerCase()
          ? -1
          : 1;
      case 2:
        return a.permitType.toLocaleLowerCase() <
          b.permitType.toLocaleLowerCase()
          ? -1
          : 1;
      case 3:
        return a.conditionDetails && b.conditionDetails
          ? a.conditionDetails.toLocaleLowerCase() <
            b.conditionDetails.toLocaleLowerCase()
            ? -1
            : 1
          : 1;
      case 5: {
        const aApprovers = a.assignees.map((a) => a.name).join(", ");
        const bApprovers = b.assignees.map((a) => a.name).join(", ");
        return aApprovers < bApprovers ? -1 : 1;
      }
      case 6: {
        const aManagers = a.managers.map((a) => a.name).join(", ");
        const bManagers = b.managers.map((a) => a.name).join(", ");
        return aManagers < bManagers ? -1 : 1;
      }
      default:
        return 0;
    }
  });

  sortedConditions =
    order === "asc" ? sortedConditions : sortedConditions.reverse();

  const isNotFound = !filteredConditions.length && filterName.length;

  return (
    <>
      <Box sx={{ margin: "15px 0 20px", padding: "0" }}>
        <ListTableToolbar
          loading={loading}
          handleOpen={() => {
            console.log("Not imbplemented");
          }}
          searchTitle="Search Tasks..."
          numSelected={selected.length}
          filterName={filterName}
          clearSelected={clearSelected}
          onFilter={handleFilterByName}
        />
      </Box>

      <Grid item md={12} sx={{ padding: "0 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={(newValue: number) => {
            setConditionStatus(newValue);
          }}
        />
      </Grid>

      <Table stickyHeader aria-label="sticky table" className="project-table">
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={filteredConditions.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={(e: any) =>
            handleSelectAllClick(
              e,
              filteredConditions.map((u) => u.id)
            )
          }
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {!loading
            ? sortedConditions
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const selectedTask = selected.indexOf(row.id) !== -1;
                  return (
                    <TaskTableRow
                      key={row.id}
                      condition={row}
                      selectedTask={selectedTask}
                      handleViewCondition={handleViewCondition}
                    />
                  );
                })
            : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <TaskTableRowSkeleton key={index} />
              ))
            : null}
          {isNotFound && filterName.length > 0 ? (
            <NoResultsFoundRow
              colSpan={TABLE_HEAD.length + 1}
              filterName={filterName}
            />
          ) : null}
          {filteredConditions.length === 0 && !loading && !filterName.length ? (
            <NoRows colSpan={TABLE_HEAD.length + 1} title={"Tasks"} />
          ) : null}
        </TableBody>
      </Table>
    </>
  );
};
