import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProject } from "@hooks/crud/useProject";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ListProjectsTabs } from "@stories/molecules/ListProjectsTabs/ListProjectsTabs";
import { PageHeaderWithIcon } from "@stories/organisms/PageHeaderWithIcon/PageHeaderWithIcon";
import ProjectModal from "@stories/organisms/ProjectModal/ProjectModal";
import { ProjectTable } from "@stories/organisms/Tables/ProjectTable/ProjectTable";
import * as RouteHelper from "@utils/routes";
import { PaymentPlan } from "permit-one-common/src/interfaces/organisation";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ListProjects = (): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();
  const [open, setOpen] = useState(false);

  const [selectedProject, setSelectedProject] = useState<
    ProjectLineItem | undefined
  >(undefined);

  const { createProject, isProjectLoading, projects, updateProject } =
    useProject(userProfile);

  const [value, setValue] = useState<number>(0);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setOpen(false);
    setSelectedProject(undefined);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditProject = (project: ProjectLineItem) => {
    setSelectedProject(project);
    handleOpen();
  };

  const handleViewProject = (project: ProjectLineItem) => {
    navigate(RouteHelper.overview(project.id));
  };

  const handleArchiveProject = (project: ProjectLineItem) => {
    console.log("archive project");
    updateProject({ ...project, archived: !project.archived });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const loading = isProjectLoading || isAuthProfileLoading;
  return (
    <>
      <PageHeaderWithIcon
        loading={loading}
        title={`${userProfile?.name} Projects`}
        userProfile={userProfile}
      />
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          margin: "0",
          padding: "0px 40px 0px 40px",
          width: "100%",
        }}
      >
        <Grid item md={2}>
          <StyledTextField
            className=" search"
            loading={loading}
            type="text"
            placeholder="Search Projects..."
            name="filterProject"
            value={inputValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6} />
        <Grid
          item
          md={2}
          sx={{
            alignItems: "right",
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 !important",
          }}
        >
          {userProfile?.isAdmin &&
          userOrganisation &&
          userOrganisation.planType !== PaymentPlan.Free ? (
            <StyledButton
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={handleOpen}
            >
              Add Project
            </StyledButton>
          ) : null}
        </Grid>
      </Grid>
      <Grid item md={12} sx={{ padding: "25px 40px 0" }}>
        <ListProjectsTabs
          loading={loading}
          value={value}
          handleChange={handleChange}
        />
      </Grid>
      <Grid sx={{ padding: "20px 40px 40px" }}>
        <ProjectTable
          projects={projects}
          organisation={userOrganisation}
          profile={userProfile}
          loading={loading}
          handleOpen={handleOpen}
          handleViewProject={handleViewProject}
          handleEditProject={handleEditProject}
          handleArchiveProject={handleArchiveProject}
          searchString={inputValue}
          tabNumber={value}
        />

        {userOrganisation && open ? (
          <ProjectModal
            open={open}
            organisation={userOrganisation}
            handleClose={handleClose}
            createProject={createProject}
            updateProject={updateProject}
            existingProject={selectedProject}
          />
        ) : null}
      </Grid>
    </>
  );
};
