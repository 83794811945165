import { ProfileProvider } from "@hooks/context/useProfileContext";
import useConfig from "@hooks/utils/useConfig";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import * as RouteHelper from "@utils/routes";
import LAYOUT_CONST, { drawerWidth } from "constant";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import "./dashboard.css";

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
  layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ layout, open, theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.sharp,
      }),
    }),
    ...(open && {
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 20,
      },
      [theme.breakpoints.up("md")]: {},
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  })
);

interface MainDashboardLayoutProps {
  children: React.ReactNode;
}
const HomePageLayout = ({ children }: MainDashboardLayoutProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.globalDirectory,
  ]);

  const [isDrawOpen, setIsDrawOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  useEffect(() => {
    const currentPathId =
      routeMatch?.pattern?.path.split("/")[1] || "NO_ID_SELECTED";
    setSelectedItem([currentPathId]);
  }, []);

  const { container, layout } = useConfig();

  const header = (
    <Toolbar
      sx={{
        padding: "0 !important",
        width: "260px",
      }}
    >
      <Header setIsDrawOpen={setIsDrawOpen} isDrawOpen={isDrawOpen} />
    </Toolbar>
  );

  return (
    <ProfileProvider>
      <Box sx={{ display: "flex" }} className="dashboardPage">
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{ background: "transparent" }}
        >
          {header}
        </AppBar>
        {/* drawer */}
        {!isDrawOpen ? (
          <div className="active">
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
              <Sidebar
                isDrawOpen={isDrawOpen}
                selectedItem={selectedItem}
                setIsDrawOpen={setIsDrawOpen}
                setSelectedItem={setSelectedItem}
              />
            )}
          </div>
        ) : (
          <div className="normal">
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
              <Sidebar
                isDrawOpen={isDrawOpen}
                selectedItem={selectedItem}
                setIsDrawOpen={setIsDrawOpen}
                setSelectedItem={setSelectedItem}
              />
            )}
          </div>
        )}

        {/* main content */}
        <Main theme={theme} open={isDrawOpen} layout={layout}>
          <Container
            maxWidth={container ? "lg" : false}
            {...(!container && { sx: { px: { xs: 0 } } })}
          >
            {children}
          </Container>
        </Main>
      </Box>
    </ProfileProvider>
  );
};

export default HomePageLayout;
