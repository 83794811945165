import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScrollToTop from "@stories/atoms/ScrollToTop/ScrollToTop";
import ErrorBoundary from "@views/ErrorBoundary/ErrorBoundary";
import { Amplify, Auth } from "aws-amplify";
import { browserStorage } from "permit-one-common/src/utils/browserStorage";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RouteInfo, getRoutes } from "routes/routes";

import { AuthProvider, useAuthContext } from "./hooks/context/useAuthContext";
import { ThemeProvider } from "./theme/Theme";
import "react-toastify/dist/ReactToastify.css";

const region = "ap-southeast-2";
export const API_END_POINT =
  "https://seo6hbatt8.execute-api.ap-southeast-2.amazonaws.com/dev/";
export const BUCKET_NAME = "storagestack-databucketd8691f4e-1tcmkchowh94l";

export const STRIPE_PUBLIC_KEY = "pk_test_JnqbQaHNT8HAWBT4hEiTQ72N";

Amplify.configure({
  API: {
    endpoints: [
      {
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
        endpoint: API_END_POINT,
        name: "api",
      },
    ],
  },
  Auth: {
    authenticationFlowType: "USER_SRP_AUTH",
    identityPoolId: "ap-southeast-2:9e2ed18e-c008-4365-ba7d-868d1c0631c1",
    mandatorySignIn: true,
    region: region,
    storage: browserStorage,
    userPoolId: "ap-southeast-2_l1QRH4UY8",
    userPoolWebClientId: "2lm8ngom1qrnsh7ds3t1glqt03",
  },
  Storage: {
    AWSS3: {
      bucket: BUCKET_NAME,
      region: region,
    },
    customPrefix: {
      private: "",
      protected: "",
      public: "",
    },
  },
});

const RoutedPath = (index: number, routeInfo: RouteInfo) => {
  const Layout = routeInfo.layout;
  const Component = routeInfo.component;
  return (
    <Route
      key={`route-${index}`}
      index={routeInfo.index}
      path={routeInfo.path}
      element={
        <Layout>
          <Component />
        </Layout>
      }
    />
  );
};

const AuthenticatedRouter = () => {
  const { loggedIn, userProfile } = useAuthContext();
  const routes = getRoutes(loggedIn, userProfile);
  const defaultPath = routes.find((r) => r.index);

  if (!defaultPath) {
    throw Error("No default path defined for this set of routes");
  }
  return (
    <Routes>
      {routes.map((r, index) => RoutedPath(index, r))}
      <Route path="*" element={<Navigate to={defaultPath.path} replace />} />
    </Routes>
  );
};

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <ScrollToTop />
          <AuthProvider>
            <AuthenticatedRouter />
          </AuthProvider>
          <ToastContainer position="bottom-right" />
        </LocalizationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
