import { Box, Button, Skeleton, Stack, Tab, Tabs } from "@mui/material";

interface ListProjectsTabsProps {
  loading: boolean;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const ListProjectsTabs = ({
  handleChange,
  loading,
  value,
}: ListProjectsTabsProps): JSX.Element => {
  return (
    <Box>
      {!loading ? (
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Active" />
          <Tab label="Archived" />
        </Tabs>
      ) : (
        <Stack direction="row" spacing={1}>
          <Skeleton animation="wave" variant="rounded">
            <Button>Acti</Button>
          </Skeleton>
          <Skeleton animation="wave" variant="rounded">
            <Button>Arch</Button>
          </Skeleton>
        </Stack>
      )}
    </Box>
  );
};
