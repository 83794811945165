import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import {
  ConditionLineItem,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionCommentLineItem,
  createDefaultConditionComment,
} from "permit-one-common/src/interfaces/conditionComment";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as Yup from "yup";

interface AddCommentModalProps {
  open: boolean;
  disabled: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  setOpen: (open: boolean) => void;
  createComments: (conditionComments: ConditionCommentLineItem[]) => void;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  comment: Yup.string()
    .required("Comment is required")
    .min(5, "Comment must be at least 5 characters")
    .max(500, "Comment cannot exceed 500 characters"),
});

const AddCommentModal = ({
  condition,
  createComments,
  disabled,
  open,
  permit,
  project,
  setOpen,
  shareCondition,
  userProfile,
}: AddCommentModalProps) => {
  const handleClose = () => setOpen(false);

  const onSubmit = async (values: { comment: string }) => {
    const newcomment = createDefaultConditionComment(
      project,
      permit,
      condition,
      values.comment,
      userProfile,
      shareCondition
    );
    await createComments([newcomment]);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-comment-modal-title"
      aria-describedby="add-comment-modal-description"
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          left: "50%",
          p: 4,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Typography id="add-comment-modal-title" variant="h6" component="h2">
          Add Comment
        </Typography>
        <Formik
          initialValues={{ comment: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, handleSubmit, touched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Field
                as={TextField}
                name="comment"
                label="Comment"
                multiline
                rows={4}
                fullWidth
                disabled={disabled}
                variant="outlined"
                helperText={
                  touched.comment && errors.comment ? errors.comment : ""
                }
                error={touched.comment && Boolean(errors.comment)}
                sx={{ mt: 2 }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{ mr: 1 }}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={disabled}>
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddCommentModal;
