import { TableCell, TableRow, Typography } from "@mui/material";

interface NoResultsFoundRowProps {
  colSpan: number;
  filterName: string;
}
export const NoResultsFoundRow = ({
  colSpan,
  filterName,
}: NoResultsFoundRowProps) => {
  return (
    <TableRow
      className="emptyrow nofoundrow"
      sx={{ textAlign: "center !important" }}
    >
      <TableCell
        align="center"
        colSpan={colSpan}
        sx={{ py: 3, textAlign: "center !important" }}
      >
        <Typography variant="h6" paragraph>
          Not found
        </Typography>

        <Typography variant="body2">
          No results found for &nbsp;
          <strong>&quot;{filterName}&quot;</strong>.
          <br /> Try checking for typos or using complete words.
        </Typography>
      </TableCell>
    </TableRow>
  );
};
