import { LoadingSteps, Steps } from "@stories/atoms/Steps/Steps";

const steps = ["Documents", "Comments", "Review"];

interface ConditionDocumentStepsProps {
  loading: boolean;
  activeStep: number;
}

export const ConditionDocumentSteps = ({
  activeStep,
  loading,
}: ConditionDocumentStepsProps) => {
  if (loading) {
    return <LoadingSteps activeStep={activeStep} steps={steps} />;
  }
  return <Steps activeStep={activeStep} steps={steps} />;
};
