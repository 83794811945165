import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useCondition } from "@hooks/crud/useCondition";
import { useConditionCount } from "@hooks/crud/useConditionCount";
import { usePermit } from "@hooks/crud/usePermit";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { ConditionTabs } from "@stories/molecules/ConditionsTab/ConditionsTab";
import ConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/ConditionDocumentModal";
import ConditionModal from "@stories/organisms/ConditionModal/ConditionModal";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import { PermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import { PermitEventLog } from "@stories/organisms/PermitEventLog/PermitEventLog";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { ConditionTable } from "@stories/organisms/Tables/ConditionTable/ConditionTable";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import * as RouteHelper from "@utils/routes";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

export const Conditions = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { permitId } = useParams();
  const [openBulk, setOpenDocumentModal] = useState(false);

  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const { getPermit, isPermitLoading, selectedPermit } = usePermit(
    undefined,
    permitId
  );

  const {
    conditions,
    createCondition,
    createShareConditions,
    deleteCondition,
    importConditions,
    isConditionLoading,
    isConditionUpdating,
    updateCondition,
  } = useCondition(undefined, permitId);

  const { conditionCounts, isConditionCountLoading, refreshConditionCounts } =
    useConditionCount(selectedProject?.id, permitId);

  const [selectedCondition, setSelectedCondition] = useState<
    ConditionLineItem | undefined
  >(undefined);
  const [open, setOpenConditionModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (!isConditionLoading && searchParams.has("id") && conditions.length) {
      const conditionId = searchParams.get("id");
      const condition = conditions.find((c) => c.id === conditionId);
      if (condition) {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete("id");
        setSearchParams(newSearchParams);
        handleOpenConditionEditModal(condition);
      }
    }
  }, [isConditionLoading, searchParams, conditions]);

  const handleCloseConditionEditModal = () => {
    setOpenConditionModal(false);
    setSelectedCondition(undefined);
  };

  const handleOpenConditionEditModal = (
    selecetedCondition?: ConditionLineItem
  ) => {
    setOpenDocumentModal(false);
    setSelectedCondition(selecetedCondition);
    setOpenConditionModal(true);
  };

  const handleOpenConditionDocumentModal = (condition: ConditionLineItem) => {
    setOpenConditionModal(false);
    setSelectedCondition(condition);
    setOpenDocumentModal(true);
  };

  const handleCloseConditionDocumentModal = () => {
    setOpenDocumentModal(false);
    setSelectedCondition(undefined);
  };

  const handleImportConditons = async (conditions: ConditionLineItem[]) => {
    await importConditions(conditions);
    // setImportedConditions(conditions);
  };

  const handleCreateCondition = (condition: ConditionLineItem) => {
    createCondition(condition).then(async () => {
      if (permitId) {
        await Promise.all([
          getPermit(permitId, true),
          refreshConditionCounts(),
        ]);
      }
    });
  };

  const handleUpdateCondition = (condition: ConditionLineItem) => {
    updateCondition(condition).then(async () => {
      if (permitId) {
        await Promise.all([
          getPermit(permitId, true),
          refreshConditionCounts(),
        ]);
      }
    });
  };

  const handleDeleteCondition = (condition: ConditionLineItem) => {
    deleteCondition(condition).then(async () => {
      if (permitId) {
        await Promise.all([getPermit(permitId), refreshConditionCounts()]);
      }
    });
  };

  const handleShareConditions = async (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string,
    accessLevel: ShareConditionAccessLevel
  ): Promise<void> => {
    await createShareConditions(
      email,
      firstName,
      lastName,
      projectId,
      permitId,
      accessLevel
    );
    await refreshConditionCounts();
  };

  const handleViewCondition = (condition: ConditionLineItem) => {
    if (selectedProject && selectedPermit) {
      navigate(
        RouteHelper.condition(
          selectedProject.id,
          selectedPermit.id,
          condition.id
        )
      );
    } else {
      throw Error("No condition or condition selected");
    }
  };

  const loading =
    isAuthProfileLoading ||
    isProjectLoading ||
    isPermitLoading ||
    isConditionLoading ||
    isConditionCountLoading;

  const disabled = isConditionUpdating;
  const selectedConditionStep = (() => {
    switch (selectedTab) {
      default:
      case 0:
        return (
          <ConditionTable
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
            handleOpenConditionEditModal={handleOpenConditionEditModal}
            loading={loading}
            disabled={disabled}
            profile={userProfile}
            userOrganisation={userOrganisation}
            project={selectedProject}
            permit={selectedPermit}
            conditions={conditions}
            setConditions={handleImportConditons}
            handleImportConditons={handleImportConditons}
            handleViewCondition={handleViewCondition}
            handleEditCondition={handleUpdateCondition}
            handleDeleteCondition={handleDeleteCondition}
            handleShareConditions={handleShareConditions}
          />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 2:
        return (
          <MailCenter
            isLoading={loading}
            project={selectedProject}
            permit={selectedPermit}
            profile={userProfile}
            showDialog={false}
          />
        );
      case 3:
        return (
          <PermitCollaborators loading={loading} permit={selectedPermit} />
        );
      case 4:
        return <PermitEventLog loading={loading} permit={selectedPermit} />;
      case 5:
        return (
          <InspectionTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
    }
  })();

  const handlOnBack = () => {
    if (selectedProject) {
      navigate(RouteHelper.permits(selectedProject.id));
    }
  };

  const canEdit =
    (selectedProject &&
      userOrganisation &&
      selectedProject?.organisationId === userOrganisation?.id) ||
    false;

  const existingIds = new Map<ConditionAuthority, number[]>();
  conditions.forEach((condition) => {
    if (condition.conditionAuthority in existingIds) {
      existingIds
        .get(condition.conditionAuthority)
        ?.push(condition.permitItemNumber);
    } else {
      existingIds.set(condition.conditionAuthority, [
        condition.permitItemNumber,
      ]);
    }
  });
  return (
    <Box>
      {loading ? (
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            alignItems: "center",
            display: "flex",
            margin: "0 0 40px",
            padding: "20px 40px 20px 40px",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{ alignItems: "center", padding: "0px !important" }}
            md={6}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton
                  size="large"
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: "22px !important",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="borderBottom"
            container
            spacing={2}
            sx={{
              alignItems: "center",
              display: "flex",
              margin: "0 0 40px",
              padding: "20px 40px 20px 40px",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{ alignItems: "center", padding: "0px !important" }}
              md={6}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back"
                >
                  <IconButton
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    className="visited-link"
                    style={{
                      color: "#00000",
                      fontSize: "22px",
                      textDecoration: "none",
                    }}
                    to={RouteHelper.permits(selectedProject?.id || "")}
                  >
                    {selectedProject?.projectName}
                  </Link>
                  <Typography color="text.primary">
                    {selectedPermit?.permitName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      <Box sx={{ padding: "10px 40px 30px" }}>
        <ConditionSummary
          conditionCounts={conditionCounts}
          project={selectedProject}
          permitId={permitId}
          loading={loading}
        />
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            typography: "body1",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              borderRadius: "0",
              display: "flex",
              margin: "40px 0 30px",
              padding: "0",
              width: "100%",
            }}
          >
            <Grid item md={12} sx={{ padding: "0 0 20px !important" }}>
              <ConditionTabs
                loading={loading}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </Grid>
            <Grid item md={12} sx={{ padding: "0 !important" }}>
              {selectedConditionStep}
            </Grid>
          </Grid>
        </Box>

        {selectedProject && selectedPermit ? (
          <ConditionModal
            open={open}
            handleOpenConditionDocumentModal={
              selectedCondition ? handleOpenConditionDocumentModal : undefined
            }
            project={selectedProject}
            permit={selectedPermit}
            permitItemNumbers={existingIds}
            conditionCount={conditions.length}
            handleClose={handleCloseConditionEditModal}
            createCondition={handleCreateCondition}
            updateCondition={handleUpdateCondition}
            existingCondition={selectedCondition}
          />
        ) : null}
        {userProfile &&
        selectedProject &&
        selectedPermit &&
        selectedCondition &&
        openBulk ? (
          <ConditionDocumentModal
            open={openBulk}
            canEdit={canEdit}
            project={selectedProject}
            permit={selectedPermit}
            condition={selectedCondition}
            userProfile={userProfile}
            handleClose={handleCloseConditionDocumentModal}
            handleEditCondition={handleUpdateCondition}
          />
        ) : null}
      </Box>
    </Box>
  );
};
