import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import { Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionStatus } from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { RejectDialog } from "../RejectDialog/RejectDialog";

export interface ConditionControlProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  handleRequestReview: () => void;
  handleReject: (rejectionMessage: string) => void;
  handleApproval: () => void;
}

export const ConditionControl = ({
  condition,
  handleApproval,
  handleReject,
  handleRequestReview,
  loading,
  permit,
  userProfile,
}: ConditionControlProps): JSX.Element => {
  const [isReviewModelOpen, setIsReviewModelOpen] = useState(false);
  const [isRejectModelOpen, setIsRejectModelOpen] = useState(false);
  const [isApproveModelOpen, setIsApproveModelOpen] = useState(false);

  let control = null;
  if (
    condition?.assignees &&
    userProfile &&
    condition.assignees.map((a) => a.id).includes(userProfile.id)
  ) {
    if (
      condition.status === ConditionStatus.Assigned ||
      condition.status === ConditionStatus.Rejected ||
      condition.status === ConditionStatus.Overdue
    ) {
      control = (
        <StyledButton
          loading={loading}
          variant="contained"
          startIcon={<PublishIcon />}
          onClick={() => {
            setIsReviewModelOpen(true);
          }}
        >
          Request Review
        </StyledButton>
      );
    }
  }

  if (
    permit?.approvers &&
    userProfile &&
    permit.approvers.map((a) => a.id).includes(userProfile.id)
  ) {
    if (condition?.status === ConditionStatus.InReview) {
      control = (
        <>
          <StyledButton
            loading={loading}
            variant="contained"
            startIcon={<CloseIcon />}
            color="error"
            onClick={() => {
              setIsRejectModelOpen(true);
            }}
          >
            Reject
          </StyledButton>
          <StyledButton
            loading={loading}
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => {
              setIsApproveModelOpen(true);
            }}
          >
            Approve
          </StyledButton>
        </>
      );
    }
  }
  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          display: "flex !important",
          justifyContent: "right !important;",
        }}
      >
        {control}
      </Stack>
      <RejectDialog
        open={isRejectModelOpen}
        title={"Reject Condition"}
        onCancel={() => {
          setIsRejectModelOpen(false);
        }}
        onConfirm={async (rejectionMessage: string) => {
          await handleReject(rejectionMessage);
          setIsRejectModelOpen(false);
        }}
      />
      <ConfirmationDialog
        message={"Are you sure you want to approve this condition?"}
        open={isApproveModelOpen}
        title={"Approve Condition"}
        intent={"info"}
        onCancel={() => {
          setIsApproveModelOpen(false);
        }}
        onConfirm={async () => {
          await handleApproval();
          setIsApproveModelOpen(false);
        }}
      />
      <ConfirmationDialog
        message={"Are you sure this condition is ready for review?"}
        open={isReviewModelOpen}
        title={"Request Review"}
        intent={"info"}
        onCancel={() => {
          setIsReviewModelOpen(false);
        }}
        onConfirm={async () => {
          await handleRequestReview();
          setIsReviewModelOpen(false);
        }}
      />
    </>
  );
};
