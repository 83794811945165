import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import {
  PermitFeeLineItem,
  createDefaultPermitFee,
} from "permit-one-common/src/interfaces/permitFee";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import * as React from "react";

import { FeeForm } from "../FeeForm/FeeForm";

interface FeeModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  createFee: (fees: PermitFeeLineItem) => void;
  handleClose: () => void;
}

export default function FeeModal({
  createFee,
  handleClose,
  open,
  permit,
  project,
}: FeeModalProps) {
  const onSubmit = async (fee: PermitFeeLineItem) => {
    await createFee(fee);
    handleClose();
  };
  const title = "Create Fee";
  return (
    <div>
     <Dialog fullWidth open={open} onClose={handleClose} className="bgBlueOverlay">
        <DialogTitle
          sx={{ fontSize: "20px !important", padding: "30px 35px 25px" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                background: "#005cd1",
              },
              background: "#000",
              color: "#fff",
              position: "absolute",
              right: 14,
              top: 11,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px 35px 40px" }}>
          <FeeForm
            project={project}
            permit={permit}
            fee={createDefaultPermitFee(project, permit)}
            handleSubmit={onSubmit}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
