import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { useState } from "react";
interface CommentTableRowProps {
  disabled: boolean;
  conditionComment: ConditionCommentLineItem;
  canEdit: boolean;
  deleteComment: (comment: ConditionCommentLineItem) => void;
}

export const CommentTableRow = ({
  canEdit,
  conditionComment,
  deleteComment,
  disabled,
}: CommentTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <TableRow>
      <TableCell>{conditionComment.comment}</TableCell>
      <TableCell>{`${conditionComment.firstName} ${conditionComment.lastName}`}</TableCell>
      <TableCell align="right" sx={{ textAlign: "center !important" }}>
        {canEdit ? (
          <IconButton
            disabled={disabled}
            color="error"
            size="large"
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon
              sx={{
                fontSize: "1.1rem",
              }}
            />
          </IconButton>
        ) : null}
      </TableCell>
      {openConfirmDialog ? (
        <ConfirmationDialog
          message={"Do you want to delete this comment?"}
          open={openConfirmDialog}
          title={"Delete document"}
          intent={"error"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => {
            deleteComment(conditionComment);
            setOpenConfirmDialog(false);
          }}
        />
      ) : null}
    </TableRow>
  );
};
