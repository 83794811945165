import { useTable } from "@hooks/utils/useTable";
import { Grid } from "@mui/material";
import PublicEditableTable from "@stories/atoms/PublicEditableTable/PublicEditableTable";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";
import PublicConditionTableToolbar from "@stories/molecules/ListTable/PublicConditionTableToolbar";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";
import {
  ConditionLineItem,
  ConditionStatus,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { stripTags } from "permit-one-common/src/utils/string";
import { useEffect, useState } from "react";

interface PublicConditionTableProps {
  loading: boolean;
  disabled: boolean;
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  documents: ConditionDocumentLineItem[];
  comments: ConditionCommentLineItem[];
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  updateDocuments: (document: ConditionDocumentLineItem[]) => void;
  deleteComment: (comment: ConditionCommentLineItem) => void;
  sendForReview: (condition: ConditionLineItem) => void;
  uploadDocument: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  showToolbar?: boolean;
  downloadStepPDF: FileGenerationStatus;
  PDFDownloadProgress: number;
  getPdfReport: (projectId: string, permitId: string) => Promise<void>;
  getExcelReport: (projectId: string, permitId: string) => Promise<void>;
  handleEditCondition: (condition: ConditionLineItem) => void;
  canEdit: boolean;
  downloadStepExcel: FileGenerationStatus;
  excelDownloadProgress: number;
  getFullReportInfo: (projectId: string, permitId: string) => Promise<void>;
  downloadStepAllFiles: FileGenerationStatus;
  allFilesDownloadProgress: number;
  shareCondition?: ShareConditionLineItem;
}
export const PublicConditionTable = ({
  PDFDownloadProgress,
  allFilesDownloadProgress,
  canEdit,
  comments,
  conditions,
  createComments,
  createDocuments,
  deleteComment,
  deleteDocument,
  disabled,
  documents,
  downloadStepAllFiles,
  downloadStepExcel,
  downloadStepPDF,
  excelDownloadProgress,
  getExcelReport,
  getFullReportInfo,
  getPdfReport,
  handleEditCondition,
  loading,
  permit,
  project,
  sendForReview,
  shareCondition,
  showToolbar = true,
  updateDocuments,
  uploadDocument,
  userOrganisation,
  userProfile,
}: PublicConditionTableProps) => {
  const params = new URLSearchParams(location.search);
  const initialConditionStatus = parseInt(params.get("status") || "0");

  const { filterName, handleFilterByName, handleRequestSort, order, orderBy } =
    useTable();

  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<number>(initialConditionStatus);

  useEffect(() => {
    setSelectedConditionStatus(initialConditionStatus);
  }, [initialConditionStatus]);

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedConditionStatus(newValue);
  };

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            (t.conditionDetails &&
              t.conditionDetails
                .toLowerCase()
                .includes(filterName.toLowerCase())) ||
            (t.conditionComments &&
              t.conditionComments
                .toLowerCase()
                .includes(filterName.toLowerCase())) ||
            (t.discipline &&
              t.discipline.toLowerCase().includes(filterName.toLowerCase())) ||
            conditonNameToString(t.conditionAuthority, t.permitItemNumber)
              .toLowerCase()
              .includes(filterName.toLowerCase())
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          c.status === ConditionStatus.Assigned ||
          c.status === ConditionStatus.Rejected
        );
      case 2:
        return c.status === ConditionStatus.InReview;
      case 3:
        return c.status === ConditionStatus.Approved;
      case 4:
        return c.status === ConditionStatus.Overdue;
      case 5:
        return c.status === ConditionStatus.NotApplicable;
    }
  });

  let sortedConditions = filteredConditions.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return conditonNameToString(
          a.conditionAuthority,
          a.permitItemNumber
        ).localeCompare(
          conditonNameToString(b.conditionAuthority, b.permitItemNumber)
        );
      case 1:
        return stripTags(
          a?.conditionDetails?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.conditionDetails?.toLocaleLowerCase() || "")
        );
      case 2:
        return stripTags(
          a?.conditionComments?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.conditionComments?.toLocaleLowerCase() || "")
        );
      case 3:
        return stripTags(a.discipline).localeCompare(stripTags(b.discipline));
      case 4:
        return stripTags(
          a?.assigneeComments?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.assigneeComments?.toLocaleLowerCase() || "")
        );
      case 5:
        return a.status < b.status ? -1 : 1;
      default:
        return 0;
    }
  });
  sortedConditions =
    order === "asc" ? sortedConditions : sortedConditions.reverse();

  return (
    <>
      {showToolbar ? (
        <PublicConditionTableToolbar
          loading={loading}
          disabled={disabled}
          profile={userProfile}
          userOrganisation={userOrganisation}
          project={project}
          permit={permit}
          filterName={filterName}
          onFilter={handleFilterByName}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getPdfReport={getPdfReport}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}
      <Grid item md={12} sx={{ padding: "30px 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={handleConditionStatusChange}
        />
      </Grid>
      <PublicEditableTable
        loading={loading}
        disabled={disabled}
        project={project}
        permit={permit}
        canEdit={canEdit}
        conditions={sortedConditions}
        documents={documents}
        comments={comments}
        createDocuments={createDocuments}
        createComments={createComments}
        deleteComment={deleteComment}
        deleteDocument={deleteDocument}
        updateDocuments={updateDocuments}
        sendForReview={sendForReview}
        handleEditCondition={handleEditCondition}
        uploadDocument={uploadDocument}
        shareCondition={shareCondition}
        order={order}
        orderBy={orderBy}
        selected={[]}
        handleRequestSort={handleRequestSort}
      />
    </>
  );
};
