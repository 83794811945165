import { useProfile } from "@hooks/crud/useProfile";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { UserFinder } from "@stories/molecules/UserFinder/UserFinder";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface InviteFormProps {
  handleSubmit: (profiles: ProfileLineItem[]) => void;
  handleClose: () => void;
}

export const InviteForm = ({ handleSubmit }: InviteFormProps) => {
  const { searchProfiles } = useProfile();

  const onInviteChange = (_?: ProfileLineItem) => {
    console.log("Not implemented");
  };

  const onInviteBlur = () => {
    console.log("Not implemented");
  };

  const onInviteSubmit = () => {
    handleSubmit([]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sm={12}>
          <UserFinder
            id="manager"
            name="manager"
            label="Search users"
            disabled={false}
            onChange={onInviteChange}
            onBlur={onInviteBlur}
            handleSearch={searchProfiles}
            existingProfile={undefined}
            error={undefined}
            helperText={undefined}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "flex-end",
          }}
        >
          <StyledButton
            loading={false}
            color="primary"
            variant="contained"
            onClick={onInviteSubmit}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </Container>
  );
};
