import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

export interface AcceptModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const AcceptModal = ({
  onCancel,
  onConfirm,
  open,
}: AcceptModalProps) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle>{"Accept Submission"}</DialogTitle>
      <DialogContent>
        <Typography>
          {"This action will mark the condition as completed."}
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton
          id="confirm-dialog-button-cancel"
          loading={false}
          onClick={onCancel}
          variant="outlined"
        >
          {"Cancel"}
        </StyledButton>
        <StyledButton
          data-test-id="confirm-dialog-button-ok"
          loading={false}
          onClick={() => onConfirm()}
          sx={{
            background: "#4bb543 !important",
            color: "#fff",
          }}
        >
          {"Accept"}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
