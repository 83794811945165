import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";

import { CommentTableRow } from "./CommentTableRow";
import { CommentTableRowSkeleton } from "./CommentTableRowSkeleton";

interface CommentTableProps {
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
  conditionComments: ConditionCommentLineItem[];
  deleteComment: (comment: ConditionCommentLineItem) => void;
}

export const CommentTable = ({
  canEdit,
  conditionComments,
  deleteComment,
  disabled,
  loading,
}: CommentTableProps) => {
  return (
    <Table aria-label="sticky table" className="project-table popup-input">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "70%" }}>Comment</TableCell>
          <TableCell sx={{ width: "20%" }}>By</TableCell>
          {canEdit ? <TableCell sx={{ width: "10%" }} /> : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <CommentTableRowSkeleton key={index} />
            ))
          : null}
        {!loading
          ? conditionComments.map((conditionComment) => (
              <CommentTableRow
                canEdit={canEdit}
                key={conditionComment.id}
                disabled={disabled}
                conditionComment={conditionComment}
                deleteComment={deleteComment}
              />
            ))
          : null}
        {!loading && conditionComments.length === 0 ? (
          <NoRows colSpan={3} title={"comments"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
