import { usePublicConditions } from "@hooks/crud/usePublicConditions";
import {
  Box,
  Breadcrumbs,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ConditionTabs } from "@stories/molecules/ConditionsTab/ConditionsTab";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import { PublicPermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import { PublicPermitEventLog } from "@stories/organisms/PermitEventLog/PublicPermitEventLog";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { PublicConditionSummary } from "@stories/organisms/Summary/PublicConditionSummary";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import { PublicConditionTable } from "@stories/organisms/Tables/PublicConditionTable/PublicConditionTable";
import {
  ConditionLineItem,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const PublicConditions = (): JSX.Element => {
  const { accessId } = useParams();

  const {
    PDFDownloadProgress,
    allFilesDownloadProgress,
    createComments,
    createDocuments,
    deleteComment,
    deleteDocument,
    downloadStepAllFiles,
    downloadStepExcel,
    downloadStepPDF,
    excelDownloadProgress,
    getExcelReport,
    getFullReportInfo,
    getPdfReport,
    getPublicConditonModel,
    isConditionLoading,
    isConditionUpdating,
    publicConditionPageModel,
    sendForReview,
    updateCondition,
    updateDocuments,
    uploadDocument,
  } = usePublicConditions(accessId);

  const handleUpdateCondition = (condition: ConditionLineItem) => {
    updateCondition(condition).then(() => {
      if (accessId) {
        getPublicConditonModel(accessId, true);
      }
    });
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const loading = isConditionLoading;

  const canEdit =
    publicConditionPageModel?.shareCondition.accessLevel ===
    ShareConditionAccessLevel.Write;
  const selectedConditionStep = (() => {
    switch (selectedTab) {
      default:
      case 0:
        return (
          <PublicConditionTable
            loading={loading}
            canEdit={canEdit}
            disabled={isConditionUpdating}
            shareCondition={publicConditionPageModel?.shareCondition}
            userProfile={publicConditionPageModel?.userProfile}
            userOrganisation={publicConditionPageModel?.userOrganisation}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            conditions={publicConditionPageModel?.conditions || []}
            documents={publicConditionPageModel?.documents || []}
            comments={publicConditionPageModel?.comments || []}
            createDocuments={createDocuments}
            createComments={createComments}
            deleteComment={deleteComment}
            deleteDocument={deleteDocument}
            updateDocuments={updateDocuments}
            sendForReview={sendForReview}
            handleEditCondition={handleUpdateCondition}
            uploadDocument={uploadDocument}
            downloadStepPDF={downloadStepPDF}
            PDFDownloadProgress={PDFDownloadProgress}
            getPdfReport={getPdfReport}
            getExcelReport={getExcelReport}
            downloadStepExcel={downloadStepExcel}
            excelDownloadProgress={excelDownloadProgress}
            getFullReportInfo={getFullReportInfo}
            downloadStepAllFiles={downloadStepAllFiles}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 2:
        return (
          <MailCenter
            isLoading={loading}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            profile={publicConditionPageModel?.userProfile}
            showDialog={false}
          />
        );
      case 3:
        return (
          <PublicPermitCollaborators
            loading={loading}
            directory={publicConditionPageModel?.directory || []}
          />
        );
      case 4:
        return (
          <PublicPermitEventLog
            loading={loading}
            permit={publicConditionPageModel?.permit}
            permitEvents={publicConditionPageModel?.activity || []}
          />
        );
      case 5:
        return (
          <InspectionTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
    }
  })();

  return (
    <Box>
      {loading ? (
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            alignItems: "center",
            display: "flex",
            margin: "0 0 40px",
            padding: "20px 40px 20px 40px",
            width: "100%",
          }}
        >
          <Grid
            item
            sx={{ alignItems: "center", padding: "0px !important" }}
            md={6}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: "22px !important",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="borderBottom"
            container
            spacing={2}
            sx={{
              alignItems: "center",
              display: "flex",
              margin: "0 0 40px",
              padding: "20px 40px 20px 40px",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{ alignItems: "center", padding: "0px !important" }}
              md={6}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="text.primary">
                    {publicConditionPageModel?.project?.projectName}
                  </Typography>
                  <Typography color="text.primary">
                    {publicConditionPageModel?.permit?.permitName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      <Box sx={{ padding: "10px 40px 30px" }}>
        <PublicConditionSummary
          conditionCounts={publicConditionPageModel?.conditionCounts}
          shareConditionId={publicConditionPageModel?.shareCondition.id}
          loading={loading}
        />
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            typography: "body1",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              borderRadius: "0",
              display: "flex",
              margin: "40px 0 30px",
              padding: "0",
              width: "100%",
            }}
          >
            <Grid item md={12} sx={{ padding: "0 0 20px !important" }}>
              <ConditionTabs
                loading={loading}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </Grid>
            <Grid item md={12} sx={{ padding: "0 !important" }}>
              {selectedConditionStep}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
