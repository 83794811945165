import { getApiData } from "@hooks/utils/api";
import { decodeBillingPortal, decodePaymentIntent } from "permit-one-common/src/decoders/stripe";
import { PaymentIntent } from "permit-one-common/src/entities/stripe";
import {
  BillingPortal,
  BillingPortalLineItem,
  PaymentIntentLineItem,
} from "permit-one-common/src/interfaces/stripe";
import * as React from "react";

export const useStripe = () => {
  const [isStripeLoading, setIsStripeLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const isLocalHost = window.location.hostname.includes("localhost");

  const getPaymentIntent = async (
    priceId: string
  ): Promise<PaymentIntentLineItem | null> => {
    try {
      setIsStripeLoading(true);
      const paymentIntent = await getApiData(
        "createPaymentIntent",
        "checkout",
        priceId,
        {
          isLocalHost: isLocalHost ? "true" : undefined,
        }
      );
      return decodePaymentIntent(paymentIntent.data as PaymentIntent);
    } catch (e: any) {
      setError("Could not fetch condition");
      return null;
    } finally {
      setIsStripeLoading(false);
    }
  };

  const createBillingPortalSession =
    async (): Promise<BillingPortalLineItem | null> => {
      try {
        setIsStripeLoading(true);
        const paymentIntent = await getApiData(
          "createPortalSession",
          "checkout",
          "",
          {
            isLocalHost: isLocalHost ? "true" : undefined,
          }
        );
        return decodeBillingPortal(paymentIntent.data as BillingPortal);
      } catch (e: any) {
        setError("Could not fetch condition");
        return null;
      } finally {
        setIsStripeLoading(false);
      }
    };

  return {
    createBillingPortalSession,
    error,
    getPaymentIntent,
    isStripeLoading,
  };
};
