import { useAuthContext } from "@hooks/context/useAuthContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  TextField,
  FormHelperText,
  Grid,
} from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

interface ForgotPasswordSubmitForm {
  emailAddress: string;
}
const ForgotPasswordSubmitForm = ({
  emailAddress,
}: ForgotPasswordSubmitForm) => {
  const navigate = useNavigate();
  const { forgotPasswordSubmit, isAuthLoading, isAuthenticated } =
    useAuthContext();

  const handleForgotPasswordSubmit = (code: string, password: string) => {
    forgotPasswordSubmit(emailAddress, code, password)
      .then(() => {
        isAuthenticated();
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid code link!");
      });
  };

  return (
    <Formik
      initialValues={{
        code: "",
        confirmPassword: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required("Code is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Required"),
        password: Yup.string()
          .required("Password is required")
          .min(
            8,
            "Must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
          )
          .minLowercase(1, "Must contain at least 1 lower case letter")
          .minUppercase(1, "Must contain at least 1 upper case letter")
          .minNumbers(1, "Must contain at least 1 number")
          .minSymbols(1, "Must contain at least 1 special character"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await handleForgotPasswordSubmit(values.code, values.password);

          setStatus({ success: true });
          setSubmitting(false);

          toast(`Password reset!`, {
            type: "success",
          } as ToastOptions);
          navigate(RouteHelper.login);
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong!", { type: "error" } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.code && errors.code)}
              >
                <TextField
                  id="code"
                  type="text"
                  name="code"
                  label="Enter code to continue"
                  placeholder="XXXXXX"
                  value={values.code}
                  disabled={isAuthLoading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.code && errors.code && (
                  <FormHelperText error>{errors.code}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
              >
                <TextField
                  id="password"
                  type="password"
                  label="New password"
                  name="password"
                  value={values.password}
                  disabled={isAuthLoading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
              >
                <TextField
                  id="confirmPassword"
                  type="password"
                  label="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  disabled={isAuthLoading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error>
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box
                  sx={{ justifyContent: "center", margin: "auto", mt: 3 }}
                 />
                <Box sx={{ fontFamily: "Roboto", mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ fontFamily: "Roboto", textAlign: "center" }}>
                <LoadingButton
                  className="signin-btn"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting || isAuthLoading}
                  sx={{
                    "&:hover": {
                      background: "#05214B !important",
                    },
                    background: "#0054D1 !important",
                    opacity: "1",
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <a href="/login">Go to login?</a>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordSubmitForm;
