// ----------------------------------------------------------------------

import { Theme } from "@mui/material";

export default function Table(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
        },
      },
    },
  };
}
