import { Avatar, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";

interface CollaboratorTableRowProps {
  loading: boolean;
  selected: boolean;
  collaborator: DirectoryContactLineItem;
}

export const CollaboratorTableRow = ({
  collaborator,
  selected,
}: CollaboratorTableRowProps) => {
  const displayName = `${collaborator.firstName} ${collaborator.lastName}`;

  return (
    <>
      <TableRow tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center" sx={{ width: "20%" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {collaborator.photoUrl ? (
              <Avatar
                alt={collaborator.name}
                src={collaborator.photoUrl}
                sx={{ background: "#fff", marginRight: "10px" }}
              />
            ) : null}
            <Typography sx={{ margin: "10px" }}>{displayName}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ width: "20%" }}>
          {collaborator.organisationName}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          {collaborator.accessLevel}
        </TableCell>
        <TableCell
          align="left"
          sx={{ textAlign: "left !important", width: "20%" }}
        >
          {collaborator.email}
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          {collaborator.phoneNumber}
        </TableCell>
      </TableRow>
    </>
  );
};
