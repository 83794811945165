import { UploadStatus } from "@hooks/utils/useUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DialogContentText, Skeleton, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import {
  ConditionLineItem,
  ShareConditionLineItem,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  createDefaultConditionDocument,
} from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as yup from "yup";

import { SelectedDocumentsRow } from "./SelectedDocumentsRow";
import { UploadModal } from "./UploadModal";


interface ConditionDocumentFormProps {
  project: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile?: ProfileLineItem;
  shareCondition?: ShareConditionLineItem;
  documentCount: number;
  documents: ConditionDocumentLineItem[];
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  createDocuments: (documents: ConditionDocumentLineItem[]) => void;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  handleSubmit: (permit: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
  uploadFile: (
    location: string,
    body: File,
    onProgress: (progress: any) => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const ConditionDocumentForm = ({
  canEdit,
  condition,
  createDocuments,
  deleteDocument,
  disabled,
  documentCount,
  documents,
  handleClose,
  handleSubmit,
  loading,
  permit,
  project,
  shareCondition,
  updateDocuments,
  uploadFile,
  userProfile,
}: ConditionDocumentFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const validationSchema = yup.object({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async () => {
      if (documents.length > 0) {
        await handleSubmit(documents);
      }
    },
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      project &&
      permit &&
      condition &&
      e.currentTarget &&
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0
    ) {
      const documents: ConditionDocumentLineItem[] = [];
      const files = Array.from(e.currentTarget.files);
      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];
        setCurrentUploadFile(currentFile.name);
        setUploadStatus(UploadStatus.Uploading);
        setUploadProgress(0);

        const fileKey = `documents/${project?.id}/${condition?.id || 0}/${
          currentFile.name
        }`;
        await uploadFile(
          fileKey,
          currentFile,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
          },
          (error: any) => {
            console.log(error);
            setUploadProgress(0);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
          }
        );

        let name = userProfile
          ? `${userProfile.firstName} ${userProfile.lastName}`
          : "";
        name = shareCondition
          ? `${shareCondition.firstName} ${shareCondition.lastName}`
          : name;
        const newDocument = createDefaultConditionDocument(
          project.id,
          currentFile,
          name,
          documentCount + i + documents.length,
          fileKey,
          project,
          permit,
          condition,
          userProfile?.id,
          shareCondition?.id
        );
        documents.push(newDocument);
        setUploadedIndices([...uploadedIndice, i]);
      }

      setCurrentUploadFile(undefined);
      setUploadStatus(UploadStatus.Saving);
      await createDocuments(documents);
      setUploadStatus(UploadStatus.Idle);
    }
  };

  const formDisabled =
    formik.isSubmitting ||
    uploadStatus !== UploadStatus.Idle ||
    disabled ||
    loading;
  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "0 0 10px",
        }}
      >
        {loading ? (
          <Skeleton animation="wave">
            <Typography>
              Select one or many files to upload to the document register
            </Typography>
          </Skeleton>
        ) : canEdit ? (
          "Select one or many files to upload to the document register"
        ) : (
          "View uploaded documents"
        )}
      </DialogContentText>
      <Grid container sx={{ padding: "0 " }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            alignContent: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 0 20px",
          }}
        >
          {canEdit ? (
            <Button
              // className="gray-hover-button"
              variant="contained"
              disabled={formDisabled}
              onClick={onFileInput}
              // sx={{
              //   background: "transparent !important",
              //   border: "1px solid rgba(145, 158, 171, 0.32) !important",
              //   color: "black !important",
              //   gap: "10px",
              //   "&:hover": {
              //     borderColor: "black",
              //     backgroundColor: "#F2F2F2 !important",
              //   },
              //   padding: "7px 10px",
              // }}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                multiple
                type="file"
                onChange={handleSelectFiles}
              />
              <CloudUploadIcon sx={{ marginRight: "10px" }} /> Upload Documents
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ padding: "0 " }}>
          <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
            <SelectedDocumentsRow
              canEdit={canEdit}
              project={project}
              documentCount={documentCount + documents.length}
              conditionDocuments={documents}
              removeFile={deleteDocument}
              updateDocuments={updateDocuments}
              loading={loading}
              disabled={disabled}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <StyledButton
                loading={false}
                disabled={formDisabled}
                variant="outlined"
                sx={{ cursor: "pointer !important" }}
                onClick={handleClose}
              >
                Exit
              </StyledButton>
              <StyledButton
                loading={false}
                disabled={formDisabled || documents.length == 0}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ cursor: "pointer !important" }}
              >
                Continue
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {canEdit ? (
        <UploadModal
          total={uploadProgress}
          uploadStatus={uploadStatus}
          currentDocumentName={currentUploadFile}
        />
      ) : null}
    </>
  );
};
