import { useOrganisation } from "@hooks/crud/useOrganisation";
import { useProfile } from "@hooks/crud/useProfile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import * as React from "react";

import { useAuthContext } from "./useAuthContext";

type PC = {
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  error?: string;
  isAuthProfileLoading: boolean;
  createUserProfileAndOrganisation: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem
  ) => Promise<void>;
  createUserProfile: (profile: ProfileLineItem) => Promise<void>;
  updateUserProfile: (profile: ProfileLineItem) => Promise<void>;
  updateUserOrganisation: (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem
  ) => Promise<void>;
  signOut: () => Promise<void>;
};

export const ProfileContext = React.createContext<PC>({
  createUserProfile: () => Promise.resolve(),
  createUserProfileAndOrganisation: () => Promise.resolve(),
  error: "Context not initialised correctly.",
  isAuthProfileLoading: false,
  signOut: () => Promise.resolve(),
  updateUserOrganisation: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  userOrganisation: undefined,
  userProfile: undefined,
});

type ProjectProviderProps = {
  children: React.ReactNode;
};

const ProfileProvider = (props: ProjectProviderProps) => {
  const { isAuthLoading, setUserProfile, signOut, userProfile } =
    useAuthContext();

  const { createProfile, updateProfile } = useProfile();
  const {
    createOrganisation,
    getProfileOrganisation,
    isOrganisationLoading,
    updateOrganisation,
    userOrganisation,
  } = useOrganisation();

  const createUserProfileAndOrganisation = async (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem
  ) => {
    await createOrganisation(organisation);
    const userProfile = await createProfile({
      ...profile,
      organisationId: organisation.id,
    });
    setUserProfile(userProfile);
  };

  const createUserProfile = async (profile: ProfileLineItem) => {
    const userProfile = await createProfile({
      ...profile,
      organisationId: undefined,
    });
    setUserProfile(userProfile);
  };

  const updateUserProfile = async (profile: ProfileLineItem) => {
    await updateProfile(profile);
    setUserProfile(profile);
  };

  const updateUserOrganisation = async (
    organisation: OrganisationLineItem,
    profile: ProfileLineItem
  ) => {
    if (profile.isAdmin) {
      await updateOrganisation(organisation);
    }
  };

  React.useEffect(() => {
    if (userProfile && userProfile.organisationId) {
      getProfileOrganisation(userProfile.organisationId);
    }
  }, [userProfile]);

  return (
    <ProfileContext.Provider
      value={{
        createUserProfile,
        createUserProfileAndOrganisation,
        isAuthProfileLoading: isAuthLoading || isOrganisationLoading,
        signOut,
        updateUserOrganisation,
        updateUserProfile,
        userOrganisation,
        userProfile,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => React.useContext(ProfileContext);

export { ProfileProvider, useProfileContext };
