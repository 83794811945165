import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { OrganisationForm } from "@stories/organisms/OrganisationForm/OrganisationForm";
import { PageHeaderWithIcon } from "@stories/organisms/PageHeaderWithIcon/PageHeaderWithIcon";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";

export const BusinessAccount = () => {
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    updateUserOrganisation,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmit = async (organisation: OrganisationLineItem) => {
    if (userProfile) {
      await updateUserOrganisation(organisation, userProfile);
    }
  };

  const loading = isAuthProfileLoading;
  return (
    <>
      <PageHeaderWithIcon
        loading={loading}
        title="Business Account"
        userProfile={userProfile}
      />

      <OrganisationForm
        organisation={userOrganisation}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};
