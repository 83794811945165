import { useTable } from "@hooks/utils/useTable";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { conditonNameToString } from "permit-one-common/src/entities/condition";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { stripTags } from "permit-one-common/src/utils/string";

import EditableTableRow from "./EditableTableRow";
import EditableTableRowSkeleton from "./EditableTableRowSkeleton";

interface EditableTableProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  userOrganisation?: OrganisationLineItem;
  conditions: ConditionLineItem[];
  selectedConditionStatus: number;
  selected: string[];
  title: string;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleOpenConditionEditModal: (condition: ConditionLineItem) => void;
  handleOpenConditionDocumentModal: (condition: ConditionLineItem) => void;
  filterName: string;
}

function EditableTable({
  conditions,
  disabled,
  filterName,
  handleDeleteCondition,
  handleEditCondition,
  handleOpenConditionDocumentModal,
  handleOpenConditionEditModal,
  loading,
  permit,
  project,
  selected,
  selectedConditionStatus,
  title,
  userOrganisation,
}: EditableTableProps) {
  const { handleRequestSort, order, orderBy } = useTable();

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            conditonNameToString(t.conditionAuthority, t.permitItemNumber)
              .toLowerCase()
              .includes(filterName.toLowerCase()) ||
            (t.conditionDetails &&
              t.conditionDetails
                .toLowerCase()
                .includes(filterName.toLowerCase())) ||
            (t.conditionComments &&
              t.conditionComments
                .toLowerCase()
                .includes(filterName.toLowerCase())) ||
            (t.discipline &&
              t.discipline.toLowerCase().includes(filterName.toLowerCase())) ||
            conditonNameToString(t.conditionAuthority, t.permitItemNumber)
              .toLowerCase()
              .includes(filterName.toLowerCase())
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          !c.markedAsNotApplicable &&
          (c.status === ConditionStatus.Assigned ||
            c.status === ConditionStatus.Rejected)
        );
      case 2:
        return (
          !c.markedAsNotApplicable && c.status === ConditionStatus.InReview
        );
      case 3:
        return (
          !c.markedAsNotApplicable && c.status === ConditionStatus.Approved
        );
      case 4:
        return c.markedAsNotApplicable;
    }
  });

  let sortedConditions = filteredConditions.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.permitItemNumber < b.permitItemNumber ? -1 : 1;
      case 1:
        return stripTags(
          a?.conditionDetails?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.conditionDetails?.toLocaleLowerCase() || "")
        );
      case 2:
        return stripTags(
          a?.conditionComments?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.conditionComments?.toLocaleLowerCase() || "")
        );
      case 3:
        return stripTags(a.discipline).localeCompare(stripTags(b.discipline));
      case 4:
        return stripTags(
          a?.assigneeComments?.toLocaleLowerCase() || ""
        ).localeCompare(
          stripTags(b?.assigneeComments?.toLocaleLowerCase() || "")
        );
      case 5:
        return a.status < b.status ? -1 : 1;
      default:
        return 0;
    }
  });
  sortedConditions =
    order === "asc" ? sortedConditions : sortedConditions.reverse();

  const TABLE_HEAD: TableHeaderCell[] = [
    { alignRight: true, id: "item", label: "Item" },
    { alignRight: true, id: "details", label: "Details" },
    { alignRight: true, id: "comments", label: "Comments" },
    { alignRight: true, id: "discipline", label: "Discipline" },
    { alignRight: true, id: "manager-notes", label: "Manager Notes" },
    { alignRight: false, id: "status", label: "Status" },
    { alignRight: false, id: "actions", label: "Actions", static: true },
    { id: "", static: true },
  ];

  return (
    <>
      {loading ? (
        <Skeleton animation="wave">
          <Typography variant="h6">{title}</Typography>
        </Skeleton>
      ) : (
        <Typography variant="h6">{title}</Typography>
      )}
      <TableContainer component={Paper}>
        <Table>
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={conditions.length}
            numSelected={selected.length}
            showCheckbox={false}
            onSelectAllClick={() => console.log("Not implemented")}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableBody>
            {loading || !permit
              ? Array.from(new Array(10)).map((_, index) => (
                  <EditableTableRowSkeleton key={index} />
                ))
              : null}
            {!loading && project && permit && userOrganisation
              ? sortedConditions.map((row) => (
                  <EditableTableRow
                    key={row.id}
                    disabled={disabled}
                    project={project}
                    permit={permit}
                    userOrganisation={userOrganisation}
                    handleOpenConditionDocumentModal={
                      handleOpenConditionDocumentModal
                    }
                    condition={row}
                    conditions={conditions}
                    handleOpenConditionEditModal={handleOpenConditionEditModal}
                    handleEditCondition={handleEditCondition}
                    handleDeleteCondition={handleDeleteCondition}
                    loading={loading}
                  />
                ))
              : null}
            {!loading && sortedConditions.length === 0 ? (
              <NoRows title={"Conditions"} colSpan={8} />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EditableTable;
