"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultMail = exports.createDefaultMailThread = void 0;
var uuid_1 = require("uuid");
var createDefaultMailThread = function (subject, message, profile, attachments, projectId, projectName, permitId, permitName, conditionId, conditionName) {
    var threadId = (0, uuid_1.v4)();
    var mail = (0, exports.createDefaultMail)(threadId, message, profile, attachments);
    return {
        conditionId: conditionId,
        conditionName: conditionName,
        created: new Date(),
        id: threadId,
        mail: [mail],
        permitId: permitId,
        permitName: permitName,
        projectId: projectId,
        projectName: projectName,
        subject: subject,
        topMail: mail,
    };
};
exports.createDefaultMailThread = createDefaultMailThread;
var createDefaultMail = function (mailThreadId, message, sender, attachments) {
    return {
        attachments: attachments,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        mailThreadId: mailThreadId,
        message: message,
        readBy: [sender.id],
        sender: sender,
        sent: false,
    };
};
exports.createDefaultMail = createDefaultMail;
