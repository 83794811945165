"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultConditionComment = void 0;
var uuid_1 = require("uuid");
var createDefaultConditionComment = function (project, permit, condition, comment, userProfile, shareCondition) {
    var firstName = (userProfile === null || userProfile === void 0 ? void 0 : userProfile.firstName) || (shareCondition === null || shareCondition === void 0 ? void 0 : shareCondition.firstName) || "";
    var lastName = (userProfile === null || userProfile === void 0 ? void 0 : userProfile.lastName) || (shareCondition === null || shareCondition === void 0 ? void 0 : shareCondition.lastName) || "";
    return {
        comment: comment,
        conditionId: condition.id,
        created: new Date(),
        firstName: firstName,
        id: (0, uuid_1.v4)(),
        lastName: lastName,
        permitId: permit.id,
        profileId: userProfile === null || userProfile === void 0 ? void 0 : userProfile.id,
        projectId: project.id,
        shareConditionId: shareCondition === null || shareCondition === void 0 ? void 0 : shareCondition.id,
    };
};
exports.createDefaultConditionComment = createDefaultConditionComment;
