import { listApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { decodeDirectoryContact } from "permit-one-common/src/decoders/directoryContact";
import { Profile } from "permit-one-common/src/entities/profile";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import * as React from "react";

export const useGlobalDirectory = () => {
  const [directory, setDirectory] = React.useState<DirectoryContactLineItem[]>(
    []
  );
  const [isDirectoryLoading, setIsDirectoryLoading] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const listGlobalDirectory = async (): Promise<void> => {
    try {
      setIsDirectoryLoading(true);
      const directoryResult = await listApiData(
        "listGlobalDirectory",
        "directory",
        ""
      );
      const directoryLineItems = await Promise.all(
        directoryResult.data.map((d) => decodeDirectoryContact(d as Profile, getImageUrl))
      );
      setDirectory(directoryLineItems);
    } catch (e: any) {
      setError("Could not list directory for condition");
    } finally {
      setIsDirectoryLoading(false);
    }
  };

  React.useEffect(() => {
    listGlobalDirectory();
  }, []);

  return {
    directory,
    error,
    isDirectoryLoading,
  };
};
