import { useAuthContext } from "@hooks/context/useAuthContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  TextField,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";

interface ForgotPasswordFormProps {
  setEmailAddress: (email: string) => void;
  setShowEnterCode: (showEnterCode: boolean) => void;
}

const ForgotPasswordForm = ({
  setEmailAddress,
  setShowEnterCode,
}: ForgotPasswordFormProps) => {
  const { forgotPassword, isAuthLoading } = useAuthContext();

  return (
    <Formik
      initialValues={{
        email: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const emailAddress = values.email.toLowerCase();
          await forgotPassword(emailAddress);

          setEmailAddress(emailAddress);
          setShowEnterCode(true);
          setStatus({ success: true });
          setSubmitting(false);

          toast(`Code sent to ${emailAddress}`, {
            type: "success",
          } as ToastOptions);
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong!", { type: "error" } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ margin: "auto" }}
              >
                <TextField
                  id="email"
                  type="email"
                  label="Enter email to reset password"
                  value={values.email}
                  disabled={isAuthLoading || isSubmitting}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="info@socialproapp.com"
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto", mt: 3 }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography align="center">{errors.submit}</Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={{ textAlign: "center" }}>
                <LoadingButton
                  className="signin-btn"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting || isAuthLoading}
                  sx={{
                    "&:hover": {
                      background: "#05214B !important",
                    },
                    background: "#0054D1 !important",
                    opacity: "1",
                  }}
                >
                  Send Code
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <a href="/login">Go to login?</a>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
